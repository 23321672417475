(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('JhiMetricsMonitoringModalController', JhiMetricsMonitoringModalController);

    JhiMetricsMonitoringModalController.$inject = ['$uibModalInstance', 'threadDump', 'threadStats'];

    function JhiMetricsMonitoringModalController ($uibModalInstance, threadDump, threadStats) {
        var vm = this;

        vm.cancel = cancel;
        vm.getLabelClass = getLabelClass;
        vm.threadDump = threadDump;
        vm.threadStats = threadStats;

        function cancel () {
            $uibModalInstance.dismiss('cancel');
        }

        function getLabelClass (threadState) {
            if (threadState === 'RUNNABLE') {
                return 'label-success';
            } else if (threadState === 'WAITING') {
                return 'label-info';
            } else if (threadState === 'TIMED_WAITING') {
                return 'label-warning';
            } else if (threadState === 'BLOCKED') {
                return 'label-danger';
            }
        }
    }
})();
