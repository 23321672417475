(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('StringUtils', StringUtils);

    function StringUtils () {

        var service = {
            concatAddressShort: concatAddressShort,
            getTranslationString: getTranslationString,
            stringifyAddress: stringifyAddress
        };

        return service;

        function concatAddressShort(address) {
            if (address) {
                return address.region + ', ' + address.locality +
                    (address.street ? (', '+ address.street) : '' ) +
                    (address.house ? (',' + address.house) : '');
            }
            return null;
        }

        function getTranslationString(array, value) {
            if (typeof value !== 'undefined' && value !== null) {
                var result = array.filter(function (element) {
                    return element.value === value;
                });
                return result[0].name;
            }
        }
        function stringifyAddress (address) {
            var resultAddress = [];
            if (address) {
                if (address.zipcode) {
                    resultAddress.push(address.zipcode);
                }
                if (address.country) {
                    resultAddress.push(address.country);
                }
                if (address.region) {
                    resultAddress.push(address.region);
                }
                if (address.subRegion) {
                    resultAddress.push(address.subRegion);
                }
                if (address.locality) {
                    resultAddress.push(address.locality);
                }
                if (address.street) {
                    resultAddress.push(address.street);
                }
                if (address.house) {
                    resultAddress.push(address.house);
                }
                if (address.housing) {
                    resultAddress.push(address.housing);
                }
                if (address.building) {
                    resultAddress.push(address.building);
                }
                if (address.apartment) {
                    resultAddress.push(address.apartment);
                }
                if (address.entrance) {
                    resultAddress.push(address.entrance);
                }
                if (address.floor) {
                    resultAddress.push(address.floor);
                }
            }
            return resultAddress.join(", ");
        }
    }
})();