(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig(stateProvider) {
        stateProvider
        .state('reclamations-client', {
            parent: 'orders-warehouse',
            url: '/reclamations-client',
            data: {
                authorities: ['ROLE_RECL_CLAIM'],
                pageTitle: 'reclamations.home.title' // different from manager?
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/reclamations/client/reclamations-client.html',
                    controller: 'ReclamationsClientController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value:'id,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reclamations');
                    return $translate.refresh();
                }]
            }
        })
        .state('reclamations-client.new', {
            parent: 'reclamations-client',
            url: '/draft/new',
            data: {
                authorities: ['ROLE_RECL_CLAIM'],
                pageTitle: 'reclamations.create.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/reclamations/client/reclamation-client-edit.html',
                    controller: 'ReclamationEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reclamations');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {
                        id: null, soId: null, details: [], comments: [], newComment: null,
                        internalNumber: null, clientNumber: null, eventDate: null, status: "DRAFT",
                        sumAlleged: 0, sumConfirmed: 0, licensePlate: null, driverName: null,
                        createdDate: null, lastModifiedBy: null, lastModifiedDate: null
                    }
                }
            }
        })
        .state('reclamations-client.edit', {
            parent: 'reclamations-client',
            url: '/draft/edit/:id',
            data: {
                authorities: ['ROLE_RECL_CLAIM'],
                pageTitle: 'reclamations.edit.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/reclamations/client/reclamation-client-edit.html',
                    controller: 'ReclamationEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Reclamation', function ($stateParams, Reclamation) {
                    return Reclamation.get({
                        id: $stateParams.id
                    });
                }]
            }
        })
        .state('reclamations-client.delete', {
            parent: 'reclamations-client',
            url: '/delete/:id',
            data: {
                authorities: ['ROLE_RECL_CLAIM'],
                pageTitle: 'reclamations.delete.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/orders-warehouse/reclamations/dialogs/reclamation-delete-dialog.html',
                    controller: 'ReclamationDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        data: function () {
                            return {
                                id: $stateParams.id
                            };
                        }
                    }
                }).result.then(function () {
                    $state.go('reclamations-client', null, { reload: true });
                }, function () {
                    $state.go('^');
                });
            }]
        })
        .state('reclamations-mgmt', {
            parent: 'orders-warehouse',
            url: '/reclamations-mgmt',
            data: {
                authorities: ['ROLE_RECL_PROCESS'],
                pageTitle: 'reclamations.home.title' // different from client?
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/reclamations/mgmt/reclamations-mgmt.html',
                    controller: 'ReclamationsMgmtController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value:'id,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reclamations');
                    return $translate.refresh();
                }]
            }
        })
        .state('reclamations-mgmt.edit', {
            parent: 'reclamations-mgmt',
            url: '/edit/:id',
            data: {
                authorities: ['ROLE_RECL_PROCESS'],
                pageTitle: 'reclamations.edit.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/reclamations/mgmt/reclamation-mgmt-edit.html',
                    controller: 'ReclamationMgmtEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Reclamation', function ($stateParams, Reclamation) {
                    return Reclamation.getExt({
                        id: $stateParams.id
                    });
                }]
            }
        })
        .state('reclamations-client.detail', {
            parent: 'reclamations-client',
            url: '/detail/:id',
            data: {
                authorities: ['ROLE_RECL_CLAIM'],
                pageTitle: 'reclamations.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/reclamations/client/reclamation-client-detail.html',
                    controller: 'ReclamationClientDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Reclamation', function ($stateParams, Reclamation) {
                    return Reclamation.get({
                        id: $stateParams.id
                    });
                }]
            }
        })
        .state('reclamations-mgmt.detail', {
            parent: 'reclamations-mgmt',
            url: '/detail/:id',
            data: {
                authorities: ['ROLE_RECL_PROCESS'],
                pageTitle: 'reclamations.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/reclamations/mgmt/reclamation-mgmt-detail.html',
                    controller: 'ReclamationMgmtDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Reclamation', function ($stateParams, Reclamation) {
                    return Reclamation.getExt({
                        id: $stateParams.id
                    });
                }]
            }
        })
        .state('reclamation-reports', {
            parent: 'orders-warehouse',
            url: '/reclamation-reports',
            data: {
                authorities: ['ROLE_RECL_PROCESS'],
                pageTitle: 'reclamations.reports.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/reclamations/reports/reclamation-reports.html',
                    controller: 'ReclamationReportsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reclamations');
                    return $translate.refresh();
                }],
                warehouses: ['ReclamationReportService', function (ReclamationReportService) {
                    return ReclamationReportService.getWarehouses();
                }],
                clients: ['ReclamationReportService', function (ReclamationReportService) {
                    return ReclamationReportService.getClients();
                }],
                recipients: ['ReclamationReportService', function (ReclamationReportService) {
                    return ReclamationReportService.getRecipients();
                }]
            }
        })
        .state('reclamation-client-reports', {
            parent: 'orders-warehouse',
            url: '/reclamation-client-reports',
            data: {
                authorities: ['ROLE_RECL_CLAIM'],
                pageTitle: 'reclamations.reports.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/reclamations/reports/reclamation-client-reports.html',
                    controller: 'ReclamationReportsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reclamations');
                    return $translate.refresh();
                }],
                warehouses: ['ReclamationReportService', function (ReclamationReportService) {
                    return ReclamationReportService.getWarehouses();
                }],
                clients: ['ReclamationReportService', function (ReclamationReportService) {
                    return ReclamationReportService.getClients();
                }],
                recipients: ['ReclamationReportService', function (ReclamationReportService) {
                    return ReclamationReportService.getRecipients();
                }]
            }
        });
    }
})();