(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('PurchaseOrder', PurchaseOrder);

    PurchaseOrder.$inject = ['$resource'];

    function PurchaseOrder ($resource) {
        var service = $resource('api/purchase/:client/:code/:type', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    if (data.wmsImportDate) {
                        data.wmsImportDate = new Date(data.wmsImportDate);
                    }
                    if (data.plannedPurchaseDate) {
                        data.plannedPurchaseDate = new Date(data.plannedPurchaseDate);
                    }
                    if (data.pickupOrder) {
                        if (data.pickupOrder.pickupDateFrom) {
                            data.pickupOrder.pickupDateFrom = new Date(data.pickupOrder.pickupDateFrom);
                        }
                        if (data.pickupOrder.pickupDateTo) {
                            data.pickupOrder.pickupDateTo = new Date(data.pickupOrder.pickupDateTo);
                        }
                    }
                    return data;
                }
            },
            'save': { method: 'POST' },
            'update': { method: 'PUT' },
            'delete': { method: 'DELETE' },
            'send': { method: 'POST', url: 'api/external/wms/po/export/:client', isArray: true },
            'status': { method: 'POST', url: 'api/external/wms/po/status/:client', isArray: true },
            'find': { method: 'POST', url: 'api/find/po/:client', isArray: true },
            'toExcel': {
                method: 'POST',
                url: 'api/excel/purchase/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'linesToExcel': {
                method: 'GET',
                url: 'api/excel/purchase-lines/:clientCode/:code/:type',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            "statusBatch": { method: 'POST', url: 'api/external/wms/po/status_batch/:client' },
            "getTypes": { method: 'GET', url: 'api/list/po-types', isArray: true },
            "findTransportless": { method: 'POST', url: 'api/find/po-transport/:client', isArray: true },
            'getTemplate': { 
                method: 'GET', url: 'api/templates/po-import/:client', responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    }; 
                }
            },
            'setMovementBlockValue': { method: 'PUT', url: 'api/purchase-additional/movement-block/:client/:code/:type'}
        });

        return service;
    }
})();