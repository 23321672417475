(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('WMSReceivingController', WMSReceivingController);

    WMSReceivingController.$inject = ['Principal', 'Reports', '$stateParams', '$state', 'pagingParams', 'paginationConstants', 'ParseLinks', 'FileSaver', '$scope', '$localStorage'];

    function WMSReceivingController (Principal, Reports, $stateParams, $state,pagingParams, paginationConstants, ParseLinks, FileSaver, $scope, $localStorage) {
        var vm = this;

        $scope.$storage = $localStorage;
        if (!$scope.$storage.itemsPerPage.reportReceiving) {
            $localStorage.itemsPerPage.reportReceiving = paginationConstants.itemsPerPage;
        }
        vm.itemsPerPage = $scope.$storage.itemsPerPage.reportReceiving;

        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.sort = sort;
        
        vm.data = [];
        vm.filtersOpen = false;
        vm.filter = $stateParams.filter;
        vm.itemsPerPageOptions = paginationConstants.itemsPerPageOptions;
        
        vm.loadAll = loadAll;
        vm.openCalendar = openCalendar;
        vm.clearFilter = clearFilter;
        vm.exportToExcel = exportToExcel;
        vm.changeItemsPerPage = changeItemsPerPage;

        init();

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.loadAll();
            });
        }

        function loadAll () {
            Reports.findReceiving({
                client: vm.client,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, vm.filter, onSuccess);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.data = data;
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function clearFilter () {
            vm.filter = {
                sku: null,
                closeDateAfter: null,
                closeDateBefore: null,
                orderNumber: null,
                lot: null
            };
            vm.totalItems = null;
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                client: vm.client,
                filter: vm.filter
            });
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function exportToExcel () {
            vm.savingXls = true;
            Reports.exportReceivingToExcel({
                client: vm.client,
                sort: sort()
            },
            vm.filter,
            function (data) {
                vm.savingXls = false;
                FileSaver.saveAs(data.blob, "receiving.xlsx");
            }, function (error) {
                vm.savingXls = false;
            });
        }

        function changeItemsPerPage () {
            // 2-way binding doesn't seem to work here for some reason
            $scope.$storage.itemsPerPage.reportReceiving = vm.itemsPerPage;
            loadAll();
        }
    }
})();