(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ReclamationMgmtEditController', ReclamationMgmtEditController);

    ReclamationMgmtEditController.$inject = ['entity', 'AlertService', '$state', '$uibModal', 'Reclamation', 'Upload', '$translate', 'ReclamationUtils'];

    function ReclamationMgmtEditController(entity, AlertService, $state, $uibModal, Reclamation, Upload, $translate, ReclamationUtils) {
        var vm = this;

        vm.reclamation = entity;
        vm.itemFilter = { sku: null, lot: null, description: null };
        vm.showItemFilter = false;
        vm.lineToggles = [];
        vm.files = [];
        vm.isFinalizing = false;
        vm.lineCommentOptions = [  "CONFIRMED", "REJECTED_VID", "REJECTED_COUNT", "REQUEST_INFO", "CUSTOM"  ];
        vm.employeeList =[];
        
        // List of employees by reclamation client id
        entity.$promise.then(function(value) {
            Reclamation.getEmployees({ clientId: value.client.id }, function(list) {
                vm.employeeList = list;
            });
        });

        vm.save = save;
        vm.cancel = cancel;
        vm.toggleLine = toggleLine;
        vm.isCollapsed = isCollapsed;
        vm.askForElaboration = askForElaboration;
        vm.finalize = finalize;
        vm.saveAndFinalize = saveAndFinalize;
        vm.changeSelectedFiles = changeSelectedFiles;
        vm.changeLineComment = changeLineComment;
        vm.costSum = ReclamationUtils.getCostSum;
        vm.deleteFile = deleteFile;

        function changeSelectedFiles () {
            vm.selectedFiles = vm.files.map(function(elem) { return elem.name }).join(", ");
        }

        function toggleLine (index) {
            var toggleIndex = vm.lineToggles.indexOf(index)
            if (toggleIndex !== -1) {
                vm.lineToggles.splice(toggleIndex, 1);
            } else {
                vm.lineToggles.push(index);
            }
        }

        function isCollapsed (index) {
            return vm.lineToggles.indexOf(index) == -1
        }

        function save () {
            vm.isSaving = true;
            Reclamation.managerUpdate(vm.reclamation, onDocSaveSuccess, onSaveError);
        }

        function onDocSaveSuccess (result) {
            if (result && result.id && (vm.files.length > 0)) {
                uploadFiles(result.id);
            } else if (vm.isFinalizing) {
                finalize(result.id);
            } else {
                onSaveSuccess();
            }
        }

        function uploadFiles (id) {
            Upload.upload({
                method: 'POST',
                url: 'api/reclamation/files/MANAGER/'+id,
                data: {
                    files: vm.files
                },
                arrayKey: ''
            }).then(function (result) {
                if (vm.isFinalizing) {
                    finalize(id)
                } else {
                    onSaveSuccess();
                }
            }, onSaveError);
        }

        function saveAndFinalize () {
            vm.isFinalizing = true;
            save();
        }

        function cancel () {
            $state.go('reclamations-mgmt', null, {reload: true});
        }

        function finalize (id) {
            var finalizeModal = $uibModal.open({
                templateUrl: 'app/orders-warehouse/reclamations/dialogs/reclamation-finalize-dialog.html',
                controller: 'ReclamationFinalizeDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'sm',
            }).result.then(function (result) {
                Reclamation.setStatus({
                    id: id
                }, result, onSaveSuccess, onSaveError);
            }, function () {
                // On Dismiss
                vm.isSaving = false;
                vm.isFinalizing = false;
            });
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            vm.isFinalizing = false;
            $state.go('reclamations-mgmt', null, {reload: true});
        }

        function onSaveError (error) {
            vm.isSaving = false;
            vm.isFinalizing = false;
            onError(error);
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function askForElaboration () {
            console.log('askForElaboration');
        }

        function changeLineComment (item) {
            if (item.lineStatus != "CUSTOM") {
                item.lineComment = $translate.instant('reclamations.lineComment.'+item.lineStatus);
            } else {
                item.lineComment = "";
            }
        }

        function deleteFile (file) {
            Reclamation.deleteFile({
                clientId: vm.reclamation.client.id,
                fileId: file.id
            }, function () {
                var i = vm.reclamation.managerFiles.indexOf(file);
                if (i !== -1)
                    vm.reclamation.managerFiles.splice(i, 1);
            }, onError)
        }
    }
})();