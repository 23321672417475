(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('products', {
            parent: 'catalogs',
            url: '/products?page&sort',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'products.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/catalogs/products/products.html',
                    controller: 'ProductsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'code,asc',
                    squash: true
                },
                filter: {
                    code: null,
                    description: null,
                    categories: null,
                    hasBatchControl: null
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialProvider: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('products');
                    return $translate.refresh();
                }]
            }
        })
        .state('product-detail', {
            parent: 'products',
            url: '/:client/detail/:code',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'products.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/catalogs/products/product-detail.html',
                    controller: 'ProductDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialProvider: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('products');
                    $translatePartialLoader.addPart('wms-reports');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Product', 'Base64utf8', function($stateParams, Product, Base64utf8) {
                    return Product.get({
                        client: $stateParams.client,
                        code: Base64utf8.encodeMod($stateParams.code) //base64
                    });
                }],
                remains: function($stateParams, Product, Base64utf8) {
                    return Product.getRemains({
                        client: $stateParams.client,
                        code: Base64utf8.encodeMod($stateParams.code) //base64
                    })
                }
            }
        })
        .state('products.new', {
            parent: 'products',
            url: '/:client/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'products.create.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/catalogs/products/product-edit.html',
                    controller: 'ProductEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('products');
                    return $translate.refresh();
                }],
                entity: function() {
                    return {
                        id: null, code: null, categories: null, description: null,
                        cost: 0.0, batchControl: null, serialCodeControl: null,
                        holdStatus: null, defaultDimensionUnit: null, pickingDimensionUnit: null,
                        dimensionUnits: [], barcodes: [], expirationViewType: 'SECONDS'
                    };
                }
            }
        })
        .state('products.edit', {
            parent: 'products',
            url: '/:client/edit/:code',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'products.edit.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/catalogs/products/product-edit.html',
                    controller: 'ProductEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('products');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Product', 'Base64utf8', function($stateParams, Product, Base64utf8) {
                    return Product.get({
                        client: $stateParams.client,
                        code: Base64utf8.encodeMod($stateParams.code) //base64
                    });
                }]
            }
        })
        .state('products.delete', {
            parent: 'products',
            url: '/:client/delete/:code',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/catalogs/products/product-delete-dialog.html',
                    controller: 'ProductDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Product', 'Base64utf8', function(Product, Base64utf8) {
                            return Product.get({
                                client: $stateParams.client,
                                code: Base64utf8.encodeMod($stateParams.code) //base64
                            });
                        }],
                        clientCode: function () {
                            return $stateParams.client;
                        }
                    }
                }).result.then(function () {
                    $state.go('products', null, { reload: true });
                }, function () {
                    $state.go('^');
                });
            }]
        })
        .state('products.uploadReport', {
            parent: 'products',
            url: '/uploadreport',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'products.report.title'
            },
            params: {
                report: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/catalogs/products/product-upload-result-dialog.html',
                    controller: 'ProductUploadResultController',
                    controllerAs: 'vm',
                    size: 'md'
                }).result.then(function () {
                    $state.go('products', null, { reload: true });
                }, function () {
                    $state.go('^');
                });
            }]
        });
    }
})();