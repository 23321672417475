(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ManagedDocumentEditController', ManagedDocumentEditController);

    ManagedDocumentEditController.$inject = ['document', 'categories', 'editParams', 'author', '$state', '$stateParams', 'ManagedDocuments', 'Upload', 'AlertService', 'FileSaver', '$uibModal'];

    function ManagedDocumentEditController (document, categories, editParams, author, $state, $stateParams, ManagedDocuments, Upload, AlertService, FileSaver, $uibModal) {
        var vm = this;

        vm.client = $stateParams.client;
        vm.type = $stateParams.type;
        vm.document = document;
        vm.author = author;
        vm.commentInput = null;
        vm.addComment = addComment;
        vm.addFile = addFile;
        vm.cancel = cancel;
        vm.save = save;
        vm.downloadFile = downloadFile;
        vm.deleteFile = deleteFile;
        vm.deleteComment = deleteComment;
        vm.isEditable = editParams.editFlag;
        vm.isAddEnabled = editParams.addFlag;
        vm.saveButtonLabel = editParams.label;
        vm.categories = categories;
        vm.changeCategory = changeCategory;
        vm.editEmails = editEmails;
        vm.stringifyEmails = stringifyEmails;

        function cancel () {
            $state.go('managed-documents', null, {reload: true});
        }

        function save () {
            var nextStatus;
            switch (vm.document.status) {
                case 'NEW':
                case 'ON_REVISION':
                    nextStatus = 'TO_CLIENT';
                    break;
                case 'TO_CLIENT':
                    nextStatus = 'CONFIRMED';
                    break;
            }
            if (nextStatus) {
                vm.isSaving = true;
                ManagedDocuments.setStatus({
                    type: vm.type,
                    client: vm.client,
                    id: vm.document.id,
                }, { newStatus: nextStatus }, onSaveSuccess, onError);
            }
        }

        function onSaveSuccess () {
            vm.isSaving = false;
            $state.go('managed-documents', null, {reload: true});
        }

        function onError (error) {
            vm.isSaving = false;
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), {param: error.headers('X-rbllkApp-params')});
            } else {
                AlertService.error({param: error.data.detail});
            }
        }

        function addComment () {
            if (vm.commentInput) {
                vm.document = ManagedDocuments.addComment({
                    type: vm.type,
                    client: vm.client,
                    id: vm.document.id,
                },{
                    message: vm.commentInput,
                    author: vm.author.username
                }, function (data) {
                    vm.commentInput = null;
                    onUpdateSuccess(data);
                }, onError);
            }
        }

        function deleteComment (id) {
            vm.isSaving = true;
            ManagedDocuments.deleteComment({
                type: vm.type,
                client: vm.client,
                id: vm.document.id,
                commentId: id
            }, onUpdateSuccess, onError);
        }

        function addFile () {
            vm.isSaving = true;
            Upload.upload({
                url: '/api/document/'+ vm.type + '/' + vm.client + '/' + vm.document.id + '/file',
                file: vm.file,
                method: 'PUT'
            }).then(function (data) {
                vm.file = null;
                onUpdateSuccess(data);
            }, onError);
        }

        function deleteFile (id) {
            vm.isSaving = true;
            ManagedDocuments.deleteFile({
                type: vm.type,
                client: vm.client,
                id: vm.document.id,
                fileId: id
            }, onUpdateSuccess, onError);
        }

        function changeCategory () {
            vm.isSaving = true;
            ManagedDocuments.changeCategory({
                type: vm.type,
                client: vm.client,
                id: vm.document.id,
            }, vm.document.category, onUpdateSuccess, onError);
        }

        function editEmails () {
            var editEmailsModal = $uibModal.open({
                templateUrl: 'app/documents/managed-documents/managed-documents-email-dialog.html',
                controller: 'ManagedDocumentsEmailDialog',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    emails: function () { return vm.document.emails.slice(); }
                }
            }).result.then(function (result) {
                ManagedDocuments.updateEmails({
                    type: vm.type,
                    client: vm.client,
                    id: vm.document.id,
                }, result, onUpdateSuccess, onError);
            })
        }

        function onUpdateSuccess (data) {
            vm.isSaving = false;
            vm.document = data;
        }

        function downloadFile (filename) {
            ManagedDocuments.getFile({
                type: vm.type,
                client: vm.client,
                id: vm.document.id,
                filename: filename
            },
            function (data) {
                FileSaver.saveAs(data.blob, filename);
            }, onError);
        }

        function stringifyEmails () {
            if (vm.document.emails) {
                return vm.document.emails.join('; ');
            }
            return '—';
        }
    }
})();