(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('PurchaseOrderEditController', PurchaseOrderEditController);

    PurchaseOrderEditController.$inject = ['AddressTypeahead', '$state', '$stateParams', 'entity', 'types', 'warehouses', 'transportCompanies', 'commonCompanies', 'PickupOrderPayers', 'PurchaseOrderRecipientTemplate', 'PurchaseOrder', '$uibModal', 'StringUtils', 'OrderConstants'];

    function PurchaseOrderEditController (AddressTypeahead, $state, $stateParams, entity, types, warehouses, transportCompanies, commonCompanies, PickupOrderPayers, PurchaseOrderRecipientTemplate, PurchaseOrder, $uibModal, StringUtils, OrderConstants) {
        var vm = this;

        vm.save = save;
        vm.client = $stateParams.client;
        vm.order = entity;
        vm.cancel = cancel;
        vm.openCalendar = openCalendar;
        vm.addProduct = addProduct;
        vm.removeElement = removeElement;
        vm.transportCompanies = transportCompanies;
        vm.commonCompanies = commonCompanies;
        vm.paymentMethods = OrderConstants.PaymentMethods;
        vm.clearTC = clearTC;
        vm.setTC = setTC;
        vm.payers = PickupOrderPayers;
        vm.concatAddress = StringUtils.concatAddressShort;
        vm.warehouses = warehouses;
        vm.getStreet = getStreet;
        vm.getLocality = getLocality;
        vm.getRegion = getRegion;
        vm.getMetro = getMetro;
        vm.ndsvalues = OrderConstants.VATRates;
        vm.typeValues = types;
        vm.qualityValues = OrderConstants.QualityValues;

        function save () {
            vm.isSaving = true;
            if (vm.order.id !== null) {
                PurchaseOrder.update({client: vm.client}, vm.order, onSaveSuccess, onSaveError);
            } else {
                PurchaseOrder.save({client: vm.client}, vm.order, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $state.go('purchase-orders', null, {reload: true});
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function cancel () {
            $state.go('purchase-orders', null, {reload: true});
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function addProduct () {
            var addProductModal = $uibModal.open({
                templateUrl: 'app/dialog/add-product-dialog/add-product-dialog.html',
                controller: 'AddProductDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    client: function () { return vm.client; },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('products');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function (result) {
                result.forEach(function (element, index, array) {
                    vm.order.products.push(element);
                });
            });
        }

        function removeElement (array, index) {
            array.splice(index, 1);
        }

        function clearTC () {
            if (vm.order) {
                vm.order.transportCompany = null;
                vm.order.pickupOrder = null;
            }
        }

        function setTC() {
            if (vm.order) {
                vm.order.transportCompany = vm.commonCompanies.rbl;
                vm.order.pickupOrder = {
                    recipient: PurchaseOrderRecipientTemplate,
                    type: "COURIER"
                };
            }
        }

        function getStreet(val) {
            return AddressTypeahead.findStreet(val);
        }

        function getRegion(val) {
            return AddressTypeahead.findRegion(val);
        }

        function getLocality(val) {
            return AddressTypeahead.findLocality(val);
        }

        function getMetro(val) {
            return AddressTypeahead.findMetroStation(val, vm.order.pickupOrder.warehouse.address.locality);
        }
    }
})();