(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ReclamationOrderDialogController', ReclamationOrderDialogController);

    ReclamationOrderDialogController.$inject = ['$uibModalInstance', 'paginationConstants', 'AlertService', 'ParseLinks', 'Reclamation', 'clientGroups'];

    function ReclamationOrderDialogController($uibModalInstance, paginationConstants, AlertService, ParseLinks, Reclamation, clientGroups) {
        var vm = this;

        vm.searchResult = [];
        vm.page = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.itemsPerPageOptions = paginationConstants.itemsPerPageOptions;
        vm.totalItems = null;
        vm.links = null;
        vm.searchParams = { code: null, owner: null, codeStrict: false };
        vm.clientSelectLabel = null;
        vm.clientSelectFilter = "";
        vm.clientGroups = clientGroups; // from state

        vm.clear = clear;
        vm.search = search;
        vm.loadPage = loadPage;
        vm.selectOrder = selectOrder;
        vm.selectOwner = selectOwner;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function search () {
            vm.searching = true;
            vm.page = 1;
            loadPage();
        }

        function loadPage () {
            Reclamation.findSo({
                dbId: vm.client.dbId,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, vm.searchParams, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.searching = false;
            vm.searchResult = data;
        }

        function onError (error) {
            vm.searching = false;
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function selectOrder (order) {
            Reclamation.getSoDetailed({
                dbId: vm.client.dbId,
                owner: order.ownerId,
                soId: order.soId
            }, function (data) {
                $uibModalInstance.close(data);
            }, onError);
        }

        function selectOwner (owner) {
            vm.client = owner;
            vm.clientSelectLabel = owner.name;
            vm.searchParams.owner = vm.client.id;
        }

        vm.groupToggles = [];
        vm.toggleGroup = toggleGroup;
        vm.isCollapsed = isCollapsed;

        function toggleGroup (index) {
            var toggleIndex = vm.groupToggles.indexOf(index)
            if (toggleIndex !== -1) {
                vm.groupToggles.splice(toggleIndex, 1);
            } else {
                vm.groupToggles.push(index);
            }
        }

        function isCollapsed (index) {
            return vm.groupToggles.indexOf(index) == -1
        }
    }
})();