(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ManagedDocumentsEmailDialog', ManagedDocumentsEmailDialog);

    ManagedDocumentsEmailDialog.$inject = ['$uibModalInstance', 'emails'];

    function ManagedDocumentsEmailDialog ($uibModalInstance, emails) {
        var vm = this;

        vm.clear = clear;
        vm.emails = emails;
        vm.confirm = confirm;
        vm.addElement = addElement;
        vm.removeElement = removeElement;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm () {
            $uibModalInstance.close(vm.emails);
        }

        function addElement (element) {
            vm.emails.push(element);
            vm.emailInput = '';
        }

        function removeElement (index) {
            vm.emails.splice(index, 1);
        }
    }
})();