(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ServicePricelistController', ServicePricelistController);

    ServicePricelistController.$inject = ['Principal', 'ServicePricelist', 'AlertService', '$uibModal'];

    function ServicePricelistController (Principal, ServicePricelist, AlertService, $uibModal) {
        var vm = this;

        vm.init = init;
        vm.loadAll = loadAll;
        vm.list = [];
        vm.addItem = addItem;
        vm.editItem = editItem;
        vm.deleteItem = deleteItem;

        vm.init();

        function init() {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.clientName = client.name;
                vm.loadAll();
            });
        }

        function loadAll () {
            ServicePricelist.query({
                client: vm.client
            }, onSuccess, onError);
        }

        function onSuccess (data) {
            vm.loaded = true;
            vm.list = data;
        }

        function onError (error) {
            vm.loaded = true;
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function addItem(lastPosition) {
            if (!angular.isDefined(lastPosition))
                lastPosition = 0
            var addItemModal = $uibModal.open({
                templateUrl: 'app/general-information/service-pricelist/service-pricelist-edit-dialog.html',
                controller: 'ServicePricelistEditDialogController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    client: function() { return vm.client },
                    data: function () {
                        return {
                            id: null, service: null, unit: null, price: 0.0, notes: null, position: lastPosition + 1
                        }
                    }
                }
            }).result.then(function (result) {
                loadAll();
            });
        }

        function editItem (item) {
            var editItemModal = $uibModal.open({
                templateUrl: 'app/general-information/service-pricelist/service-pricelist-edit-dialog.html',
                controller: 'ServicePricelistEditDialogController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    client: function() { return vm.client },
                    data: ['ServicePricelist', function (ServicePricelist) {
                        return ServicePricelist.get({
                            client: vm.client,
                            id: item.id
                        });
                    }]
                }
            }).result.then(function (result) {
                loadAll();
            });
        }

        function deleteItem (item) {
            var deleteItemModal = $uibModal.open({
                templateUrl: 'app/general-information/service-pricelist/service-pricelist-delete-dialog.html',
                controller: 'ServicePricelistDeleteDialogController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    client: function() { return vm.client },
                    data: function() { return item }
                }
            }).result.then(function (result) {
                loadAll();
            });
        }

        function editList () {

        }
    }
})();