(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('Principal', Principal);

    Principal.$inject = ['$q', 'Account', '$localStorage'];

    function Principal ($q, Account, $localStorage) {
        var _identity,
            _authenticated = false;

        var service = {
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved,
            hasAnyService: hasAnyService,
            hasService: hasService,
            getUserNameAndLogin: getUserNameAndLogin,
            getClientData: getClientData,
            getCurrentClient: getCurrentClient,
            setCurrentClient: setCurrentClient,
            clearCurrentClient: clearCurrentClient
        };

        return service;

        function authenticate (identity) {
            _identity = identity;
            _authenticated = identity !== null;
        }

        function hasAnyAuthority (authorities) {
            if (!_authenticated || !_identity || !_identity.authorities) {
                return false;
            }

            for (var i = 0; i < authorities.length; i++) {
                if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }

            return false;
        }

        function hasAuthority (authority) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function(_id) {
                return _id.authorities && _id.authorities.indexOf(authority) !== -1;
            }, function(){
                return false;
            });
        }

        function identity (force) {
            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            Account.get().$promise
                .then(getAccountThen)
                .catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen (account) {
                _identity = account.data;
                _authenticated = true;
                deferred.resolve(_identity);
            }

            function getAccountCatch () {
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            }
        }

        function isAuthenticated () {
            return _authenticated;
        }

        function isIdentityResolved () {
            return angular.isDefined(_identity);
        }

        function hasAnyService (services) {
            if(!_authenticated || !_identity || !_identity.authorities) {
                return $q.when(false);
            }
            //if user has ROLE_ADMIN do not check services
            if(_identity.authorities.indexOf('ROLE_ADMIN') !== -1) {
                return $q.when(true);
            } else {
                return this.getCurrentClient().then(function (client) {
                    for (var i = 0; i < services.length; i++) {
                        if (client.availableServices.indexOf(services[i]) !== -1) {
                            return true;
                        }
                    }
                    return false;
                });
            }
        }

        function hasService (service) {
            if (!_authenticated) {
                return $q.when(false);
            }
            return this.identity().then(function(_id) {
                // return (_id.authorities && _id.authorities.indexOf('ROLE_ADMIN') !== -1 ||
                // ($localStorage.currentClient && $localStorage.currentClient.availableServices.indexOf(service) !== -1));
                return ($localStorage.currentClient && $localStorage.currentClient.availableServices.indexOf(service) !== -1);
            }, function() {
                return false;
            });
        }

        function getUserNameAndLogin () {
            // if (!_authenticated) {
            //     return $q.when("");
            // }
            return this.identity().then(function(_id) {
                return {
                    login: _id.login,
                    username: (!_id.firstName && !_id.lastName) ? _id.login : [_id.lastName, _id.firstName].join(" ")
                };
            });
        }

        function getClientData () {
            if (!_authenticated) {
                return null;
            }

            return this.identity().then(function(_id) {
                var data = { clients: [], current: null };
                data.clients = _id.clients;
                if ($localStorage.currentClient &&
                    _id.clients.findIndex(function(element) { return element.code == $localStorage.currentClient.code; }) != -1) {

                } else {
                    var activeClients = _id.clients.filter(function (a) {return a.active;});
                    setCurrentClient(activeClients[0]);
                }
                data.current = $localStorage.currentClient;
                return data;
            });
        }

        function getCurrentClient () {
            if (!_authenticated) {
                return null;
            }

            return this.identity().then(function(_id) {
                if ($localStorage.currentClient &&
                    _id.clients.findIndex(function(element) { return element.code == $localStorage.currentClient.code; }) != -1) {
                    return $localStorage.currentClient;
                } else {
                    setCurrentClient(_id.clients[0]);
                    return $localStorage.currentClient;
                }
            });
        }

        function setCurrentClient (client) {
            if(!_authenticated || !_identity) {
                return false;
            }

            $localStorage.currentClient = client;
            return true;
        }

        function clearCurrentClient () {
            delete $localStorage.currentClient;
        }
    }
})();
