(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('pickup-orders', {
            parent: 'orders-delivery',
            url: '/pickup-orders',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'pickupOrders.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-delivery/pickup-orders/pickup-orders.html',
                    controller: 'PickupOrdersController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value:'code,asc',
                    squash: true
                },
                filter: {
                    code: null,
                    plannedDate: null,
                    sender: null,
                    recipient: null
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pickup-orders');
                    return $translate.refresh();
                }]
            }
        })
        .state('pickup-order-detail', {
            parent: 'pickup-orders',
            url: '/:client/detail/:code',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'pickupOrders.create.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-delivery/pickup-orders/pickup-order-detail.html',
                    controller: 'PickupOrderDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'PickupOrder', function ($stateParams, PickupOrder) {
                    return PickupOrder.get({
                        client: $stateParams.client,
                        code: $stateParams.code
                    });
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pickup-orders');
                    return $translate.refresh();
                }]
            }
        })
        .state('pickup-orders.new', {
            parent: 'pickup-orders',
            url: '/:client/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'pickupOrders.create.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-delivery/pickup-orders/pickup-order-edit.html',
                    controller: 'PickupOrderEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                defaultName: ['Principal', function (Principal) {
                    return Principal.getUserNameAndLogin();
                }],
                entity: ['defaultName', function (defaultName) {
                    return {
                        id: null, createdDate: null, lastModifiedBy: null, lastModifiedDate: null, type: "COURIER",
                        code: null, author: defaultName.username, pickupDateFrom: null, pickupDateTo: null,
                        contentDescription: null, contentType: null, packageType: null, specialRequirements: null,
                        contentMarkings: null, cargoSpaceCount: 0, maxSpaceWeight: 0, totalWeight: 0, totalVolume: 0,
                        payer: null, paymentMethod: null, assessedCost: 0, totalCost: 0,
                        sender: null, recipient: null, warehouse: null
                    };
                }],
                warehouses: ['$stateParams', 'Warehouse', function ($stateParams, Warehouse) {
                    return Warehouse.getList({ client: $stateParams.client });
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pickup-orders');
                    $translatePartialLoader.addPart('warehouses');
                    return $translate.refresh();
                }]
            }
        })
        .state('pickup-orders.edit', {
            parent: 'pickup-orders',
            url: '/:client/edit/:code',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'pickupOrders.edit.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-delivery/pickup-orders/pickup-order-edit.html',
                    controller: 'PickupOrderEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pickup-orders');
                    $translatePartialLoader.addPart('warehouses');
                    return $translate.refresh();
                }],
                warehouses: ['$stateParams', 'Warehouse', function ($stateParams, Warehouse) {
                    return Warehouse.getList({ client: $stateParams.client });
                }],
                entity: ['$stateParams', 'PickupOrder', function ($stateParams, PickupOrder) {
                    return PickupOrder.get({
                        client: $stateParams.client,
                        code: $stateParams.code
                    });
                }]
            }
        })
        .state('pickup-orders.delete', {
            parent: 'pickup-orders',
            url: '/:client/delete/:code',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'pickupOrders.delete.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/orders-delivery/pickup-orders/pickup-order-delete-dialog.html',
                    controller: 'PickupOrderDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                code: $stateParams.code,
                                clientCode: $stateParams.client
                            };
                        }
                    }
                }).result.then(function () {
                    $state.go('pickup-orders', null, { reload: true });
                }, function () {
                    $state.go('^');
                });
            }]
        });
    }
})();