(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('JhiMetricsService', JhiMetricsService);

    JhiMetricsService.$inject = ['$rootScope', '$http'];

    function JhiMetricsService ($rootScope, $http) {
        var service = {
            getMetrics: getMetrics,
            threadDump: threadDump
        };

        return service;

        function getMetrics () {
            return $http.get('management/jhimetrics').then(function (response) {
                var cacheStatsArray = [];
                for (var cacheName in response.data.cache) {
                    cacheStatsArray.push({cache: cacheName, stats: response.data.cache[cacheName]});
                }
                response.data.cache = cacheStatsArray;
                return response.data;
            });
        }

        function threadDump () {
            return $http.get('management/threaddump').then(function (response) {
                return response.data;
            });
        }
    }
})();
