(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('Warehouse', Warehouse);

    Warehouse.$inject = ['$resource'];

    function Warehouse ($resource) {
        var service = $resource('api/warehouses/:client/:code', {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method: 'POST'},
            'update': { method: 'PUT' },
            'delete': { method: 'DELETE' },
            'getList': { method: 'GET', url: 'api/list/warehouses/:client/', isArray: true }
        });

        return service;
    }
})();