(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .service('ClientInfo', ClientInfo);

    ClientInfo.$inject = ['$resource'];

    function ClientInfo($resource) {
        var service = $resource('api/document/info/:client', {}, {
            'get': { 
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.contractDate) {
                            data.contractDate = new Date(data.contractDate);
                        }
                    }
                    return data;
                } 
            },
            'update': { method: 'PUT' },
            'save': { method: 'POST' },
            'toExcel': { 
                method: 'GET',
                url: 'api/excel/client-info/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    }; 
                }
            }
        });
        return service;
    }
})();