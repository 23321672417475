(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ReclamationEditController', ReclamationEditController);

    ReclamationEditController.$inject = ['entity', '$state', '$uibModal', 'Reclamation', 'Upload', 'FileSaver', 'AlertService'];

    function ReclamationEditController(entity, $state, $uibModal, Reclamation, Upload, FileSaver, AlertService) {
        var vm = this;

        vm.reclamation = entity;
        vm.headerLabel = entity.id ? "reclamations.edit.title" : "reclamations.create.title";
        vm.files = [];
        vm.itemFilter = { sku: "" };
        vm.showItemFilter = false;
        vm.lineToggles = [];
        vm.qtyWarning = false;

        vm.openCalendar = openCalendar;
        vm.changeSelectedFiles = changeSelectedFiles;
        vm.save = save;
        vm.cancel = cancel;
        vm.openOrderDialog = openOrderDialog;
        vm.openSkuDialog = openSkuDialog;
        vm.removeItem = removeItem;
        vm.downloadFile = downloadFile;
        vm.deleteFile = deleteFile;
        vm.toggleLine = toggleLine;
        vm.isCollapsed = isCollapsed;
        vm.register = register;
        vm.saveAndRegister = saveAndRegister;

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function changeSelectedFiles () {
            vm.selectedFiles = vm.files.map(function(elem) { return elem.name }).join(", ");
        }

        function save () {
            vm.qtyWarning = !checkQty();
            if (!vm.qtyWarning) {
                vm.isSaving = true;
                if (vm.reclamation.id !== null) {
                    Reclamation.update(vm.reclamation, onDocSaveSuccess, onSaveError);
                } else {
                    Reclamation.save(vm.reclamation, onDocSaveSuccess, onSaveError);
                }
            }
        }

        function onDocSaveSuccess (result) {
            if (result && result.id && (vm.files.length > 0 || vm.torg2file)) {
                uploadFiles(result.id);
            } else if (vm.isRegistering) {
                register(result.id)
            } else {
                onSaveSuccess();
            }
        }

        function uploadFiles (id) {
            Upload.upload({
                method: 'POST',
                url: 'api/reclamation/files/CLIENT/'+id,
                data: {
                    files: vm.files,
                    torg2: vm.torg2file
                },
                arrayKey: ''
            }).then(function (result) {
                if (vm.isRegistering) {
                    register(id)
                } else {
                    onSaveSuccess();
                }
            }, onSaveError);
        }

        function register (id) {
            Reclamation.setStatus({
                id: id
            }, { status: 'NEW' }, onSaveSuccess, onSaveError);
        }

        function saveAndRegister () {
            vm.isRegistering = true;
            save();
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            vm.isRegistering = false;
            vm.qtyWarning = false;
            $state.go('reclamations-client', null, {reload: true});
        }

        function onSaveError (error) {
            vm.isSaving = false;
            vm.isRegistering = false;
            vm.qtyWarning = false;
            onError(error);
        }

        function cancel () {
            $state.go('reclamations-client', null, {reload: true});
        }

        function openOrderDialog () {
            var orderSearchModal = $uibModal.open({
                templateUrl: 'app/orders-warehouse/reclamations/dialogs/reclamation-order-dialog.html',
                controller: 'ReclamationOrderDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialProvider: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('sales-orders');
                        return $translate.refresh();
                    }],
                    clientGroups: ['Client', function (Client) {
                        return Client.getOwnerGroups();
                    }]
                }
            }).result.then(function (result) {
                // result { clientId, id, code, type, recipient } + TODO licensePlate, driverName
                vm.reclamation.client = result.client;
                vm.reclamation.soId = result.soId;
                vm.reclamation.soNumber = result.code;
                vm.reclamation.recipient = result.recipient;
                vm.reclamation.eventDate = new Date(result.shippedDate);
                vm.reclamation.licensePlate = result.licensePlate;
                vm.reclamation.driverName = result.driverName;
                vm.reclamation.details = result.items;
                vm.reclamation.lineCount = result.items.length;
            }, function () {
                // no SO selected
            });
        }

        function openSkuDialog () {
            var skuSearchModal = $uibModal.open({
                templateUrl: 'app/orders-warehouse/reclamations/dialogs/reclamation-sku-dialog.html',
                controller: 'ReclamationSkuDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    client: function () { return vm.reclamation.client },
                    translatePartialProvider: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('products');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function (result) {
                vm.reclamation.details.push({
                    sku: result.sku,
                    skuId: result.skuId,
                    factQty: result.factQty,
                    shortageQty: result.shortageQty,
                    surplusQty: result.surplusQty,
                    defectQty: result.defectQty,
                    description: result.description
                });
                vm.lineToggles = []; // clean toggles
            });
        }

        function removeItem (item) {
            var i = vm.reclamation.details.indexOf(item);
            if (i !== -1) {
                vm.lineToggles = []; // clean toggles
                vm.reclamation.details.splice(i, 1);
            }
        }

        function downloadFile (file) {
            vm.isDownloading = true;
            Reclamation.downloadFile({
                clientId: vm.reclamation.client.id,
                fileId: file.id
            }, function (data) {
                vm.isDownloading = false;
                var filename = file.filename;
                FileSaver.saveAs(data.blob, filename);
            }, function (error) {
                vm.isDownloading = false;
                onError(error);
            })
        }

        function deleteFile (file) {
            Reclamation.deleteFile({
                clientId: vm.reclamation.client.id,
                fileId: file.id
            }, function () {
                var i = vm.reclamation.clientFiles.indexOf(file);
                if (i !== -1)
                    vm.reclamation.clientFiles.splice(i, 1);
            }, onError)
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function toggleLine (index) {
            var toggleIndex = vm.lineToggles.indexOf(index)
            if (toggleIndex !== -1) {
                vm.lineToggles.splice(toggleIndex, 1);
            } else {
                vm.lineToggles.push(index);
            }
        }

        function isCollapsed (index) {
            return vm.lineToggles.indexOf(index) == -1
        }

        function nonZeroQty (element) {
            return element.surplusQty > 0 || element.shortageQty > 0 || element.defectQty > 0;
        }

        function checkQty () {
            return vm.reclamation.details.some(nonZeroQty);
        }
    }
})();