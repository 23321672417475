(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('DocumentsController', DocumentsController);

    DocumentsController.$inject = ['Principal', 'Documents', 'FileSaver', 'AlertService'];

    function DocumentsController (Principal, Documents, FileSaver, AlertService) {
        var vm = this;

        vm.documents = [];
        vm.loadAll = loadAll;
        vm.searchParams = { name: null, category: null, createdDate: null };
        vm.openCalendar = openCalendar;
        vm.downloadFile = downloadFile;

        init();

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.clientName = client.name;
                vm.loadAll();
                loadCategories();
            });
        }

        function loadAll () {
            Documents.findDocuments({
                client: vm.client
            }, vm.searchParams, onSuccess, onError);
        }

        function loadCategories () {
            Documents.getCategories({ client: vm.client }, function (data) { vm.categories = data });
        }

        function onSuccess (data) {
            vm.documents = data;
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function downloadFile (id, filename) {
            Documents.getFile({
                client: vm.client,
                id: id
            },
            function (data) {
                FileSaver.saveAs(data.blob, filename);
            }, onError);
        }
    }
})();