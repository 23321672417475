(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', 'User', 'ParseLinks', 'AlertService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService', '$scope', '$localStorage', '$stateParams', 'UserConstants', '$sessionStorage'];

    function UserManagementController(Principal, User, ParseLinks, AlertService, $state, pagingParams, paginationConstants, JhiLanguageService, $scope, $localStorage, $stateParams, UserConstants, $sessionStorage) {
        var vm = this;

        $scope.$storage = $localStorage;
        if(!$scope.$storage.userColumns) { //due to $default issue with restoring storage
            $localStorage.userColumns = { created: true, lastModifiedBy: true, lastModifiedDate: true };
        }
        vm.shownColumns = $scope.$storage.userColumns;
        if (!$scope.$storage.itemsPerPage.userList) {
            $localStorage.itemsPerPage.userList = paginationConstants.itemsPerPage;
        }
        vm.itemsPerPage = $scope.$storage.itemsPerPage.userList;

        vm.page = 1;
        vm.totalItems = null;
        vm.clear = clear;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        vm.currentAccount = null;
        vm.languages = null;
        vm.users = [];
        vm.itemsPerPageOptions = paginationConstants.itemsPerPageOptions;
        vm.authorityOptions = UserConstants.AuthorityOptions;

        if (!$sessionStorage.filters.userManagement) {
            $sessionStorage.filters.userManagement = { login: null, authority: null };
        }
        vm.searchParams = $sessionStorage.filters.userManagement;

        vm.loadAll = loadAll;
        vm.setActive = setActive;
        vm.changeItemsPerPage = changeItemsPerPage;


        vm.loadAll();
        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });
        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });

        function setActive (user, isActivated) {
            //user.activated = isActivated;
            //User.update(user, function () {
            //    vm.loadAll();
            //    vm.clear();
            //});
        }

        function loadAll () {
            User.find({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, vm.searchParams, onSuccess, onError);
        }

        function onSuccess(data, headers) {
            //hide anonymous user from user management: it's a required user for Spring Security
            var hiddenUsersSize = 0;
            for (var i in data) {
                if (data[i]['login'] === 'anonymoususer') {
                    data.splice(i, 1);
                    hiddenUsersSize++;
                }
            }
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count') - hiddenUsersSize;
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.users = data;
        }

        function onError(error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function clear () {
            vm.user = {
                id: null, login: null, firstName: null, lastName: null, email: null, phoneNumber: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null, clientName: null, availableServices: null
            };
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function changeItemsPerPage () {
            // 2-way binding doesn't seem to work here for some reason
            $scope.$storage.itemsPerPage.userList = vm.itemsPerPage;
            loadAll();
        }

    }
})();
