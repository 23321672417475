(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ClosingDocumentsController', ClosingDocumentsController);

    ClosingDocumentsController.$inject = ['$stateParams', 'Principal', 'ClosingDocuments', 'FileSaver', 'AlertService'];

    function ClosingDocumentsController ($stateParams, Principal, ClosingDocuments, FileSaver, AlertService) {
        var vm = this;
        vm.directoryFilter = $stateParams.filter;
        vm.loadAll = loadAll;
        vm.downloadFile = downloadFile;

        init();

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.clientName = client.name;
                loadAll();
            });
        }

        function loadAll () {
            ClosingDocuments.list({
                client: vm.client
            },
            vm.directoryFilter,
            function (data) {
                vm.files = data.filesByDirectory;
                vm.directories = data.directories;
            }, onError);
        }

        function downloadFile (filename, directory) {
            ClosingDocuments.getFile({
                client: vm.client,
                directory: directory === ".." ? null : directory,
                filename: filename
            },
            function (data) {
                FileSaver.saveAs(data.blob, filename);
            }, onError);
        }

        function onError (error) {
            AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
        }
    }
})();