(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('TransportEditController', TransportEditController);

    TransportEditController.$inject = ['$stateParams', '$state', 'entity', 'soTypes', 'poTypes', 'DatetimePickerConfig', '$uibModal', 'SalesOrder', 'PurchaseOrder', 'Transport','mixingAllowed', 'AlertService'];

    function TransportEditController ($stateParams, $state, entity, soTypes, poTypes, DatetimePickerConfig, $uibModal, SalesOrder, PurchaseOrder, Transport, mixingAllowed, AlertService) {
        var vm = this;

        vm.transport = entity;
        vm.client = $stateParams.client;
        vm.headerLabel = vm.transport.id != null ? "transport.edit.title" : "transport.create.title";
        vm.datetimePickerConfig = {
            buttonBar: DatetimePickerConfig.buttonBar(),
            timepickerOptions: DatetimePickerConfig.timepickerOptions(),
            modelOptions: DatetimePickerConfig.modelOptions()
        };
        vm.poTypes = poTypes;
        vm.soTypes = soTypes;

        vm.openCalendar = openCalendar;
        vm.addInbound = addInbound;
        vm.addOutbound = addOutbound;
        vm.save = save;
        vm.cancel = cancel;
        vm.addCommentary = addCommentary;
        vm.editCommentary = editCommentary;
        vm.removeCommentary = removeCommentary;
        vm.showCommentDialog = showCommentDialog;
        vm.makeOrderList = makeOrderList;
        vm.isCodeEditDisabled = isCodeEditDisabled;
        vm.translateType = translateType;

        vm.mixingAllowed = mixingAllowed; // from state

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function addOutbound () {
            if (!vm.mixingAllowed && vm.transport.inboundOrders.length > 0) {
                AlertService.error('transport.messages.cannot.mix', []);
            } else {
                addOrder(SalesOrder, vm.transport.outboundOrders, 'OUT');
            }
        }

        function addInbound () {
            if (!vm.mixingAllowed && vm.transport.outboundOrders.length > 0) {
                AlertService.error('transport.messages.cannot.mix', []);
            } else {
                addOrder(PurchaseOrder, vm.transport.inboundOrders, 'IN');
            }
        }

        function addOrder (service, resultArray, type) {
            var addOrderModal = $uibModal.open({
                templateUrl: 'app/orders-warehouse/transport/transport-orders-dialog.html',
                controller: 'TransportOrdersDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    client: function () { return vm.client; },
                    orderService: function () { return service; },
                    selected: function () { return resultArray; },
                    type: function () { return type; },
                    typeTranslations: function () {
                        switch (type) {
                            case 'OUT': return vm.soTypes;
                            case 'IN': return vm.poTypes;
                            default: return {};
                        }
                    }
                }
            });
        }

        function save () {
            vm.isSaving = true;
            if (vm.transport.id !== null) {
                Transport.update({client: vm.client}, vm.transport, onSaveSuccess, onSaveError);
            } else {
                Transport.save({client: vm.client}, vm.transport, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $state.go('transport-summary', null, {reload: true});
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function cancel () {
            $state.go('transport-summary', null, {reload: true});
        }

        function addCommentary() {
            showCommentDialog(
                { orderCode: null, commentary: null },
                makeOrderList(),
                function (result) {
                    vm.transport.commentaries.push(result);
                });
        }

        function editCommentary(item) {
            var index = vm.transport.commentaries.indexOf(item);
            showCommentDialog(
                {
                    orderCode: item.orderCode,
                    commentary: item.commentary
                }, makeOrderList(),
                function (result) {
                    vm.transport.commentaries[index] = result;
                });
        }

        function removeCommentary(item) {
            vm.transport.commentaries.splice(vm.transport.commentaries.indexOf(item), 1);
        }

        function showCommentDialog(comment, codes, resultHandler) {
            var addOrderModal = $uibModal.open({
                templateUrl: 'app/orders-warehouse/transport/transport-edit-comment-dialog.html',
                controller: 'TransportEditCommentDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    commentary: function () {return comment},
                    codes: function () {return codes}
                }
            }).result.then(resultHandler);
        }

        function makeOrderList() {
            var list = [];
            if(vm.transport.inboundOrders) {
                vm.transport.inboundOrders.forEach(function(element) {
                    list.push(element.code);
                });
            }
            if(vm.transport.outboundOrders) {
                vm.transport.outboundOrders.forEach(function(element) {
                    list.push(element.code);
                });
            }
            return list;
        }

        function isCodeEditDisabled () {
            return (vm.transport.id && vm.transport.status !== 'NEW');
        }

        function translateType (string, translationArray) {
            return translationArray[string] ? translationArray[string] : string;
        }
    }
})();