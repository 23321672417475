(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .constant('AllServiceList', [ "REGISTRY_PURCHASE",
            "REGISTRY_PURCHASE_PICKUP",
            "REQ_DELIV_COURIER",
            "REQ_DELIV_RUSPOST",
            "REQ_PICKUP",
            "REQ_PURCHASE",
            "REQ_PURCHASE_PICKUP",
            "REQ_SALES",
            "REQ_SALES_COURIER",
            "REQ_SALES_RBL",
            "REQ_SALES_RUSPOST",
            "REQ_TRANSPORT",
            'AN_TEMPERATURE_REGIME', // deprecated: старый механизм
            'WORTMANN_MOV_BLOCK'
        ])
        .constant('WMSConfigurationConstants', {
            "serialNumberControlTypes": ["NONE", "SALE", "PURCHASE_SALE"],
            "batchControlTypes": ["NONE", "SALE", "PURCHASE_SALE"],
            "expirationControlTypes": ["NONE", "SHELF_LIFE", "MFG_DATE", "COMBINED"],
            "kitTypes": ["NONE", "SALE"]
        })
        .constant('ClientManagementConstants', {
            "AvailableServiceList": [
                'REQ_SALES',
                'REQ_PURCHASE',
                'REQ_TRANSPORT',
                'AN_TEMPERATURE_REGIME', // deprecated: старый механизм
                'WORTMANN_MOV_BLOCK',
                'REF_EDIT'
            ],
            "DeliveryServices": ['REQ_DELIV_COURIER', 'REQ_DELIV_RUSPOST', 'REQ_PURCHASE_PICKUP', 'REQ_PICKUP'],
            "WarehouseServices": ['REQ_SALES', 'REQ_PURCHASE', 'REQ_TRANSPORT']
        });
})();