(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('UserCategories', UserCategories);

    UserCategories.$inject = ['$resource'];

    function UserCategories ($resource) {
        var service = $resource('api/user-config/categories', {}, {
            'getAll': { method: 'GET', url: 'api/user-config/categories/all/:login/', isArray: true },
            'save': { method: 'PUT', url: 'api/user-config/categories/available/:login/'},
            'getAvailable': { method: 'GET', url: 'api/user-config/categories/available/:login/'}
        });

        return service;
    }
})();
