(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('ws-configuration', {
            parent: 'external-configuration',
            url: '/ws-configuration',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'wsConfiguration.create.title'
            },
            views: {
                'ext-configuration': {
                    templateUrl: 'app/admin/external-configuration/wms-ws/ws-configuration.html',
                    controller: 'WsConfigurationController',
                    controllerAs: 'vm'
                }
            }
        })
        .state('ws-configuration.new', {
            parent: 'ws-configuration',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'wsConfiguration.create.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/external-configuration/wms-ws/ws-configuration-dialog.html',
                    controller: 'WsConfigurationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null, name: null, requestUrl: null, responseSenderIp: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('ws-configuration', null, { reload: true });
                }, function() {
                    $state.go('ws-configuration');
                });
            }]
        })
        .state('ws-configuration.edit', {
            parent: 'ws-configuration',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'wsConfiguration.edit.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/external-configuration/wms-ws/ws-configuration-dialog.html',
                    controller: 'WsConfigurationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['WebServiceConfig', function(WebServiceConfig) {
                            return WebServiceConfig.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('ws-configuration', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('ws-configuration.delete', {
            parent: 'ws-configuration',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'wsConfiguration.delete.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/external-configuration/wms-ws/ws-configuration-delete-dialog.html',
                    controller: 'WsConfigurationDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['WebServiceConfig', function(WebServiceConfig) {
                            return WebServiceConfig.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('ws-configuration', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
