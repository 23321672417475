(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('SidebarService', SidebarService);

    function SidebarService() {
        var isCollapsed = false;

        var service = {
            toggle: toggle,
            getState: getState
        };

        function toggle () {
            isCollapsed = !isCollapsed;
        }

        function getState () {
            return isCollapsed;
        }

        return service;
    }
})();