(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ReclamationReportsController', ReclamationReportsController);

    ReclamationReportsController.$inject = ['ReclamationReportService', 'AlertService', 'FileSaver', 'warehouses', 'clients', 'recipients'];

    function ReclamationReportsController(ReclamationReportService, AlertService, FileSaver, warehouses, clients, recipients) {
        var vm = this;

        vm.filter = { dbId: null, owner: null, dateFrom: null, dateTo: null };
        
        vm.openCalendar = openCalendar;
        vm.changeWarehouse = changeWarehouse;
        vm.changeClient = changeClient;
        vm.changeStatus = changeStatus;
        vm.report1 = report1;
        vm.report2 = report2;
        vm.report2a = report2a;
        vm.report2b = report2b;
        vm.report3 = report3;
        vm.report4 = report4;
        vm.resetDate = resetDate;
        vm.validateDateRange = validateDateRange;
        vm.resetInvalid = resetInvalid;
        vm.changeClientGroup = changeClientGroup;
        vm.reportStatistics = reportStatistics;

        vm.dateRangeInvalid = false;
        vm.clientSelectLabel = null;

        vm.warehouseOptions = warehouses;
        vm.clientOptions = clients;
        vm.recipientOptions = recipients;

        vm.statusOptions = [ 'DRAFT', 'NEW', 'IN_PROGRESS', 'CLOSED' ];
        vm.substatusOptions = [];

        function openCalendar (picker) {
            vm[picker] = true;
        }
        
        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function changeWarehouse () {
            ReclamationReportService.getClients({ dbId: vm.filter.dbId}, function (data) {
                vm.clientOptions = data;
                vm.filter.owner = null;
                vm.filter.group = null;
                vm.clientSelectLabel = null;
            }, onError);
            ReclamationReportService.getRecipients({ dbId: vm.filter.dbId}, function (data) {
                vm.recipientOptions = data;
                vm.filter.recipient = null;
            }, onError);
        }

        function changeClient (selectedClient) {
            vm.filter.owner = selectedClient;
            vm.filter.group = null;
            vm.clientSelectLabel = selectedClient !== null ? selectedClient.name : null;
            ReclamationReportService.getRecipients({ clientId: selectedClient.clientId}, function (data) {
                vm.recipientOptions = data;
                vm.filter.recipient = null;
            }, onError);
        }

        function changeClientGroup (selectedGroup) {
            vm.filter.owner = null;
            vm.filter.group = { id: selectedGroup.id, wmsId: selectedGroup.wmsId };
            vm.clientSelectLabel = selectedGroup.name;
            ReclamationReportService.getRecipients({ groupId: selectedGroup.wmsId}, function (data) {
                vm.recipientOptions = data;
                vm.filter.recipient = null;
            }, onError);
        }

        function changeStatus () {
            switch (vm.filter.status) {
                case 'IN_PROGRESS': vm.substatusOptions = ['CLIENT', 'OPERATOR']; break;
                case 'CLOSED': vm.substatusOptions = ['CONFIRMED', 'REJECTED']; break;
                default: vm.substatusOptions = [];
            }
        }

        function report1 () {
            vm.savingXls = true;
            ReclamationReportService.getReport1(vm.filter,
            function (data) {
                vm.savingXls = false;
                FileSaver.saveAs(data.blob, "reclamation_report1.xlsx");
            }, function (error) {
                vm.savingXls = false;
                onError(error);
            });
        }

        function report2 () {
            validateDateRange();
            if (!vm.dateRangeInvalid) {
                vm.savingXls = true;
                ReclamationReportService.getReport2(vm.filter,
                function (data) {
                    vm.savingXls = false;
                    FileSaver.saveAs(data.blob, "reclamation_report2.xlsx");
                }, function (error) {
                    vm.savingXls = false;
                    onError(error);
                });
            }
        }

        function report2a () {
            validateDateRange();
            if (!vm.dateRangeInvalid) {
                vm.savingXls = true;
                ReclamationReportService.getReport2a(vm.filter,
                function (data) {
                    vm.savingXls = false;
                    FileSaver.saveAs(data.blob, "reclamation_report2a.xlsx");
                }, function (error) {
                    vm.savingXls = false;
                    onError(error);
                });
            }
        }

        function report2b () {
            validateDateRange();
            if (!vm.dateRangeInvalid) {
                vm.savingXls = true;
                ReclamationReportService.getReport2b(vm.filter,
                function (data) {
                    vm.savingXls = false;
                    FileSaver.saveAs(data.blob, "reclamation_report2b.xlsx");
                }, function (error) {
                    vm.savingXls = false;
                    onError(error);
                });
            }
        }

        function report3 () {
            vm.savingXls = true;
            ReclamationReportService.getReport3(vm.filter,
            function (data) {
                vm.savingXls = false;
                FileSaver.saveAs(data.blob, "reclamation_report3.xlsx");
            }, function (error) {
                vm.savingXls = false;
                onError(error);
            });
        }

        function report4 () {
            vm.savingXls = true;
            ReclamationReportService.getReport4(vm.filter,
            function (data) {
                vm.savingXls = false;
                FileSaver.saveAs(data.blob, "reclamation_report4.xlsx");
            }, function (error) {
                vm.savingXls = false;
                onError(error);
            });
        }

        function resetDate () {
            if (!vm.filter.dateFrom) {
                vm.filter.dateTo = null;
            }
            // validateDateRange();
            resetInvalid();
        }

        function validateDateRange () {
            vm.dateRangeInvalid = false;
            if (vm.filter.dateFrom && vm.filter.dateTo) {
                var dateToMax = (new Date(vm.filter.dateFrom)).setMonth(vm.filter.dateFrom.getMonth() + 2);
                vm.dateRangeInvalid = (vm.filter.dateTo > dateToMax);
            }
        }

        function resetInvalid () {
            vm.dateRangeInvalid = false;
        }

        vm.groupToggles = [];
        vm.toggleGroup = toggleGroup;
        vm.isCollapsed = isCollapsed;

        function toggleGroup (index) {
            var toggleIndex = vm.groupToggles.indexOf(index)
            if (toggleIndex !== -1) {
                vm.groupToggles.splice(toggleIndex, 1);
            } else {
                vm.groupToggles.push(index);
            }
        }

        function isCollapsed (index) {
            return vm.groupToggles.indexOf(index) == -1
        }

        function reportStatistics () {
            vm.savingXls = true;
            ReclamationReportService.getReportStatistics(vm.filter,
                function (data) {
                    vm.savingXls = false;
                    FileSaver.saveAs(data.blob, "reclamation_stat.xlsx");
                }, function (error) {
                    vm.savingXls = false;
                    onError(error);
                });
        }
    }
})();