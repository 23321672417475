(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .constant('paginationConstants', {
            'itemsPerPage': 20,
            'itemsPerPageOptions': [10, 20, 50, 100]
        });
})();
