(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('TranslationStringDeleteDialogController', TranslationStringDeleteDialogController);

    TranslationStringDeleteDialogController.$inject = ['$uibModalInstance', 'data', 'TranslationString'];

    function TranslationStringDeleteDialogController ($uibModalInstance, data, TranslationString) {
        var vm = this;

        vm.translationString = data;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            TranslationString.delete({
                id: vm.translationString.id
            }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();