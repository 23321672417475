(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('CourierOrderSendDialogController', CourierOrderSendDialogController);

    CourierOrderSendDialogController.$inject = ['$uibModalInstance', 'data', 'CourierOrder', 'AlertService'];

    function CourierOrderSendDialogController ($uibModalInstance, data, CourierOrder, AlertService) {
        var vm = this;

        vm.data = data;
        vm.clear = clear;
        vm.confirmSend = confirmSend;
        vm.sending = false;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmSend () {
            vm.sending = true;
            CourierOrder.send({
                client: data.client
            }, data.code, function () {
                vm.sending = false;
                $uibModalInstance.close(true);
            }, function (error) {
                vm.sending = false;
                AlertService.error('error.param.error', { param: error.data.join(';') });
            });
        }
    }
})();