(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .filter('shippingFilter', shippingFilter);

    function shippingFilter () {
        var filter = function (items, params) {
            if (isEmpty(params.sku) &&
                isEmpty(params.lot)) {
                return items;
            }
            
            var filteredItems = [];
            if (angular.isArray(items) && items.length > 0) {
                filteredItems = items.filter(function (item) {
                    return (isEmpty(params.sku) || item.sku.toUpperCase().indexOf(params.sku.toUpperCase()) !== -1) &&
                    (isEmpty(params.lot) || item.lotString.toUpperCase().indexOf(params.lot.toUpperCase()) !== -1);
                });
                return filteredItems;
            }
        };

        var isEmpty = function (item) {
            return (typeof item == 'undefined' || item === null || item === '');
        };

        return filter;
    }
})();