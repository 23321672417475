(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ClientInfoCardEditController', ClientInfoCardEditController);

    ClientInfoCardEditController.$inject = ['data', 'ClientInfo', 'ClientInfoConstants', '$state', '$stateParams'];

    function ClientInfoCardEditController (data, ClientInfo, ClientInfoConstants, $state, $stateParams) {
        var vm = this;

        vm.client = $stateParams.client;
        vm.data = data;
        vm.openCalendar = openCalendar;
        vm.addContact = addContact;
        vm.addStorageUnit = addStorageUnit;
        vm.removeElement = removeElement;
        vm.storageTypes = ClientInfoConstants.storageTypes;
        vm.integrationMethods = ClientInfoConstants.integrationMethods;
        vm.integrationTools = ClientInfoConstants.integrationTools;
        vm.cancel = cancel;
        vm.save = save;

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function addContact () {
            vm.data.contactPersons.push({ fullName: null, position: null, phoneNumber: null, email: null});
        }

        function addStorageUnit () {
            vm.data.storage.push({ description: null, quantity: 0, type: 'PALLET', size: null, surplus: 0, ratio: 0.0});
        }

        function removeElement (array, element) {
            array.splice(array.indexOf(element), 1);
        }

        function cancel () {
            $state.go('client-info', null, {reload: true});
        }

        function save () {
            vm.isSaving = true;
            if (vm.data.id !== null) {
                ClientInfo.update({client: vm.client}, vm.data, onSaveSuccess, onSaveError);
            } else {
                ClientInfo.save({client: vm.client}, vm.data, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess () {
            vm.isSaving = false;
            cancel();
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();