(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('TransportDetailController', TransportDetailController);

    TransportDetailController.$inject = ['transport', 'soTypes', 'poTypes', 'PurchaseOrderStatuses', 'SalesOrderStatuses'];

    function TransportDetailController (transport, soTypes, poTypes, PurchaseOrderStatuses, SalesOrderStatuses) {
        var vm = this;

        vm.transport = transport;
        vm.poStatusValues = PurchaseOrderStatuses;
        vm.soStatusValues = SalesOrderStatuses;
        vm.poTypes = poTypes;
        vm.soTypes = soTypes;

        vm.translateType = translateType;

        function translateType (string, translationArray) {
            return translationArray[string] ? translationArray[string] : string;
        }
    }
})();