(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .constant('TransportSlotsConstants', {
            'LoadTypeOptions': ['Навал', 'На паллетах'],
            'TransportTypeOptions': [
                'Газель',
                'Фура',
                'Самокат'
            ],
        });

})();