(function () {
    'use strict'

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('translation-strings', {
            parent: 'admin',
            url: '/translation-strings',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'translation-string.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/translation-strings/translation-strings.html',
                    controller: 'TranslationStringsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value:'id,asc',
                    squash: true
                },
                filter: {
                    translationKey: null,
                    translationValue: null,
                    stringType: null,
                    langKey: null
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('translation-string');
                    return $translate.refresh();
                }]
            }
        })
    }
})();