(function () {
    'use strict'

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('service-pricelist', {
            parent: 'general-information',
            url: '/service-pricelist',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'service-pricelist.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/general-information/service-pricelist/service-pricelist.html',
                    controller: 'ServicePricelistController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('service-pricelist');
                    return $translate.refresh();
                }]
            }
        })
    }
})();