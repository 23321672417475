(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('calc-courier', {
            parent: 'app',
            url: '/calc/courier',
            data: {
                authorities: [],
                pageTitle: 'calculator.courier.title'
            },
            params: {
                type: 0
            },
            views: {
                'content@': {
                    templateUrl: 'app/calculator/delivery-calculator.html',
                    controller: 'DeliveryCalculatorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('calculator');
                    $translatePartialLoader.addPart('delivery-orders');
                    return $translate.refresh();
                }]
            }
        })
        .state('calc-post', {
            parent: 'app',
            url: '/calc/post',
            data: {
                authorities: [],
                pageTitle: 'calculator.post.title'
            },
            params: {
                type: 1
            },
            views: {
                'content@': {
                    templateUrl: 'app/calculator/delivery-calculator.html',
                    controller: 'DeliveryCalculatorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('calculator');
                    $translatePartialLoader.addPart('delivery-orders');
                    return $translate.refresh();
                }]
            }
        });
    }
})();