(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('RepresentativeDeleteDialogController', RepresentativeDeleteDialogController);

    RepresentativeDeleteDialogController.$inject = ['$uibModalInstance', 'data', 'client', 'Representative'];

    function RepresentativeDeleteDialogController ($uibModalInstance, data, client, Representative) {
        var vm = this;

        vm.client = client;
        vm.representative = data;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            Representative.delete({
                client: vm.client,
                id: vm.representative.id
            }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();