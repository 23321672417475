(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .constant('OrderConstants', {
            'QualityValues': ['Брак', 'Некондиция'],
            'VATRates': {
                "values": [0, 10, 20],
                "strings": {
                    "0": "products.nds.0",
                    "10": "products.nds.10",
                    "18": "products.nds.18",
                    "20": "products.nds.20"
                }
            },
            'PaymentMethods': [{ value: 0, name: "global.paymentMethod.cash"},
                { value: 1, name: "global.paymentMethod.card"},
                { value: 2, name: "global.paymentMethod.prepaid"}]
        });

})();