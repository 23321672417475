(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('TransportCompanyDeleteController', TransportCompanyDeleteController);

    TransportCompanyDeleteController.$inject = ['$uibModalInstance', 'entity', 'TransportCompany'];

    function TransportCompanyDeleteController ($uibModalInstance, entity, TransportCompany) {
        var vm = this;

        vm.data = entity;
        vm.confirmDelete = confirmDelete;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            TransportCompany.delete({
                client: vm.data.client,
                id: id
            }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();