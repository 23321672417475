(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('alert-email-config', {
            parent: 'admin',
            url: '/alert-email-config',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'alertEmailConfig.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/alert-email/alert-email.html',
                    controller: 'AlertEmailConfigController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialProvider: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('alert-email-config');
                    return $translate.refresh();
                }]
            }
        })
        .state('alert-email-config.new', {
            parent: 'alert-email-config',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'alertEmailConfigController.create.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/alert-email/alert-email-edit-dialog.html',
                    controller: 'AlertEmailConfigEditDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null, name: null, requestUrl: null, responseSenderIp: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('alert-email-config', null, { reload: true });
                }, function() {
                    $state.go('alert-email-config');
                });
            }]
        })
        .state('alert-email-config.edit', {
            parent: 'alert-email-config',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'alertEmailConfig.edit.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/alert-email/alert-email-edit-dialog.html',
                    controller: 'AlertEmailConfigEditDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['AlertEmailConfig', function(AlertEmailConfig) {
                            return AlertEmailConfig.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('alert-email-config', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('alert-email-config.delete', {
            parent: 'alert-email-config',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'alertEmailConfig.delete.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/alert-email/alert-email-delete-dialog.html',
                    controller: 'AlertEmailConfigDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['AlertEmailConfig', function(AlertEmailConfig) {
                            return AlertEmailConfig.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('alert-email-config', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();