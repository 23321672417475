(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ReclamationFinalizeDialogController', ReclamationFinalizeDialogController);

    ReclamationFinalizeDialogController.$inject = ['$uibModalInstance'];

    function ReclamationFinalizeDialogController($uibModalInstance) {
        var vm = this;

        vm.status = null;

        vm.save = save;
        vm.clear = clear;

        function save () {
            if (vm.status !== null) {
                $uibModalInstance.close({
                    status: 'CLOSED',
                    subStatus: vm.status
                });
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();