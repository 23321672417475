(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .service('ManagedDocuments', ManagedDocuments);

    ManagedDocuments.$inject = ['$resource'];

    function ManagedDocuments ($resource) {
        var service = $resource('api/document/:type/:client/:id', {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'addComment': {
                url: 'api/document/:type/:client/:id/comment',
                method: 'PUT'
            },
            'setStatus': {
                url: '/api/document/:type/:client/:id/status',
                method: 'PUT'
            },
            'delete': { method: 'DELETE' },
            'getFile': {
                url: '/api/document/:type/:client/:id/file/:filename',
                method: 'GET',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'deleteFile': {
                url: '/api/document/:type/:client/:id/file/:fileId',
                method: 'DELETE'
            },
            'deleteComment': {
                url: '/api/document/:type/:client/:id/comment/:commentId',
                method: 'DELETE'
            },
            'changeCategory': {
                url: '/api/document/:type/:client/:id/category',
                method: 'PUT'
            },
            'updateEmails': {
                url: '/api/document/:type/:client/:id/emails',
                method: 'PUT'
            }
        });

        return service;
    }
})();