(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('WMSShippingAltController', WMSShippingAltController);
    
        WMSShippingAltController.$inject = ['Principal', 'Reports', '$stateParams', 'paginationConstants', 'FileSaver', 'ReportShippingConstants'];

    function WMSShippingAltController (Principal, Reports, $stateParams, paginationConstants, FileSaver, ReportShippingConstants) {
        var vm = this;

        vm.data = [];
        vm.filtersOpen = false;
        vm.filter = $stateParams.filter;
        vm.statusValues = ReportShippingConstants.statusValues;

        vm.page = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.itemsPerPageOptions = paginationConstants.itemsPerPageOptions;
        vm.predicate = 'shippedDate';
        vm.reverse = true;

        vm.loadAll = loadAll;
        vm.openCalendar = openCalendar;
        vm.clearFilter = clearFilter;
        vm.exportToExcel = exportToExcel;

        init();

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                //loadAll(); NOT WITH AN EMPTY FILTER
            });
        }

        function loadAll () {
            Reports.getShipping({
                client: vm.client,
            }, vm.filter, onSuccess);
        }

        function onSuccess (data) {
            vm.data = data;
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function clearFilter () {
            vm.filter = {
                sku: null,
                dateAfter: null,
                dateBefore: null,
                orderNumber: null,
                lot: null
            };
            vm.totalItems = null;
        }

        function exportToExcel() {
            Reports.exportShippingToExcelAlt({
                client: vm.client
            },
            vm.filtered,
            function (data) {
                vm.exportingXlsx = false;
                FileSaver.saveAs(data.blob, "shipping.xlsx");
            },
            function () {
                vm.exportingXlsx = false;
            });
        }
    }
})();