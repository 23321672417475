(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('StaticDocumentCategoryEditController', StaticDocumentCategoryEditController);

    StaticDocumentCategoryEditController.$inject = ['category', '$uibModalInstance', '$stateParams', 'Documents'];

    function StaticDocumentCategoryEditController (category, $uibModalInstance, $stateParams, Documents) {
        var vm = this;

        vm.client = $stateParams.client;
        vm.save = save;
        vm.clear = clear;
        vm.category = category;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if (vm.category.id !== null) {
                Documents.updateCategory({
                    client: vm.client
                }, vm.category, onSaveSuccess, onSaveError);
            } else {
                Documents.createCategory({
                    client: vm.client
                }, vm.category, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }

})();