(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .filter('reclamationItemFilter', reclamationItemFilter);

    function reclamationItemFilter () {
        var filter = function (items, params) {
            if (isEmpty(params.sku) &&
                isEmpty(params.lot) && 
                isEmpty(params.description)) {
                return items;
            }
            
            var filteredItems = [];
            if (angular.isArray(items) && items.length > 0) {
                filteredItems = items.filter(function (item) {
                    return (isEmpty(params.sku) || item.sku.toUpperCase().indexOf(params.sku.toUpperCase()) !== -1) &&
                    (isEmpty(params.lot) || (!isEmpty(item.lot) && item.lot.toUpperCase().indexOf(params.lot.toUpperCase()) !== -1)) &&
                    (isEmpty(params.description) || item.description.toUpperCase().indexOf(params.description.toUpperCase()) !== -1);
                });
                return filteredItems;
            }
        };

        var isEmpty = function (item) {
            return (typeof item == 'undefined' || item === null || item === '');
        };

        return filter;
    }
})();