(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('RepresentativesController', RepresentativesController);

    RepresentativesController.$inject = ['$uibModal', 'Principal', 'Representative', 'AlertService']

    function RepresentativesController ($uibModal, Principal, Representative, AlertService) {
        var vm  = this;

        vm.representatives = [];
        vm.init = init;
        vm.loadAll = loadAll;
        vm.add = addRep;
        vm.edit = editRep;
        vm.delete = deleteRep;

        vm.init();

        function init() {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.clientName = client.name;
                vm.loadAll();
            });
        }

        function loadAll () {
            Representative.query({
                client: vm.client
            }, onSuccess, onError);
        }

        function onSuccess (data) {
            vm.loaded = true;
            vm.representatives = data;
        }

        function onError (error) {
            vm.loaded = true;
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function addRep(role) {
            var addRepresentativeModal = $uibModal.open({
                templateUrl: 'app/general-information/representatives/representative-edit-dialog.html',
                controller: 'RepresentativeEditDialogController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                resolve: {
                    client: function() { return vm.client },
                    data: function () {
                        return {
                            id: null, name: null, position: null, email: null, phoneNumber: null,
                            role: role
                        }
                    }
                }
            }).result.then(function (result) {
                loadAll();
            });
        }

        function editRep(rep) {
            var editRepresentativeModal = $uibModal.open({
                templateUrl: 'app/general-information/representatives/representative-edit-dialog.html',
                controller: 'RepresentativeEditDialogController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                resolve: {
                    client: function() { return vm.client },
                    data: ['Representative', function (Representative) {
                        return Representative.get({
                            client: vm.client,
                            id: rep.id
                        });
                    }]
                }
            }).result.then(function (result) {
                loadAll();
            });
        }

        function deleteRep(rep) {
            var deleteRepresentativeModal = $uibModal.open({
                templateUrl: 'app/general-information/representatives/representative-delete-dialog.html',
                controller: 'RepresentativeDeleteDialogController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    client: function() { return vm.client },
                    data: function() { return rep }
                }
            }).result.then(function (result) {
                loadAll();
            });
        }
    }
})();