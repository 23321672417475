(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('CourierRegistryEditController', CourierRegistryEditController);

    CourierRegistryEditController.$inject = ['AddressTypeahead', '$state', '$stateParams', 'CourierOrderRegistry', 'entity', 'warehouses', 'ShipmentTypes', 'PickupOrderPayers', 'PurchaseOrderRecipientTemplate', 'DeliveryOrderPaymentMethods', '$uibModal', 'StringUtils'];

    function CourierRegistryEditController (AddressTypeahead, $state, $stateParams, CourierOrderRegistry, entity, warehouses, ShipmentTypes, PickupOrderPayers, PurchaseOrderRecipientTemplate, DeliveryOrderPaymentMethods, $uibModal, StringUtils) {
        var vm = this;

        vm.save = save;
        vm.client = $stateParams.client;
        vm.registry = entity;
        vm.cancel = cancel;
        vm.openCalendar = openCalendar;
        vm.shipmentTypes = ShipmentTypes;
        vm.paymentMethods = DeliveryOrderPaymentMethods;
        vm.payers = PickupOrderPayers;
        vm.hasPickup = false;
        vm.changePickup = changePickup;
        vm.addOrder = addOrder;
        vm.removeElement = removeElement;
        vm.concatAddress = StringUtils.concatAddressShort;
        vm.warehouses = warehouses;
        vm.getStreet = getStreet;
        vm.getLocality = getLocality;
        vm.getRegion = getRegion;
        vm.getMetro = getMetro;

        function save () {
            vm.isSaving = true;
            if (vm.registry.id !== null) {
                CourierOrderRegistry.update({client: vm.client}, vm.registry, onSaveSuccess, onSaveError);
            } else {
                CourierOrderRegistry.save({client: vm.client}, vm.registry, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $state.go('courier-registry', null, {reload: true});
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function cancel () {
            $state.go('courier-registry', null, {reload: true});
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function changePickup () {
            if (vm.registry.hasPickup) {
                vm.registry.pickupOrder = {
                    type: "COURIER",
                    recipient: PurchaseOrderRecipientTemplate
                };
            } else {
                vm.registry.pickupOrder = null;
            }
        }

        function addOrder () {
            var addProductModal = $uibModal.open({
                templateUrl: 'app/orders-delivery/add-order-dialog/add-order-dialog.html',
                controller: 'AddOrderDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    client: function () {
                        return vm.client;
                    },
                    orderResource: ['CourierOrder', function (CourierOrder) {
                        return CourierOrder;
                    }]
                }
            }).result.then(function (result) {
                result.forEach(function (element, index, array) {
                    vm.registry.orders.push(element);
                });
            });
        }

        function removeElement (array, index) {
            array.splice(index, 1);
        }

        function getStreet(val) {
            return AddressTypeahead.findStreet(val);
        }

        function getRegion(val) {
            return AddressTypeahead.findRegion(val);
        }

        function getLocality(val) {
            return AddressTypeahead.findLocality(val);
        }

        function getMetro(val) {
            return AddressTypeahead.findMetroStation(val, vm.registry.pickupOrder.warehouse.address.locality);
        }
    }
})();