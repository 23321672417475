(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('Product', Product);

    Product.$inject = ['$resource'];

    function Product ($resource) {
        var service = $resource('api/products/:client/:code', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method: 'POST' },
            'update': { method: 'PUT' },
            'delete': { method: 'DELETE' },
            'find': { method: 'POST', url: 'api/find/products/:client', isArray: true },
            'send': { method: 'POST', url: 'api/external/wms/product/exportAll/:client' },
            'toExcel': {
                method: 'POST',
                url: 'api/excel/product/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'getRemains': { method: 'GET', url: 'api/misc/remains/:client/:code', isArray: true },
            'getTemplate': { 
                method: 'GET', url: 'api/templates/mf-import/:client', responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            }
        });

        return service;
    }
})();