(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('TransportDbLogController', TransportDbLogController);

    TransportDbLogController.$inject = ['$stateParams', '$uibModalInstance', 'Transport', 'AlertService', 'ParseLinks'];

    function TransportDbLogController ($stateParams, $uibModalInstance, Transport, AlertService, ParseLinks) {
        var vm = this;

        vm.client = $stateParams.client;
        vm.code = $stateParams.code;
        vm.result = [];

        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.itemsPerPageOptions = [5, 10, 20, 50, 100];
        vm.totalItems = null;
        vm.links = null;

        vm.clear = clear;
        vm.loadPage = loadPage;

        search();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function search () {
            vm.searching = true;
            vm.page = 1;
            loadPage();
        }

        function loadPage () {
            Transport.getLog({
                client: vm.client,
                code: vm.code,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: 'createdDate,desc'
            }, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.searching = false;
            vm.result = data;
        }

        function onError (error) {
            vm.searching = false;
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }


    }
})();