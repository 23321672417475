(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ManagedDocumentDeleteController', ManagedDocumentDeleteController);

    ManagedDocumentDeleteController.$inject = ['$uibModalInstance', '$stateParams', 'document', 'clientCode', 'ManagedDocuments'];

    function ManagedDocumentDeleteController ($uibModalInstance, $stateParams, document, clientCode, ManagedDocuments) {
        var vm = this;

        vm.document = document;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.client = clientCode;
        vm.type = $stateParams.type;


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ManagedDocuments.delete({
                type: vm.type,
                client: vm.client,
                id: id
            }, function () {
                $uibModalInstance.close(true);
            });
        }

    }
})();