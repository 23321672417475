(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('static-documents', {
            parent: 'documents',
            url: '/documents',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR']
            },
            views: {
                'content@': {
                    templateUrl: 'app/documents/static-documents/static-documents.html',
                    controller: 'DocumentsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('documents');
                    return $translate.refresh();
                }]
            }
        })
        .state('static-documents.new',  {
            parent: 'static-documents',
            url: '/:client/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/documents/static-documents/static-document-edit.html',
                    controller: 'StaticDocumentEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        document: function () {
                            return {
                                id: null, name: null, fileName: null, category: null
                            };
                        },
                        categories: ['Documents', function (Documents) {
                            return Documents.getCategories({ client: $stateParams.client });
                        }]
                    }
                }).result.then(function () {
                    $state.go('static-documents', null, { reload: true });
                }, function () {
                    $state.go('static-documents');
                })
            }]
        })
        .state('static-documents.edit',  {
            parent: 'static-documents',
            url: '/:client/:id/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/documents/static-documents/static-document-edit.html',
                    controller: 'StaticDocumentEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        document: ['Documents', function (Documents) {
                            return Documents.getDocument({ client: $stateParams.client, id: $stateParams.id });
                        }],
                        categories: ['Documents', function (Documents) {
                            return Documents.getCategories({ client: $stateParams.client });
                        }]
                    }
                }).result.then(function () {
                    $state.go('static-documents', null, { reload: true });
                }, function () {
                    $state.go('static-documents');
                })
            }]
        })
        .state('static-documents.delete', {
            parent: 'static-documents',
            url: '/:client/delete/:id',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_OPERATOR'],
                pageTitle: 'documents.delete.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/documents/static-documents/static-document-delete-dialog.html',
                    controller: 'StaticDocumentsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        document: ['Documents',  function (Documents) {
                            return Documents.getDocument({ client: $stateParams.client, id: $stateParams.id });
                        }],
                        client: function () {
                            return $stateParams.client;
                        }
                    }
                }).result.then(function () {
                    $state.go('static-documents', null, { reload: true });
                }, function () {
                    $state.go('^');
                });
            }]
        })
        .state('static-documents.new-category',  {
            parent: 'static-documents',
            url: '/:client/new-category',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_OPERATOR']
            },
            onEnter: ['$state', '$uibModal', function ($state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/documents/static-documents/static-document-category-edit.html',
                    controller: 'StaticDocumentCategoryEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        category: function () {
                            return {
                                id: null, name: null, dirName: null
                            };
                        }
                    }
                }).result.then(function () {
                    $state.go('static-documents', null, { reload: true });
                }, function () {
                    $state.go('^');
                })
            }]
        })
        .state('static-documents.list-categories',  {
            parent: 'static-documents',
            url: '/:client/list-categories',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_OPERATOR']
            },
            views: {
                'content@': {
                    templateUrl: 'app/documents/static-documents/static-document-category-list.html',
                    controller: 'StaticDocumentCategoryListController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                list: ['$stateParams', 'Documents', function ($stateParams, Documents) {
                    return Documents.getCategories({ client: $stateParams.client});
                }]
            }
        })
        .state('static-documents.list-categories.edit',  {
            parent: 'static-documents.list-categories',
            url: '/edit/:id',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/documents/static-documents/static-document-category-edit.html',
                    controller: 'StaticDocumentCategoryEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        category: ['Documents', function (Documents) {
                            return Documents.getCategory({ client: $stateParams.client, id: $stateParams.id });
                        }]
                    }
                }).result.then(function (result) {
                    $state.go('static-documents.list-categories', null, { reload: true });
                }, function () {
                    $state.go('^');
                })
            }]
        })
        .state('static-documents.list-categories.delete', {
            parent: 'static-documents.list-categories',
            url: '/delete/:id',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_OPERATOR'],
                pageTitle: 'documents.deleteCategory.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/documents/static-documents/static-document-category-delete-dialog.html',
                    controller: 'StaticDocumentCategoryDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        category: ['Documents',  function (Documents) {
                            return Documents.getCategory({ client: $stateParams.client, id: $stateParams.id });
                        }],
                        client: function () {
                            return $stateParams.client;
                        }
                    }
                }).result.then(function (result) {
                    $state.go('static-documents.list-categories', null, { reload: true });
                }, function () {
                    $state.go('^');
                });
            }]
        })
    }
})();