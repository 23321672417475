(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('DatetimePickerConfig', DatetimePickerConfig);

    DatetimePickerConfig.$inject = ['$filter'];

    function DatetimePickerConfig ($filter) {

        var service = {
            buttonBar: buttonBar,
            timepickerOptions: timepickerOptions,
            modelOptions: modelOptions
        }

        return service;

        function buttonBar () {
            return {
                show: true,
                now: {
                    show: true,
                    text: $filter('translate')('datepicker.now'),
                    cls: 'btn-sm btn-info'
                },
                today: {
                    show: true,
                    text: $filter('translate')('datepicker.today'),
                    cls: 'btn-sm btn-info'
                },
                clear: {
                    show: true,
                    text: $filter('translate')('datepicker.clear'),
                    cls: 'btn-sm btn-danger'
                },
                date: {
                    show: true,
                    text: $filter('translate')('datepicker.date'),
                    cls: 'btn-sm btn-default'
                },
                time: {
                    show: true,
                    text: $filter('translate')('datepicker.time'),
                    cls: 'btn-sm btn-default'
                },
                close: {
                    show: true,
                    text: $filter('translate')('datepicker.close'),
                    cls: 'btn-sm btn-success'
                },
                cancel: {
                    show: false,
                    text: $filter('translate')('datepicker.cancel'),
                    cls: 'btn-sm btn-default'
                }
            }
        }

        function timepickerOptions () {
            return {
                showMeridian: false
            }
        }

        function modelOptions () {
            return {
                timezone: 'utc'
            }
        }
    }
})();