(function() {
'use strict';

    angular
        .module('rbllkApp')
        .controller('ClientManagementController', ClientManagementController);

    ClientManagementController.$inject = ['Client', 'ParseLinks', 'AlertService', '$state', 'pagingParams', 'paginationConstants', '$scope', '$localStorage', '$stateParams', '$sessionStorage'];
    function ClientManagementController(Client, ParseLinks, AlertService, $state, pagingParams, paginationConstants, $scope, $localStorage, $stateParams, $sessionStorage) {
        var vm = this;

        $scope.$storage = $localStorage;
        if(!$scope.$storage.clientColumns) { //due to $default issue with restoring storage
            $localStorage.clientColumns = { innKpp: false, created: true, lastModifiedBy: true, lastModifiedDate: true };
        }
        vm.shownColumns = $scope.$storage.clientColumns;
        if (!$scope.$storage.itemsPerPage.clientList) {
            $localStorage.itemsPerPage.clientList = paginationConstants.itemsPerPage;
        }
        vm.itemsPerPage = $scope.$storage.itemsPerPage.clientList;

        vm.page = 1;
        vm.totalItems = null;
        vm.clear = clear;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        
        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        vm.clients = [];
        vm.itemsPerPageOptions = paginationConstants.itemsPerPageOptions;

        if (!$sessionStorage.filters.clientManagement) {
            $sessionStorage.filters.clientManagement = { code: null, name: null, owner: null };
        }
        vm.searchParams = $sessionStorage.filters.clientManagement;
        
        vm.loadAll = loadAll;
        vm.setActive = setActive;
        vm.changeItemsPerPage = changeItemsPerPage;
        vm.syncClientNames = syncClientNames;
        vm.syncClientGroups = syncClientGroups;

        vm.loadAll();

        function loadAll() {
            Client.find({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, vm.searchParams, onSuccess, onError);
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.clients = data;
        }

        function onError(error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function clear() {
            vm.client = {
                id: null, name: null, code: null, info: null, reatedBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, availableServices: null
            };
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function setActive (client, isActive) {
            client.active = isActive;
            Client.update(client, function () {
               vm.loadAll();
            });
        }

        function changeItemsPerPage () {
            // 2-way binding doesn't seem to work here for some reason
            $scope.$storage.itemsPerPage.clientList = vm.itemsPerPage;
            loadAll();
        }

        function syncClientNames () {
            Client.syncNames();
            loadAll();
        }

        function syncClientGroups () {
            Client.syncGroups();
            loadAll();
        }
    }
})();