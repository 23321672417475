(function() {
'use strict';

    angular
        .module('rbllkApp')
        .service('Documents', Documents);

    Documents.$inject = ['$resource', 'Blob'];

    function Documents($resource, Blob) {
        var service = $resource('api/document/static/:client/:id', {}, {
            'getDocument': { method: 'GET' },
            'updateDocument': { method: 'PUT' },
            'createDocument': { method: 'POST' },
            'findDocuments': { url: 'api/find/document/:client', method: 'POST' },
            'deleteDocument': { method: 'DELETE' },
            'getFile': {
                url: 'api/document/static/:client/:id/file',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'getCategory': { url: 'api/document/category/:client/:id', method: 'GET'},
            'getCategories': { url: 'api/document/category/:client', method: 'GET', isArray: true },
            'createCategory': { url: 'api/document/category/:client', method: 'POST'},
            'updateCategory': { url: 'api/document/category/:client', method: 'PUT'},
            'deleteCategory': { url: 'api/document/category/:client/:id', method: 'DELETE' },
        });

        return service;
    }
})();