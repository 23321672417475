(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('UserAvailableCategoriesController', UserAvailableCategoriesController);

    UserAvailableCategoriesController.$inject = ['allCategories', 'availableCategories', 'UserCategories', '$stateParams', '$state', 'AlertService'];

    function UserAvailableCategoriesController (allCategories, availableCategories, UserCategories, $stateParams, $state, AlertService) {
        var vm = this;

        vm.allCategories = allCategories;
        vm.availableCategories = availableCategories;
        vm.login = $stateParams.login;

        vm.toggleCategory = toggleCategory;
        vm.hasCategory = hasCategory;
        vm.cancel = cancel;
        vm.save = save;

        function toggleCategory (clientId, categoryId) {
            if (!vm.availableCategories[clientId]) {
                vm.availableCategories[clientId] = [];
            }
            var index = vm.availableCategories[clientId].indexOf(categoryId);
            if (index === -1) {
                vm.availableCategories[clientId].push(categoryId);
            } else {
                vm.availableCategories[clientId].splice(index, 1);
            }
        }

        function hasCategory (clientId, categoryId) {
            return vm.availableCategories[clientId] && vm.availableCategories[clientId].indexOf(categoryId) !== -1;
        }

        function cancel () {
            $state.go('user-management', null, {reload: true});
        }

        function save () {
            vm.isSaving = true;
            UserCategories.save({ login: vm.login }, vm.availableCategories, function () {
                vm.isSaving = false;
            }, onError);
        }

        function onError (error) {
            vm.isSaving = false;
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }
    }
})();