(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('JhiMetricsMonitoringController', JhiMetricsMonitoringController);

    JhiMetricsMonitoringController.$inject = ['$scope','JhiMetricsService', '$uibModal', '$sessionStorage'];

    function JhiMetricsMonitoringController ($scope, JhiMetricsService, $uibModal, $sessionStorage) {
        var vm = this;

        vm.cachesStats = {};
        vm.metrics = {};
        vm.servicesStats = {};
        vm.threadDump = {};
        vm.updatingMetrics = true;
        vm.activeTab = $sessionStorage.activeMetricsTabIdx;

        vm.refresh = refresh;
        vm.refreshThreadDumpData = refreshThreadDumpData;
        vm.calculatePercentage = calculatePercentage;
        vm.saveTabIdx = saveTabIdx;

        vm.refresh();

        $scope.$watch('vm.metrics', function (newValue) {
            vm.servicesStats = [];
            angular.forEach(newValue.services, function (value, key) {
                angular.forEach(value, function (data, method) {
                    vm.servicesStats.push({
                        endpoint: key,
                        method: method,
                        max: data.max,
                        mean: data.mean,
                        count: data.count
                    });
                });
            });
        });

        $scope.$watch('vm.threadDump', function (newValue) {
            if (vm.threadDump && vm.threadDump.threads) {
                vm.threadStats = {
                    threadDumpRunnable: 0,
                    threadDumpWaiting: 0,
                    threadDumpTimedWaiting: 0,
                    threadDumpBlocked: 0,
                    threadDumpAll: 0
                };

                vm.threadDump.threads.forEach(function(item) {
                    if (item.threadState === 'RUNNABLE') {
                        vm.threadStats.threadDumpRunnable += 1;
                    } else if (item.threadState === 'WAITING') {
                        vm.threadStats.threadDumpWaiting += 1;
                    } else if (item.threadState === 'TIMED_WAITING') {
                        vm.threadStats.threadDumpTimedWaiting += 1;
                    } else if (item.threadState === 'BLOCKED') {
                        vm.threadStats.threadDumpBlocked += 1;
                    }
                });

                vm.threadStats.threadDumpAll =
                    vm.threadStats.threadDumpRunnable +
                    vm.threadStats.threadDumpWaiting +
                    vm.threadStats.threadDumpTimedWaiting +
                    vm.threadStats.threadDumpBlocked;
            }
        })

        function refresh () {
            vm.updatingMetrics = true;
            JhiMetricsService.getMetrics().then(function (promise) {
                vm.metrics = promise;
                JhiMetricsService.threadDump().then(function (promise) {
                    vm.threadDump = promise;
                    vm.updatingMetrics = false;
                }, refreshFailure);
            }, refreshFailure);
        }

        function refreshFailure (promise) {
            vm.metrics = promise.data;
            vm.updatingMetrics = false;
        }

        function refreshThreadDumpData () {
            $uibModal.open({
                templateUrl: 'app/admin/metrics/metrics.modal.html',
                controller: 'JhiMetricsMonitoringModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    threadDump: function() {
                        return vm.threadDump.threads;
                    },
                    threadStats: function() {
                        return vm.threadStats;
                    }
                }
            });
        }

        function calculatePercentage (value, total) {
            if (total != 0) {
                return (100 * value) / total;
            } else {
                return 0;
            }
        }

        function saveTabIdx (index) {
            $sessionStorage.activeMetricsTabIdx = index;
        }
    }
})();
