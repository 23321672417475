(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$state', 'Principal', '$sessionStorage', 'SidebarService'];

    function SidebarController($state, Principal, $sessionStorage, SidebarService) {
        var vm = this;

        vm.isAuthenticated = Principal.isAuthenticated;
        vm.$state = $state;
        $sessionStorage.$default({
            collapsed: {
                catalogs: true,
                ordersWH: true,
                ordersDelivery: true,
                documents: true,
                reports: true,
                calc: true,
                generalInfo: true
            }
        });
        vm.collapsed = $sessionStorage.collapsed;
        vm.selectMenuItem = selectMenuItem;
        vm.isSidebarCollapsed = SidebarService.getState;

        function selectMenuItem(menuItem) {
            if (vm.collapsed[menuItem]) {
                Object.keys(vm.collapsed).forEach(function (key) {
                    if(key != menuItem) {
                        vm.collapsed[key] = true;
                    }
                })
            }
            vm.collapsed[menuItem] = !vm.collapsed[menuItem];
        }
    }
})();