(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .constant('UserConstants', {
            'AuthorityOptions': [
                'ROLE_USER',
                'ROLE_EDITOR',
                'ROLE_ADMIN',
                'ROLE_OPERATOR',
                'ROLE_CLIENT_INFO_EDITOR',
                'ROLE_DOC_ACCESS',
                'ROLE_PRICELIST_ACCESS',
                'ROLE_API_ACCESS',
                'ROLE_ITEM_EXPORT',
                'ROLE_RECL_CLAIM',
                'ROLE_RECL_PROCESS',
                'ROLE_MIX_TRANSPORT'
            ],
        });

})();