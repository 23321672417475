(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('FinanceServiceConfigurationDialogController', FinanceServiceConfigurationDialogController);

    FinanceServiceConfigurationDialogController.$inject = ['$uibModalInstance', 'entity','ExternalServiceConfig'];

    function FinanceServiceConfigurationDialogController ($uibModalInstance, entity, ExternalServiceConfig) {
        var vm = this;

        vm.config = entity;
        vm.clear = clear;
        vm.save = save;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            if (vm.config.id !== null) {
                ExternalServiceConfig.update({type: 'finance'}, vm.config, onSaveSuccess, onSaveError);
            } else {
                ExternalServiceConfig.save({type: 'finance'}, vm.config, onSaveSuccess, onSaveError);
            }
        }
    }
})();