(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('wms-reports', {
            parent: 'reports',
            url: '/reports/wms',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'wmsReports.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/reports/wms-reports/wms-reports.html',
                    controller: 'WMSReportsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialProvider: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wms-reports');
                    return $translate.refresh();
                }]
            }
        })
        .state('wms-reports.inventory', {
            parent: 'wms-reports',
            url: '/inventory',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'wmsReports.inventory.title'
            },
            views: {
                'report': {
                    templateUrl: 'app/reports/wms-reports/wms-inventory.html',
                    controller: 'WMSInventoryController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'sku,asc',
                    squash: true
                },
                filter: {
                    sku: null,
                    description: null,
                    expAfter: null,
                    expBefore: null,
                    mfgAfter: null,
                    mfgBefore: null,
                    qtyGt: null,
                    qtyLt: null,
                    lotString: null,
                    status: null
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]
            }
        })
        .state('wms-reports.movements', {
            parent: 'wms-reports',
            url: '/movements',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'wmsReports.movements.title'
            },
            views: {
                'report': {
                    templateUrl: 'app/reports/wms-reports/wms-movements.html',
                    controller: 'WMSMovementsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'date,desc',
                    squash: true
                },
                filter: {
                    sku: null,
                    description: null,
                    dateAfter: null,
                    dateBefore: null,
                    quantityGt: null,
                    quantityLt: null,
                    uom: null,
                    hold: null,
                    doctype: null,
                    orderNumber: null,
                    lot: null
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]
            }
        })
        .state('wms-reports.shipping', {
            parent: 'wms-reports',
            url: '/shipping',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'wmsReports.shipping.title'
            },
            views: {
                'report': {
                    templateUrl: 'app/reports/wms-reports/wms-shipping.html',
                    controller: 'WMSShippingController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'date,desc',
                    squash: true
                },
                filter: {
                    sku: null,
                    dateAfter: null,
                    dateBefore: null,
                    orderNumber: null,
                    lotString: null
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]
            }
        })
        .state('wms-reports.receiving', {
            parent: 'wms-reports',
            url: '/receiving',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'wmsReports.receiving.title'
            },
            views: {
                'report': {
                    templateUrl: 'app/reports/wms-reports/wms-receiving.html',
                    controller: 'WMSReceivingController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'closeDate,desc',
                    squash: true
                },
                filter: {
                    sku: null,
                    closeDateAfter: null,
                    closeDateBefore: null,
                    orderNumber: null,
                    lotString: null
                },
                client: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]
            }
        })
        .state('wms-reports.inventory-alt', {
            parent: 'wms-reports',
            url: '/inventory-alt',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'wmsReports.inventory.title'
            },
            views: {
                'report': {
                    templateUrl: 'app/reports/wms-reports/wms-inventory-alt.html',
                    controller: 'WMSInventoryAltController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'sku,asc',
                    squash: true
                },
                filter: {
                    sku: null,
                    description: null,
                    expAfter: null,
                    expBefore: null,
                    mfgAfter: null,
                    mfgBefore: null,
                    qtyGt: null,
                    qtyLt: null,
                    lotString: null,
                    status: null
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]
            }
        })
        .state('wms-reports.receiving-alt', {
            parent: 'wms-reports',
            url: '/receiving-alt',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'wmsReports.receiving.title'
            },
            views: {
                'report': {
                    templateUrl: 'app/reports/wms-reports/wms-receiving-alt.html',
                    controller: 'WMSReceivingAltController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'sku,asc',
                    squash: true
                },
                filter: {
                    sku: null,
                    closeDateAfter: null,
                    closeDateBefore: null,
                    orderNumber: null,
                    lot: null
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]
            }
        })
        .state('wms-reports.shipping-alt', {
            parent: 'wms-reports',
            url: '/shipping-alt',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'wmsReports.shipping.title'
            },
            views: {
                'report': {
                    templateUrl: 'app/reports/wms-reports/wms-shipping-alt.html',
                    controller: 'WMSShippingAltController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'sku,asc',
                    squash: true
                },
                filter: {
                    sku: null,
                    dateAfter: null,
                    dateBefore: null,
                    orderNumber: null,
                    lot: null
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]
            }
        })
        .state('wms-reports.movements-alt', {
            parent: 'wms-reports',
            url: '/movements-alt',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'wmsReports.movements.title'
            },
            views: {
                'report': {
                    templateUrl: 'app/reports/wms-reports/wms-movements-alt.html',
                    controller: 'WMSMovementsAltController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'date,desc',
                    squash: true
                },
                filter: {
                    sku: null,
                    dateAfter: null,
                    dateBefore: null,
                    hold: null,
                    doctype: null,
                    orderNumber: null,
                    lot: null
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]
            }
        })
        .state('wms-reports.so-status', {
            parent: 'wms-reports',
            url: '/so-status',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'wmsReports.soStatus.title'
            },
            views: {
                'report': {
                    templateUrl: 'app/reports/wms-reports/wms-status.html',
                    controller: 'WMSSoStatusController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'dueDate,desc',
                    squash: true
                },
                filter: {
                    soNumber: null
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]
            }
        });
    }
})();