(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .constant('TransportConstants', {
            'TypeOptions': ['PO', 'SO', 'MIX', 'EMPTY'],
            'StatusOptions': [
                'NEW',
                'IMPORTED',
                'PLANED',
                'ALLOW_IN',
                'ON_TERRITORY_BEFORE',
                'ARRIVED',
                'ARRIVE',
                'ON_TERRITORY',
                'ON_DOCK',
                'ON_TERRITORY_AFTER',
                'ALLOW_OUT',
                'WAIT_OUT',
                'CLOSED',
                'PROBLEM',
                'WEIGHTED'
            ],
        });

})();