(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('StaticDocumentsDeleteController', StaticDocumentsDeleteController);

    StaticDocumentsDeleteController.$inject = ['$uibModalInstance', 'document', 'client', 'Documents'];

    function StaticDocumentsDeleteController ($uibModalInstance, document, client, Documents) {
        var vm = this;

        vm.document = document;
        vm.client = client;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            Documents.deleteDocument({
                client: vm.client,
                id: vm.document.id
            }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();