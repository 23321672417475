(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ReclamationDeleteController', ReclamationDeleteController);

    ReclamationDeleteController.$inject = ['$uibModalInstance', 'data', 'Reclamation'];

    function ReclamationDeleteController ($uibModalInstance, data, Reclamation) {
        var vm = this;

        vm.reclamation = data;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            Reclamation.delete({
                id: vm.reclamation.id
            }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();