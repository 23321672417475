(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ClientManagementEditController', ClientManagementEditController);

    ClientManagementEditController.$inject = ['$state', '$stateParams', 'entity', 'Client', 'AlertService', 'ClientManagementConstants', 'financeConfigs', 'wsConfigs', 'WMSConfigurationConstants', 'sqlConfigs'];
    function ClientManagementEditController($state, $stateParams, entity, Client, AlertService, ClientManagementConstants, financeConfigs, wsConfigs, WMSConfigurationConstants, sqlConfigs) {
        var vm = this;

        vm.services = ClientManagementConstants.AvailableServiceList;
        vm.cancel = cancel;
        vm.save = save;
        vm.client = entity;
        vm.wsConfigs = wsConfigs;
        vm.sqlConfigs = sqlConfigs;
        vm.hasAnyService = hasAnyService;
        vm.deliveryServices = ClientManagementConstants.DeliveryServices;
        vm.warehouseServices = ClientManagementConstants.WarehouseServices;
        vm.financeConfigs = financeConfigs;
        vm.batchControlTypes = WMSConfigurationConstants.batchControlTypes;
        vm.serialNumberControlTypes = WMSConfigurationConstants.serialNumberControlTypes;
        vm.expirationControlTypes = WMSConfigurationConstants.expirationControlTypes;
        vm.kitTypes = WMSConfigurationConstants.kitTypes;
        vm.flagsOpen = true;

        function cancel() {
            $state.go('client-management', null, {reload: true});
        }

        function save () {
            vm.isSaving = true;
            if (vm.client.id !== null) {
                Client.update(vm.client, onSaveSuccess, onSaveError);
            } else {
                Client.save(vm.client, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $state.go('client-management', null, {reload: true});
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function hasAnyService(services) { //might be performance heavy
            if (vm.client && vm.client.availableServices) {
                return services.some(function(service) {
                    return vm.client.availableServices.indexOf(service)!==-1;
                });
            }
            return false;
        }
    }
})();