(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig(stateProvider) {
        stateProvider
        .state('s3filestorage', {
            parent: 'external-configuration',
            url: '/s3filestorage',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'S3 File Storage'
            },
            views: {
                'ext-configuration': {
                    templateUrl: 'app/admin/external-configuration/s3filestorage/s3filestorage.html',
                    controller: 'S3FileStorageController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();