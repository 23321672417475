(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('PurchaseOrderDeleteController', PurchaseOrderDeleteController);

    PurchaseOrderDeleteController.$inject = ['$uibModalInstance', 'orderData', 'PurchaseOrder', 'Base64utf8'];

    function PurchaseOrderDeleteController ($uibModalInstance, orderData, PurchaseOrder, Base64utf8) {
        var vm = this;

        vm.order = orderData;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (orderCode) {
            PurchaseOrder.delete({
                client: vm.order.clientCode,
                code: Base64utf8.encodeMod(orderCode),
                type: vm.order.type
            }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();