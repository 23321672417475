(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .filter('filesize', filesize);

    function filesize () {
        var units = ["B", "kB", "MB", "GB"];

        return filesizeFilter;

        function filesizeFilter (bytes, precision) {
            if (isNaN(parseFloat(bytes))) {
                return "-";
            }
            if (bytes < 1) {
                return "0 B";
            }
            if(isNaN(precision)) {
                precision = 0;
            }
            var unitIndex = Math.floor(Math.log(bytes) / Math.log(1024));
            var value = bytes / Math.pow(1024, unitIndex);
            var unit = unitIndex < units.length ? units[unitIndex] : units[units.length - 1]
            return value.toFixed(precision) + " " + unit;
        }

    }
})();