(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('OrderImportLogDetailController', OrderImportLogDetailController);

    OrderImportLogDetailController.$inject = ['data', '$uibModalInstance'];

    function OrderImportLogDetailController (data, $uibModalInstance) {
        var vm = this;

        vm.data = data;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();