(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .service('AddressSearch', AddressSearch);

    AddressSearch.$inject = ['$resource'];

    function AddressSearch ($resource) {
        var service = $resource('api/esearch/', {}, {
            'searchAddress': { url: 'api/esearch/address/:type/:term', method: 'GET', isArray: true },
            'searchMetroStation': { url: 'api/esearch/metro/:city/:term', method: 'GET', isArray: true }
        });

        return service;
    }
})();