(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('CourierOrderEditController', CourierOrderEditController);

    CourierOrderEditController.$inject = ['AddressTypeahead', '$state', '$stateParams', 'CourierOrder', 'entity', '$uibModal', 'warehouses', 'DeliveryOrderPaymentMethods', 'StringUtils'];

    function CourierOrderEditController (AddressTypeahead, $state, $stateParams, CourierOrder, entity, $uibModal, warehouses, DeliveryOrderPaymentMethods, StringUtils) {
        var vm = this;

        vm.save = save;
        vm.client = $stateParams.client;
        vm.order = entity;
        vm.cancel = cancel;
        vm.paymentMethods = DeliveryOrderPaymentMethods;
        vm.datepickerFromOptions = { maxDate: vm.order.deliveryTimeTo };
        vm.datepickerToOptions = { minDate: vm.order.deliveryTimeFrom };
        vm.openCalendar = openCalendar;
        vm.addCargoSpace = addCargoSpace;
        vm.addProduct = addProduct;
        vm.removeElement = removeElement;
        vm.warehouses = warehouses;
        vm.concatAddress = StringUtils.concatAddressShort;
        vm.getStreet = getStreet;
        vm.getLocality = getLocality;
        vm.getRegion = getRegion;
        vm.getMetro = getMetro;

        function save () {
            vm.isSaving = true;
            if (vm.order.id !== null) {
                CourierOrder.update({client: vm.client}, vm.order, onSaveSuccess, onSaveError);
            } else {
                CourierOrder.save({client: vm.client}, vm.order, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $state.go('courier-orders', null, {reload: true});
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function cancel () {
            $state.go('courier-orders', null, {reload: true});
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function addCargoSpace () {
            vm.order.cargoSpaces.push({
                number: null,
                warehouse: null
            });
        }

        function addProduct () {
            var addProductModal = $uibModal.open({
                templateUrl: 'app/dialog/add-product-dialog/add-product-dialog.html',
                controller: 'AddProductDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    client: function () { return vm.client; },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('products');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function (result) {
                result.forEach(function (element, index, array) {
                    vm.order.products.push({
                        product: element,
                        quantity: 0,
                        cost: element.cost
                    });
                });
            });
        }

        function removeElement (array, index) {
            array.splice(index, 1);
        }

        function getStreet(val) {
            return AddressTypeahead.findStreet(val);
        }

        function getRegion(val) {
            return AddressTypeahead.findRegion(val);
        }

        function getLocality(val) {
            return AddressTypeahead.findLocality(val);
        }

        function getMetro(val) {
            return AddressTypeahead.findMetroStation(val, vm.order.address.locality);
        }
    }
})();