(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('CourierOrderDetailController', CourierOrderDetailController);

    CourierOrderDetailController.$inject = ['entity', 'DeliveryOrderStatuses', 'DeliveryOrderPaymentMethods', 'StringUtils'];

    function CourierOrderDetailController (entity, DeliveryOrderStatuses, DeliveryOrderPaymentMethods, StringUtils) {
        var vm = this;

        vm.order = entity;
        vm.statusValues = DeliveryOrderStatuses;
        vm.stringifyAddress = stringifyAddress;
        vm.paymentMethods = DeliveryOrderPaymentMethods;

        function stringifyAddress (address) {
            return StringUtils.stringifyAddress(address);
        }
    }
})();