(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ReclamationStatusLogDialogController', ReclamationStatusLogDialogController);

    ReclamationStatusLogDialogController.$inject = ['data', '$uibModalInstance', 'ReclamationUtils'];

    function ReclamationStatusLogDialogController(data, $uibModalInstance, ReclamationUtils) {
        var vm = this;

        vm.logEntries = data;
        
        vm.clear = clear;
        vm.getStatusKey = getStatusKey;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function getStatusKey(status, subStatus) {
            return ReclamationUtils.getStatusLabelKey(status, subStatus);
        }
    }
})();