(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('WMSReportsController', WMSReportsController);

    WMSReportsController.$inject = [];

    function WMSReportsController () {
        var vm = this;

        vm.init = init;

        vm.init();

        function init () {

        }
    }
})();