(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .constant('SalesOrderStatuses', {
            "values": [0, 1, 2, 3, 4, 5, 6, 60, 100, 101],
            "strings": {
                "0": "salesOrders.statusValue.localNew",
                "1": "salesOrders.statusValue.localSent",
                "2": "salesOrders.statusValue.imported",
                "3": "salesOrders.statusValue.released",
                "4": "salesOrders.statusValue.picked",
                "5": "salesOrders.statusValue.packed",
                "6": "salesOrders.statusValue.shipped",
                "60": "salesOrders.statusValue.cancelled",
                "-1": "salesOrders.statusValue.invalid",
                "100": "salesOrders.statusValue.unfinished",
                "101": "salesOrders.statusValue.finished"
            }
        });
})();