(function () {
    'use strict';

    angular.module('rbllkApp').controller('ProductDeleteController', ProductDeleteController);

    ProductDeleteController.$inject = ['$uibModalInstance', 'entity', 'clientCode', 'Product', 'Base64utf8'];

    function ProductDeleteController ($uibModalInstance, entity, clientCode, Product, Base64utf8) {
        var vm = this;

        vm.product = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.client = clientCode;


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (code) {
            Product.delete({
                client: vm.client,
                code: Base64utf8.encodeMod(code)
            }, function () {
                $uibModalInstance.close(true);
            });
        }

    }
})();