(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ReclamationClientDetailController', ReclamationClientDetailController);

    ReclamationClientDetailController.$inject = ['entity', 'Reclamation', 'ReclamationUtils', 'FileSaver', 'AlertService', '$state', '$uibModal'];

    function ReclamationClientDetailController(entity, Reclamation, ReclamationUtils, FileSaver, AlertService, $state, $uibModal) {
        var vm = this;

        vm.reclamation = entity;
        vm.isDownloading = false;
        vm.lineToggles = [];

        vm.downloadFile = downloadFile;
        vm.toggleLine = toggleLine;
        vm.isCollapsed = isCollapsed;
        vm.cancel = cancel;
        vm.isRegistered = isRegistered;
        vm.showStatusLogDialog = showStatusLogDialog;
        vm.getStatusKey = getStatusKey;

        function downloadFile (file) {
            vm.isDownloading = true;
            Reclamation.downloadFile({
                clientId: vm.reclamation.client.id,
                fileId: file.id
            }, function (data) {
                vm.isDownloading = false;
                var filename = file.filename;
                FileSaver.saveAs(data.blob, filename);
            }, function (error) {
                vm.isDownloading = false;
                onError(error);
            })
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function toggleLine (index) {
            var toggleIndex = vm.lineToggles.indexOf(index)
            if (toggleIndex !== -1) {
                vm.lineToggles.splice(toggleIndex, 1);
            } else {
                vm.lineToggles.push(index);
            }
        }

        function isCollapsed (index) {
            return vm.lineToggles.indexOf(index) == -1
        }

        function cancel () {
            $state.go('^');
        }

        function isRegistered () {
            return vm.reclamation.status !== 'DRAFT' && vm.reclamation.status !== 'CANCELLED';
        }

        function showStatusLogDialog () {
            var statusLogModal = $uibModal.open({
                templateUrl: 'app/orders-warehouse/reclamations/dialogs/reclamation-status-log-dialog.html',
                controller: 'ReclamationStatusLogDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    data: ['Reclamation', function (Reclamation) {
                        return Reclamation.getStatusChangelog({
                            id: vm.reclamation.id
                        });
                    }]
                }
            })
        }

        function getStatusKey(status, subStatus) {
            return ReclamationUtils.getStatusLabelKey(status, subStatus);
        }
    }
})();