(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('CourierOrder', CourierOrder);

    CourierOrder.$inject = ['$resource'];

    function CourierOrder ($resource) {
        var service = $resource('api/courier/:client/:code', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    if (data.orderCreationDate) {
                        data.orderCreationDate = new Date(data.orderCreationDate);
                    }
                    if (data.deliveryTimeFrom) {
                        data.deliveryTimeFrom = new Date(data.deliveryTimeFrom);
                    }
                    if (data.deliveryTimeTo) {
                        data.deliveryTimeTo = new Date(data.deliveryTimeTo);
                    }
                    return data;
                }
            },
            'save': { method: 'POST' },
            'update': { method: 'PUT' },
            'delete': { method: 'DELETE' },
            'send': { method: 'POST', url: 'api/external/courier/send/:client', isArray: true },
            'status': { method: 'POST', url: 'api/external/courier/status/:client', isArray: true },
            'find': {
                method: 'POST',
                url: 'api/find/courier/:client',
                isArray: true
            },
            'cost': { method: 'POST', url: '/api/external/courier/cost/:client' },
            'labels': { method: 'POST', url: '/api/external/courier/labels/:client', isArray: true },
            'toExcel': {
                method: 'POST',
                url: 'api/excel/courier/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            }
        });

        return service;
    }
})();