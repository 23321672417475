(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('TranslationStringsController', TranslationStringsController);

    TranslationStringsController.$inject = ['$uibModal', '$state', '$stateParams', 'TranslationString', 'AlertService', 'ParseLinks', 'pagingParams', 'paginationConstants']

    function TranslationStringsController ($uibModal, $state, $stateParams, TranslationString, AlertService, ParseLinks, pagingParams, paginationConstants) {
        var vm  = this;

        vm.translationStrings = [];
        vm.loadAll = loadAll;
        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.searchParams = $stateParams.filter;
        vm.addString = addString;
        vm.editString = editString;
        vm.deleteString = deleteString;

        vm.loadAll();

        function loadAll () {
            TranslationString.find({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, vm.searchParams, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.translationStrings = data;
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage () {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filter: vm.searchParams
            });
        }

        function addString () {
            var addTranslationStringModal = $uibModal.open({
                templateUrl: 'app/admin/translation-strings/translation-string-edit-dialog.html',
                controller: 'TranslationStringEditDialogController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    data: function () {
                        return {
                            id: null, key: null, value: null, type: null, langKey: null
                        }
                    }
                }
            }).result.then(function (result) {
                loadAll();
            });
        }

        function editString (rep) {
            var editTranslationStringModal = $uibModal.open({
                templateUrl: 'app/admin/translation-strings/translation-string-edit-dialog.html',
                controller: 'TranslationStringEditDialogController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    data: ['TranslationString', function (TranslationString) {
                        return TranslationString.get({ id: rep.id });
                    }]
                }
            }).result.then(function (result) {
                loadAll();
            });
        }

        function deleteString (rep) {
            var deleteTranslationStringModal = $uibModal.open({
                templateUrl: 'app/admin/translation-strings/translation-string-delete-dialog.html',
                controller: 'TranslationStringDeleteDialogController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    data: function() { return rep }
                }
            }).result.then(function (result) {
                loadAll();
            });
        }
    }
})();