(function () {
    'use strict';

   angular
        .module('rbllkApp')
        .factory('S3FileStorage', S3FileStorage);

    S3FileStorage.$inject = ['$resource'];

    function S3FileStorage (resource) {
        var service = resource('api/s3filestorage', {}, {
            'getBuckets': { method: 'GET', url: 'api/s3filestorage/listBuckets', isArray: true },
            'getObjects': { method: 'GET', url: 'api/s3filestorage/listObjects/:bucketName', isArray: true },
            'getObject': { method: 'GET', url: 'api/s3filestorage/:bucketName/:key' },
            'fixLegacyRec': { method: 'GET', url: 'api/tools/rec-fix' },
            'getOwnerGroups': { method: 'GET', url: 'api/owners/withGroups', isArray: true },
            'getOwnerGroupsByLogin': { method: 'GET', url: 'api/owners/withGroupsByLogin', isArray: true }
        });

        return service;
    }
})();