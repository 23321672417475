(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .directive('faCheckbox', faCheckbox);

    function faCheckbox () {
        var directive = {
            replace: true,
            restrict: 'E',
            template: '<a href="" class="checkbox-fa" ng-click="onClickFunc()" ng-disabled="disabled">' +
                '<span class="fa-fw fa-lg" ng-class="{\'far fa-square\': !isChecked, \'fas fa-check-square\': isChecked}"></span>' +
                '<span>{{label}}</span></a>',
            scope: {
                isChecked: "=",
                label: "<?",
                onClickFunc: "&?onClick",
                disabled: "="
            },
            link: linkFunc
        }

        return directive;

        function linkFunc (scope) {
            if (scope.onClickFunc === undefined || scope.onClickFunc === null) {
                scope.onClickFunc = function () {
                    scope.isChecked = !scope.isChecked
                }
            }
        }
    }
})();