(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('TransportDetailedListController', TransportDetailedListController);

    TransportDetailedListController.$inject = ['$localStorage', '$sessionStorage', '$scope', '$state', 'Principal', 'ParseLinks', 'pagingParams', 'paginationConstants', 'AlertService', 'Transport', 'SalesOrderStatuses', 'PurchaseOrderStatuses', 'FileSaver'];

    function TransportDetailedListController ($localStorage, $sessionStorage, $scope, $state, Principal, ParseLinks, pagingParams, paginationConstants, AlertService, Transport, SalesOrderStatuses, PurchaseOrderStatuses, FileSaver) {
        var vm = this;

        $scope.$storage = $localStorage;
        if(!$scope.$storage.trdColumns) { //due to $default issue with restoring storage
            $localStorage.trdColumns = { };
        }
        vm.shownColumns = $scope.$storage.trdColumns;
        if (!$scope.$storage.itemsPerPage.transportDetailed) {
            $localStorage.itemsPerPage.transportDetailed = paginationConstants.itemsPerPage;
        }
        vm.itemsPerPage = $scope.$storage.itemsPerPage.transportDetailed;

        if (!$sessionStorage.filters.transportDetailed) {
            $sessionStorage.filters.transportDetailed = { driverName: null, expectedDate: null, factArrival: null, factDeparture: null, factDock: null, factUndock: null, orderType: null, orderSubType: null, transportCompany: null };
        }
        vm.searchParams = $sessionStorage.filters.transportDetailed;

        vm.data = [];
        vm.client = null;
        vm.soStatus = SalesOrderStatuses;
        vm.poStatus = PurchaseOrderStatuses;
        vm.itemsPerPageOptions = paginationConstants.itemsPerPageOptions;
        vm.orderTypeOptions = ['SO', 'PO'];

        vm.loadAll = loadAll;
        vm.translateStatus = translateStatus;
        vm.exportToExcel = exportToExcel;
        vm.changeItemsPerPage = changeItemsPerPage;
        vm.openCalendar = openCalendar;
        vm.toggleColumn = toggleColumn;

        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        init();

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.loadAll();
            });
        }

        function loadAll () {
            Transport.findDetailed({ 
                client: vm.client,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, vm.searchParams, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.data = data;
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            return result;
        }

        function loadPage () {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filter: vm.searchParams
            });
        }

        function translateStatus (detail) {
            switch (detail.orderType) {
                case 'PO': return vm.poStatus.strings[detail.orderStatus];
                case 'SO': return vm.soStatus.strings[detail.orderStatus];
                default: return '';
            }
        }

        function exportToExcel () {
            vm.savingXls = true;
            Transport.toExcelDetailed({
                client: vm.client,
                sort: sort()
            },
            vm.searchParams,
            function (data) {
                vm.savingXls = false;
                FileSaver.saveAs(data.blob, "transport_detailed.xlsx");
            }, function (error) {
                vm.savingXls = false;
                onError(error);
            });
        }

        function changeItemsPerPage () {
            // 2-way binding doesn't seem to work here for some reason
            $scope.$storage.itemsPerPage.transportDetailed = vm.itemsPerPage;
            loadAll();
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function toggleColumn (columnName) {
            vm.shownColumns[columnName] = !vm.shownColumns[columnName];
            if (!vm.shownColumns[columnName]) {
                vm.searchParams[columnName] = null;
            }
        }
    }
})();