(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('StaticDocumentCategoryListController', StaticDocumentCategoryListController)

        StaticDocumentCategoryListController.$inject = ['list', '$stateParams', '$state'];

        function StaticDocumentCategoryListController (list, $stateParams, $state) {
            var vm = this;

            vm.list = list;
            vm.cancel = cancel;
            vm.client = $stateParams.client;

            function cancel () {
                $state.go('static-documents', null, {reload: true});
            }
        }
})();