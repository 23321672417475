(function () {
    'use-strict';

    angular
        .module('rbllkApp')
        .controller('DeliveryCalculatorController', DeliveryCalculatorController);

    DeliveryCalculatorController.$inject = ['AddressTypeahead', '$stateParams', 'Principal', 'CourierOrder', 'PostOrder', 'AlertService'];

    function DeliveryCalculatorController (AddressTypeahead, $stateParams, Principal, CourierOrder, PostOrder, AlertService) {
        var vm = this;

        vm.type = $stateParams.type;
        vm.init = init;
        vm.openCalendar = openCalendar;
        vm.clear = clear;
        vm.calculate = calculate;
        vm.getStreet = getStreet;
        vm.getLocality = getLocality;
        vm.getRegion = getRegion;

        vm.init();

        function init () {
            Principal.identity().then(function(account) {
                vm.client = account.clientCode;
            });
            switch(vm.type) {
                case 0: vm.title = 'calculator.courier.title'; break;
                case 1: vm.title = 'calculator.post.title'; break;
            }
            vm.clear();
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function clear() {
            vm.query = {
                weight: 0,
                length: 0,
                width: 0,
                height: 0,
                address: {
                    zipcode: null,
                    country: 'Россия',
                    region: null,
                    subRegion: null,
                    locality: null,
                    street: null,
                    house: null,
                    housing: null,
                    building: null,
                    apartment: null
                },
                assessedCost: 0,
                dispatchDate: null
            };
            vm.result = null;
        }

        function calculate() {
            vm.isCalculating = true;
            switch(vm.type) {
                case 0:
                    CourierOrder.cost({ client: vm.client}, vm.query, onSuccess, onError);
                    break;
                case 1:
                    PostOrder.cost({ client: vm.client}, vm.query, onSuccess, onError);
                    break;
            }
        }

        function onSuccess(data) {
            vm.isCalculating = false;
            vm.result = data.cost;
        }

        function onError(error) {
            vm.isCalculating = false;
            if (error.data.errors) {
                AlertService.error('error.ext.service.error', { param: error.data.errors.join(', ')});
            }
        }

        function getStreet(val) {
            return AddressTypeahead.findStreet(val);
        }

        function getRegion(val) {
            return AddressTypeahead.findRegion(val);
        }

        function getLocality(val) {
            return AddressTypeahead.findLocality(val);
        }
    }
})();