(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('CourierRegistryDetailController', CourierRegistryDetailController);

    CourierRegistryDetailController.$inject = ['entity', 'ShipmentTypes', 'DeliveryOrderPaymentMethods', 'PickupOrderPayers', 'StringUtils'];

    function CourierRegistryDetailController (entity, ShipmentTypes, DeliveryOrderPaymentMethods, PickupOrderPayers, StringUtils) {
        var vm = this;

        vm.registry = entity;
        vm.paymentMethods = DeliveryOrderPaymentMethods;
        vm.payers = PickupOrderPayers;
        vm.shipmentTypes = ShipmentTypes;
        vm.getTranslationString = StringUtils.getTranslationString;
        vm.concatAddress = StringUtils.concatAddressShort;
    }
})();