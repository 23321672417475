(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('SyncEvent', SyncEvent);

    SyncEvent.$inject = ['$resource'];

    function SyncEvent ($resource) {
        var service = $resource('api/sync-event/:id', {}, {
            'find': { method: 'POST', url: 'api/find/sync-event/:client', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }
})();