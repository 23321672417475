(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('TransportController', TransportController);

    TransportController.$inject = ['$state', 'Principal'];

    function TransportController ($state, Principal) {
        var vm = this;

        init();

        function init () {
            //$state.go('transport-summary');
            Principal.getCurrentClient().then(function (client) {
                vm.clientName = client.name;
            });
        }
    }
})();