(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('TransportCompaniesController', TransportCompaniesController);

    TransportCompaniesController.$inject = ['Principal', 'TransportCompany', 'AlertService','$state', 'ParseLinks', 'pagingParams', 'paginationConstants', '$scope', '$localStorage'];

    function TransportCompaniesController (Principal, TransportCompany, AlertService, $state, ParseLinks, pagingParams, paginationConstants, $scope, $localStorage) {
        var vm = this;

        $scope.$storage = $localStorage;
        if (!$scope.$storage.itemsPerPage.organizations) {
            $localStorage.itemsPerPage.organizations = paginationConstants.itemsPerPage;
        }
        vm.itemsPerPage = $scope.$storage.itemsPerPage.organizations;

        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        vm.client = null;
        vm.clientName = null;
        vm.companies = [];
        vm.searchParams = { code: null, name: null };
        vm.itemsPerPageOptions = paginationConstants.itemsPerPageOptions;

        vm.init = init;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.syncAll = syncAll;
        vm.changeItemsPerPage = changeItemsPerPage;

        vm. init();

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.clientName = client.name;
                vm.loadAll();
            });
        }

        function loadAll () {
            TransportCompany.query({
                client: vm.client,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.companies = data;
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage () {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function search () {
            TransportCompany.find({
                client: vm.client,
                page: pagingParams.page - 1,
                size: vm.totalItems,
                sort: sort()
            }, vm.searchParams, onSuccess, onError);
        }

        function syncAll () {
            TransportCompany.syncAll({ client: vm.client }, loadAll, onError);
        }

        function changeItemsPerPage () {
            // 2-way binding doesn't seem to work here for some reason
            $scope.$storage.itemsPerPage.organizations = vm.itemsPerPage;
            loadAll();
        }
    }
})();