(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('FinanceServiceConfigurationDeleteDialogController', FinanceServiceConfigurationDeleteDialogController);

    FinanceServiceConfigurationDeleteDialogController.$inject = ['$uibModalInstance', 'entity', 'ExternalServiceConfig'];

    function FinanceServiceConfigurationDeleteDialogController ($uibModalInstance, entity, ExternalServiceConfig) {
        var vm = this;

        vm.config = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ExternalServiceConfig.delete({type: 'finance', id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();