(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('managed-documents', {
            parent: 'documents',
            url: '/managed-documents/:type?page&sort',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'managedDocuments.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/documents/managed-documents/managed-documents.html',
                    controller: 'ManagedDocumentsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'createdDate,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('documents');
                    return $translate.refresh();
                }]
            }
        })
        .state('managed-documents.new', {
            parent: 'managed-documents',
            url: '/:client/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_OPERATOR', 'ROLE_USER'],
                pageTitle: 'managedDocuments.create.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/documents/managed-documents/managed-documents-edit.html',
                    controller: 'ManagedDocumentsAddController',
                    controllerAs: 'vm'
                }
            },
            params: {
                type: null
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('documents');
                    return $translate.refresh();
                }],
                author: ['Principal', function (Principal) {
                    return Principal.getUserNameAndLogin();
                }],
                document: function () {
                    return {
                        id: null, name: null, status: 'NEW', comments: [], files: [], emails: [],
                        createdDate: null, lastModifiedBy: null, lastModifiedDate: null
                    }
                },
                categories: ['$stateParams', 'Documents', function ($stateParams, Documents) {
                    return Documents.getCategories({ client: $stateParams.client });
                }]
            }
        })
        .state('managed-documents.edit', {
            parent: 'managed-documents',
            url: '/:client/edit/:id',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_OPERATOR', 'ROLE_USER'],
                pageTitle: 'managedDocuments.edit.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/documents/managed-documents/managed-documents-edit.html',
                    controller: 'ManagedDocumentEditController',
                    controllerAs: 'vm'
                }
            },
            params: {
                type: null
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('documents');
                    return $translate.refresh();
                }],
                author: ['Principal', function (Principal) {
                    return Principal.getUserNameAndLogin();
                }],
                document: ['ManagedDocuments', '$stateParams', function (ManagedDocuments, $stateParams) {
                    return ManagedDocuments.get({
                        type: $stateParams.type,
                        client: $stateParams.client,
                        id: $stateParams.id
                    }).$promise;
                }],
                categories: ['$stateParams', 'Documents', function ($stateParams, Documents) {
                    return Documents.getCategories({ client: $stateParams.client });
                }],
                editParams: ['Principal', '$stateParams', 'document', function (Principal, $stateParams, document) {
                    var result = { editFlag: false, addFlag: true, label: null };
                    switch (document.status) {
                        case 'NEW':
                            result.addFlag = false;
                        case 'ON_REVISION':
                            if ($stateParams.type === 'rb' && Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_OPERATOR'])) {
                                result.editFlag = true;
                                result.addFlag  = true;
                                result.label = 'managedDocuments.saveToClient';
                            } else if ($stateParams.type === 'cl' && !Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_OPERATOR'])) {
                                result.editFlag = true;
                                result.addFlag  = true;
                                result.label = 'managedDocuments.saveToAdmin';
                            }
                            break;
                        case 'TO_CLIENT':
                            if (($stateParams.type == 'rb' && !Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_OPERATOR'])) ||
                                ($stateParams.type == 'cl' && Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_OPERATOR']))) {
                                    result.editFlag = true;
                                    result.label = 'managedDocuments.saveConfirm';
                            }
                            break;
                    }
                    return result;
                }]
            }
        })
        .state('managed-documents.delete', {
            parent: 'managed-documents',
            url: '/:client/delete/:id',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            params: {
                type: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/documents/managed-documents/managed-documents-delete-dialog.html',
                    controller: 'ManagedDocumentDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        document: ['ManagedDocuments', function(ManagedDocuments) {
                            return ManagedDocuments.get({
                                type: $stateParams.type,
                                client: $stateParams.client,
                                id: $stateParams.id
                            });
                        }],
                        clientCode: function () {
                            return $stateParams.client;
                        }
                    }
                }).result.then(function () {
                    $state.go('managed-documents', null, { reload: true });
                }, function () {
                    $state.go('^');
                });
            }]
        })
    }
})();