(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .directive('hasAnyService', hasAnyService);

    hasAnyService.$inject = ['Principal'];

    function hasAnyService(Principal) {
        var directive = {
            link: linkFunc,
            restrict: 'A'
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var services = attrs.hasAnyService.replace(/\s+/g, '').split(',');

            var setVisible = function() {
                element.removeClass('hidden');
            },
            setHidden = function() {
                element.addClass('hidden');
            },
            defineVisibility = function(reset) {
                if (reset) {
                    setVisible();
                }

                Principal.hasAnyService(services)
                    .then(function(result) {
                    if (result) {
                        setVisible();
                    } else {
                        setHidden();
                    }
                });
            };

            if (services.length > 0) {
                defineVisibility(true);

                scope.$watch(function() {
                    return Principal.isAuthenticated();
                }, function() {
                    defineVisibility(true);
                });
            }
        }
    }
})();