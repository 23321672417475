(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('purchase-orders', {
            parent: 'orders-warehouse',
            url: '/purchase-orders',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'purchaseOrders.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/purchase-orders/purchase-orders.html',
                    controller: 'PurchaseOrdersController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value:'code,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('purchase-orders');
                    return $translate.refresh();
                }],
                types: ['PurchaseOrder', function (PurchaseOrder) {
                    return PurchaseOrder.getTypes().$promise.then(function (types) {
                        var typeTranslations = {}
                        types.forEach(function (element) {
                            typeTranslations[element.key] = element.value;
                        });
                        return typeTranslations;
                    });
                }]
            }
        })
        .state('purchase-order-detail', {
            parent: 'purchase-orders',
            url: '/:client/detail/:code/:type',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'purchaseOrders.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/purchase-orders/purchase-order-detail.html',
                    controller: 'PurchaseOrderDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                order: ['$stateParams', 'PurchaseOrder', 'Base64utf8', function($stateParams, PurchaseOrder, Base64utf8) {
                    return PurchaseOrder.get({
                        client: $stateParams.client,
                        code: Base64utf8.encodeMod($stateParams.code),
                        type: $stateParams.type
                    }).$promise;
                }],
                typeTranslation: ['TranslationString', 'order', function (TranslationString, order) {
                    return TranslationString.translate({
                        translationKey: order.type,
                        type: 'PO_TYPE',
                        langKey: 'ru'
                    });
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('purchase-orders');
                    $translatePartialLoader.addPart('pickup-orders');
                    $translatePartialLoader.addPart('products');
                    return $translate.refresh();
                }]
            }
        })
        .state('purchase-orders.new', {
            parent: 'purchase-orders',
            url: '/:client/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'purchaseOrders.create.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/purchase-orders/purchase-order-edit.html',
                    controller: 'PurchaseOrderEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                defaultName: ['Principal', function (Principal) {
                    return Principal.getUserNameAndLogin();
                }],
                entity: ['PurchaseOrderRecipientTemplate', 'defaultName', function (PurchaseOrderRecipientTemplate, defaultName) {
                    return {
                        id: null, code: null,
                        wmsImportDate: new Date(), author: defaultName.username,
                        tcType: 1, transportCompany: null, plannedPurchaseDate: null,
                        products: [],
                        createdDate: null, lastModifiedBy: null, lastModifiedDate: null,
                    };
                }],
                transportCompanies: ['$stateParams', 'TransportCompany', function ($stateParams, TransportCompany) {
                    return TransportCompany.getList({client: $stateParams.client});
                }],
                commonCompanies: ['TransportCompany', function (TransportCompany) {
                    return TransportCompany.getCommon();
                }],
                warehouses: ['$stateParams', 'Warehouse', function ($stateParams, Warehouse) {
                    return Warehouse.getList({ client: $stateParams.client });
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('purchase-orders');
                    $translatePartialLoader.addPart('pickup-orders');
                    $translatePartialLoader.addPart('transport-companies');
                    $translatePartialLoader.addPart('warehouses');
                    $translatePartialLoader.addPart('products');
                    return $translate.refresh();
                }],
                types: ['PurchaseOrder', function (PurchaseOrder) {
                    return PurchaseOrder.getTypes();
                }]
            }
        })
        .state('purchase-orders.edit', {
            parent: 'purchase-orders',
            url: '/:client/edit/:code/:type',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'purchaseOrders.edit.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/purchase-orders/purchase-order-edit.html',
                    controller: 'PurchaseOrderEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'PurchaseOrder', 'Base64utf8', function($stateParams, PurchaseOrder, Base64utf8) {
                    return PurchaseOrder.get({
                        client: $stateParams.client,
                        code: Base64utf8.encodeMod($stateParams.code),
                        type: $stateParams.type
                    });
                }],
                transportCompanies: ['$stateParams', 'TransportCompany', function ($stateParams, TransportCompany) {
                    return TransportCompany.getList({client: $stateParams.client});
                }],
                commonCompanies: ['TransportCompany', function (TransportCompany) {
                    return TransportCompany.getCommon();
                }],
                warehouses: ['$stateParams', 'Warehouse', function ($stateParams, Warehouse) {
                    return Warehouse.getList({ client: $stateParams.client });
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('purchase-orders');
                    $translatePartialLoader.addPart('pickup-orders');
                    $translatePartialLoader.addPart('transport-companies');
                    $translatePartialLoader.addPart('warehouses');
                    $translatePartialLoader.addPart('products');
                    return $translate.refresh();
                }],
                types: ['PurchaseOrder', function (PurchaseOrder) {
                    return PurchaseOrder.getTypes();
                }]
            }
        })
        .state('purchase-orders.delete', {
            parent: 'purchase-orders',
            url: '/:client/delete/:code/:type',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'purchaseOrders.delete.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/orders-warehouse/purchase-orders/purchase-order-delete-dialog.html',
                    controller: 'PurchaseOrderDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        orderData: function () {
                            return {
                                code: $stateParams.code,
                                clientCode: $stateParams.client,
                                type: $stateParams.type
                            };
                        }
                    }
                }).result.then(function () {
                    $state.go('purchase-orders', null, { reload: true });
                }, function () {
                    $state.go('^');
                });
            }]
        })
        .state('purchase-orders.uploadReport', {
            parent: 'purchase-orders',
            url: '/upload-report',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'dialog.orderUploadResultDialog.title'
            },
            params: {
                report: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/dialog/order-upload-result-dialog/order-upload-result-dialog.html',
                    controller: 'OrderUploadResultController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function () {
                    $state.go('purchase-orders', null, { reload: false });
                }, function () {
                    $state.go('^');
                });
            }]
        });
    }
})();