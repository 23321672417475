(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('PickupOrderDeleteController', PickupOrderDeleteController);

    PickupOrderDeleteController.$inject = ['$uibModalInstance', 'entity', 'PickupOrder'];

    function PickupOrderDeleteController ($uibModalInstance, entity, PickupOrder) {
        var vm = this;

        vm.order = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (orderCode) {
            PickupOrder.delete({
                client: vm.order.clientCode,
                code: orderCode
            }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();