(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('TransportSlotsController', TransportSlotsController);

    TransportSlotsController.$inject = ['Principal', 'TransportSlots', 'AlertService'];

    function TransportSlotsController(Principal, TransportSlots, AlertService) {
        var vm = this;

        vm.filter = { date: new Date() };
        vm.loadAll = loadAll;
        vm.openCalendar = openCalendar;

        vm.page = 1;
        vm.itemsPerPage = 20;
        vm.predicate = 'gate';
        vm.reverse = false;

        init();

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.loadAll();
            });
        }

        function loadAll () {
            TransportSlots.findAll({client: vm.client}, vm.filter, onSuccess, onError);
        }

        function onSuccess (data) {
            vm.data = data;
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else if (error.data.message) {
                AlertService.error(error.data.message, [error.data.description]);
            } else {
                AlertService.error(error.status + ' ' + error.statusText, []);
            }
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }
    }
})();
