(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('StaticDocumentEditController', StaticDocumentEditController);

    StaticDocumentEditController.$inject = ['document', 'categories', '$uibModalInstance', '$stateParams', 'Upload'];

    function StaticDocumentEditController (document, categories, $uibModalInstance, $stateParams, Upload) {
        var vm = this;

        vm.client = $stateParams.client;
        vm.document = document;
        vm.categories = categories;
        vm.save = save;
        vm.clear = clear;

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onError (error) {
            vm.isSaving = false;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            var method = vm.document.id !== null ? 'PUT' : 'POST';
            Upload.upload({
                url: '/api/document/static/' + vm.client,
                method: method,
                file: vm.file,
                data: { document: Upload.jsonBlob(vm.document) }
            }).then(onSaveSuccess, onError);
        }
    }

})();