(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('SalesOrder', SalesOrder);
    
    SalesOrder.$inject = ['$resource'];

    function SalesOrder ($resource) {
        var service = $resource('api/sales/:client/:code/:type', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    if (data.wmsImportDate) {
                        data.wmsImportDate = new Date(data.wmsImportDate);
                    }
                    if (data.plannedSaleDate) {
                        data.plannedSaleDate = new Date(data.plannedSaleDate);
                    }
                    if (data.deliveryDateFrom) {
                        data.deliveryDateFrom = new Date(data.deliveryDateFrom);
                    }
                    if (data.deliveryDateTo) {
                        data.deliveryDateTo = new Date(data.deliveryDateTo);
                    }
                    return data;
                }
            },
            'save': { method: 'POST' },
            'update': { method: 'PUT' },
            'delete': { method: 'DELETE' },
            'send': { method: 'POST', url: 'api/external/wms/so/export/:client', isArray: true },
            'status': { method: 'POST', url: 'api/external/wms/so/status/:client', isArray: true },
            'find': { method: 'POST', url: 'api/find/so/:client', isArray: true },
            'toExcel': { 
                method: 'POST', 
                url: 'api/excel/sales/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    }; 
                }
            },
            'linesToExcel': {
                method: 'GET',
                url: 'api/excel/sales-lines/:clientCode/:code/:type',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    }; 
                }
            },
            "statusBatch": { method: 'POST', url: 'api/external/wms/so/status_batch/:client' },
            "getTypes": { method: 'GET', url: 'api/list/so-types', isArray: true },
            "findTransportless": { method: 'POST', url: 'api/find/so-transport/:client', isArray: true },
            'getTemplate': { 
                method: 'GET', url: 'api/templates/so-import/:client', responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    }; 
                }
            },
            "updateReference": {
                method: 'POST', url: 'api/external/wms/so/referenceUpd/:client'
            }
        });

        return service;
    }
})();