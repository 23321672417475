(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .constant('PickupOrderStatuses', {
            "0": "localNew",
            "1": "localSent",
            "11": "localSentCourier",
            "12": "localSentPost"

        })
        .constant('PickupOrderContentTypes' , [
            { value: 1, name: "pickupOrders.contentType.glass" },
            { value: 2, name: "pickupOrders.contentType.fragile" },
            { value: 3, name: "pickupOrders.contentType.liquid" },
            { value: 4, name: "pickupOrders.contentType.danger" },
            { value: 5, name: "pickupOrders.contentType.aerosol" },
            { value: 6, name: "pickupOrders.contentType.other" }
        ])
        .constant('PickupOrderPackageTypes', [
            { value: 1, name: "pickupOrders.packageType.none" },
            { value: 2, name: "pickupOrders.packageType.bag" },
            { value: 3, name: "pickupOrders.packageType.cardboard" },
            { value: 4, name: "pickupOrders.packageType.crate" },
            { value: 5, name: "pickupOrders.packageType.canister" },
            { value: 6, name: "pickupOrders.packageType.cage" },
            { value: 7, name: "pickupOrders.packageType.pallet" },
            { value: 8, name: "pickupOrders.packageType.other" }
        ])
        .constant('PickupOrderPayers', [
            { value: 1, name: "pickupOrders.payer.contragent" },
            { value: 2, name: "pickupOrders.payer.sender" },
            { value: 3, name: "pickupOrders.payer.receiver" }
        ])
        .constant('OrderTypes', ["COURIER", "RUSSIANPOST"]);
})();