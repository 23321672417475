(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .constant('DeliveryOrderStatuses', {
            "-2": "localSent",
            "-1": "localNew",
            "0": "needscheck",
            "1": "created",
            "2": "creationError",
            "10": "accepted",
            "11": "waiting",
            "20": "sorting",
            "30": "transporting",
            "40": "deliveredToBranch",
            "45": "deliveredToRecipientPO",
            "46": "rescheduled",
            "48": "deliveryInProgress",
            "50": "deliveredToRecipient",
            "58": "deliveredToRecipientPartially",
            "60": "preparingToReturn",
            "70": "returnedToWarehouse",
            "80": "returnedToSender",
            "403": "lost",
            "404": "notFound",
            "410": "cancelled"
        })
        .constant('DeliveryOrderPaymentMethods', [{ value: 0, name: "global.paymentMethod.cash"},
        { value: 1, name: "global.paymentMethod.card"},
        { value: 2, name: "global.paymentMethod.prepaid"}]);
})();