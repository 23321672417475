(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ClientInfoCardController', ClientInfoCardController);

    ClientInfoCardController.$inject = ['Principal', 'ClientInfo', 'AlertService', 'FileSaver'];

    function ClientInfoCardController (Principal, ClientInfo, AlertService, FileSaver) {
        var vm = this;

        vm.init = init;
        vm.loadAll = loadAll;
        vm.data = null;
        vm.exportToFile = exportToFile;

        vm.init();

        function init() {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.clientName = client.name;
                vm.loadAll();
            });
        }

        function loadAll () {
            ClientInfo.get({
                client: vm.client
            }, onSuccess, onError);
        }

        function onSuccess (data) {
            vm.loaded = true;
            vm.data = data;
        }

        function onError (error) {
            vm.loaded = true;
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function exportToFile () {
            vm.savingXls = true;
            ClientInfo.toExcel({
                client: vm.client
            },
            function (data) {
                vm.savingXls = false;
                var filename = vm.clientName + ".xlsx";
                FileSaver.saveAs(data.blob, filename);
            }, function (error) {
                vm.savingXls = false;
                onError(error);
            });
        }
    }
})();