(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('TransportSummaryController', TransportSummaryController);

    TransportSummaryController.$inject = ['$localStorage', '$sessionStorage', '$scope', '$state', 'AlertService', 'FileSaver', 'paginationConstants', 'pagingParams', 'ParseLinks', 'Principal', 'Transport', 'TransportConstants', 'Upload'];

    function TransportSummaryController ($localStorage, $sessionStorage, $scope, $state, AlertService, FileSaver, paginationConstants, pagingParams, ParseLinks, Principal, Transport, TransportConstants, Upload) {
        var vm = this;

        $scope.$storage = $localStorage;
        if(!$scope.$storage.trsColumns) { //due to $default issue with restoring storage
            $localStorage.trsColumns = { };
        }
        vm.shownColumns = $scope.$storage.trsColumns;
        if (!$scope.$storage.itemsPerPage.transportSummary) {
            $localStorage.itemsPerPage.transportSummary = paginationConstants.itemsPerPage;
        }
        vm.itemsPerPage = $scope.$storage.itemsPerPage.transportSummary;

        if (!$sessionStorage.filters.transportSummary) {
            $sessionStorage.filters.transportSummary = { code: null, licensePlate: null, driverName: null, expectedDateAfter: null, expectedDateBefore: null, transportType: null, statuses: [] };
        }
        vm.searchParams = $sessionStorage.filters.transportSummary;

        vm.data = [];
        vm.client = null;
        vm.itemsPerPageOptions = paginationConstants.itemsPerPageOptions;
        vm.typeOptions = TransportConstants.TypeOptions;
        vm.statusOptions = TransportConstants.StatusOptions;
        vm.multiOwnerUpload = false;

        vm.loadAll = loadAll;
        vm.uploadFile = uploadFile;
        vm.openCalendar = openCalendar;
        vm.exportToExcel = exportToExcel;
        vm.syncStatus = syncStatus;
        vm.syncBatch = syncBatch;
        vm.sendOrder = sendOrder;
        vm.isNew = isNew;
        vm.changeItemsPerPage = changeItemsPerPage;
        vm.toggleStatusFilter = toggleStatusFilter;
        vm.statusFilterToggled = statusFilterToggled;
        vm.getStatusFilterString = getStatusFilterString;
        vm.downloadTemplate = downloadTemplate;
        vm.toggleColumn = toggleColumn;
        vm.isEditable = isEditable;

        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        init();

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.loadAll();
            });
        }

        function loadAll () {
            Transport.find({
                client: vm.client,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, vm.searchParams, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.data = data;
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else if (error.data.message) {
                AlertService.error(error.data.message, [error.data.description]);
            } else {
                AlertService.error(error.status + ' ' + error.statusText, []);
            }
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage () {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filter: vm.searchParams
            });
        }

        function uploadFile () {
            vm.uploading = true;
            Upload.upload({
                url: '/api/import/xls/transport',
                data: { clientCode: vm.client, multiOwner: vm.multiOwnerUpload },
                file: vm.file
            }).then(function (response) {
                vm.uploading = false;
                $state.go('transport-summary.uploadReport', { report: response.data });
            }, function (error) {
                vm.uploading = false;
                onError(error);
            });
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function exportToExcel () {
            vm.savingXls = true;
            Transport.toExcelSummary({
                client: vm.client,
                sort: sort()
            },
            vm.searchParams,
            function (data) {
                vm.savingXls = false;
                FileSaver.saveAs(data.blob, "transport_summary.xlsx");
            }, function (error) {
                vm.savingXls = false;
                onError(error);
            });
        }

        function syncStatus (code) {
            Transport.status({client: vm.client}, code, vm.loadAll, onError);
        }

        function syncBatch () {
            Transport.statusBatch({client: vm.client}, null, vm.loadAll, onError);
        }

        function sendOrder (transport) {
            Transport.send({client: vm.client}, transport.code, vm.loadAll, onError);
        }

        function isNew (transport) {
            return transport.status === 'NEW';
        }

        function changeItemsPerPage () {
            // 2-way binding doesn't seem to work here for some reason
            $scope.$storage.itemsPerPage.transportSummary = vm.itemsPerPage;
            loadAll();
        }

        function toggleStatusFilter (status) {
            var index = vm.searchParams.statuses.indexOf(status);
            if (index == -1) {
                vm.searchParams.statuses.push(status);
            } else {
                vm.searchParams.statuses.splice(index, 1);
            }
        }

        function statusFilterToggled (status) {
            return  vm.searchParams.statuses.indexOf(status) != -1
        }

        function getStatusFilterString() {
            var count = vm.searchParams.statuses.length;
            if (count < 1) {
                return "transport.filter.noStatuses";
            } else if (count == 1) {
                return "transport.statuses." + vm.searchParams.statuses[0];
            } else {
                return "transport.filter.multipleStatuses";
            }
        }

        function downloadTemplate () {
            vm.savingTemplate = true;
            Transport.getTemplate({
                client: vm.client
            }, function (data) {
                vm.savingTemplate = false;
                var filename = "transport_import_template.xls";
                FileSaver.saveAs(data.blob, filename);
            }, function (error) {
                vm.savingTemplate = false;
                onError(error);
            })
        }

        function toggleColumn (columnName) {
            vm.shownColumns[columnName] = !vm.shownColumns[columnName];
            if (!vm.shownColumns[columnName]) {
                vm.searchParams[columnName] = null;
            }
        }

        function isEditable (transport) {
            return transport.status === 'NEW' || transport.status === 'IMPORTED' || transport.status === 'PLANED';
        }
    }
})();