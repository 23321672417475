(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('CourierOrderRegistry', CourierOrderRegistry);

    CourierOrderRegistry.$inject = ['$resource'];

    function CourierOrderRegistry ($resource) {
        var service = $resource('api/registry/courier/:client/:code', {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    if (data.registryCreationDate) {
                        data.registryCreationDate = new Date(data.registryCreationDate);
                    }
                    if (data.shipmentDate) {
                        data.shipmentDate = new Date(data.shipmentDate);
                    }
                    if (data.pickupOrder && data.pickupOrder.pickupDateFrom) {
                        data.pickupOrder.pickupDateFrom = new Date(data.pickupOrder.pickupDateFrom);
                    }
                    if (data.pickupOrder && data.pickupOrder.pickupDateTo) {
                        data.pickupOrder.pickupDateTo = new Date(data.pickupOrder.pickupDateTo);
                    }
                    return data;
                }
            },
            'save': { method: 'POST' },
            'update': { method: 'PUT' },
            'delete': { method: 'DELETE' },
            'send': { method: 'POST', url: 'api/external/courier/registry/:client', isArray: true }
        });

        return service;
    }
})();