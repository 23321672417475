(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('TranslationString', TranslationString);

    TranslationString.$inject = ['$resource'];

    function TranslationString ($resource) {
        var service = $resource('api/translation-string/:id', {}, {
            'find': { method: 'POST', url: 'api/find/translation-string', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'translate': { method: 'GET', url: 'api/translate' },
            'update': { method:'PUT' },
            'delete': { method:'DELETE' }
        });

        return service;
    }
})();