(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('PickupOrderSendDialogController', PickupOrderSendDialogController);

    PickupOrderSendDialogController.$inject = ['$uibModalInstance', 'data', 'PickupOrder', 'AlertService'];

    function PickupOrderSendDialogController ($uibModalInstance, data, PickupOrder, AlertService) {
        var vm = this;

        vm.data = data;
        vm.clear = clear;
        vm.confirmSend = confirmSend;
        vm.sending = false;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmSend () {
            vm.sending = true;
            PickupOrder.send({
                client: data.client
            }, data.code, function () {
                vm.sending = false;
                $uibModalInstance.close(true);
            }, function (error) {
                vm.sending = false;
                var message = error.data.join("\n");
                AlertService.error('error.param.error', { param: message });
            });
        }
    }
})();