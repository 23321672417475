(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('transport', {
            parent: 'orders-warehouse',
            url: '/transport',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'transport.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/transport/transport.html',
                    controller: 'TransportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('transport');
                    return $translate.refresh();
                }]
            }
        })
        .state('transport-summary', {
            parent: 'transport',
            url: '/summary',
            views: {
                'transport-content': {
                    templateUrl: 'app/orders-warehouse/transport/transport-summary.html',
                    controller: 'TransportSummaryController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value:'expectedDateTime,desc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]
            }
        })
        .state('transport-detail', {
            parent: 'transport-summary',
            url: '/:client/detail/:code',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'transport.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/transport/transport-detail.html',
                    controller: 'TransportDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                transport: ['$stateParams', 'Transport', 'Base64utf8', function ($stateParams, Transport, Base64utf8) {
                    return Transport.get({
                        client: $stateParams.client,
                        code: Base64utf8.encodeMod($stateParams.code)
                    }).$promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('purchase-orders');
                    $translatePartialLoader.addPart('sales-orders');
                    return $translate.refresh();
                }],
                soTypes: ['SalesOrder', function (SalesOrder) {
                    return SalesOrder.getTypes().$promise.then(function (types) {
                        var typeTranslations = {}
                        types.forEach(function (element) {
                            typeTranslations[element.key] = element.value;
                        });
                        return typeTranslations;
                    });
                }],
                poTypes: ['PurchaseOrder', function (PurchaseOrder) {
                    return PurchaseOrder.getTypes().$promise.then(function (types) {
                        var typeTranslations = {}
                        types.forEach(function (element) {
                            typeTranslations[element.key] = element.value;
                        });
                        return typeTranslations;
                    });
                }]
            }
        })
        .state('transport-summary.new', {
            parent: 'transport-summary',
            url: '/:client/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'transport.create.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/transport/transport-edit.html',
                    controller: 'TransportEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        id: null, code: null, driverName: null, licensePlate: null, vehicleModel: null, status: null,
                        priority: 0, commentary: null, companyName: null,
                        expectedDateTime: null, factArrival: null, factDock: null, factUndock: null, factDeparture: null,
                        createdDate: null, lastModifiedBy: null, lastModifiedDate: null, outboundOrders: [], inboundOrders: [], commentaries: []
                    };
                },
                soTypes: ['SalesOrder', function (SalesOrder) {
                    return SalesOrder.getTypes().$promise.then(function (types) {
                        var typeTranslations = {}
                        types.forEach(function (element) {
                            typeTranslations[element.key] = element.value;
                        });
                        return typeTranslations;
                    });
                }],
                poTypes: ['PurchaseOrder', function (PurchaseOrder) {
                    return PurchaseOrder.getTypes().$promise.then(function (types) {
                        var typeTranslations = {}
                        types.forEach(function (element) {
                            typeTranslations[element.key] = element.value;
                        });
                        return typeTranslations;
                    });
                }],
                mixingAllowed: ['Principal', function (Principal) {
                    return Principal.hasAuthority('ROLE_MIX_TRANSPORT');
                }]
            }
        })
        .state('transport-summary.edit', {
            parent: 'transport-summary',
            url: '/:client/edit/:code',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'transport.edit.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/transport/transport-edit.html',
                    controller: 'TransportEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Transport', 'Base64utf8', function ($stateParams, Transport, Base64utf8) {
                    return Transport.get({
                        client: $stateParams.client,
                        code: Base64utf8.encodeMod($stateParams.code)
                    });
                }],
                soTypes: ['SalesOrder', function (SalesOrder) {
                    return SalesOrder.getTypes().$promise.then(function (types) {
                        var typeTranslations = {}
                        types.forEach(function (element) {
                            typeTranslations[element.key] = element.value;
                        });
                        return typeTranslations;
                    });
                }],
                poTypes: ['PurchaseOrder', function (PurchaseOrder) {
                    return PurchaseOrder.getTypes().$promise.then(function (types) {
                        var typeTranslations = {}
                        types.forEach(function (element) {
                            typeTranslations[element.key] = element.value;
                        });
                        return typeTranslations;
                    });
                }],
                mixingAllowed: ['Principal', function (Principal) {
                    return Principal.hasAuthority('ROLE_MIX_TRANSPORT');
                }]
            }
        })
        .state('transport-summary.delete', {
            parent: 'transport-summary',
            url: '/:client/delete/:code',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'transport.delete.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/orders-warehouse/transport/transport-delete-dialog.html',
                    controller: 'TransportDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        data: function () {
                            return {
                                client: $stateParams.client,
                                code: $stateParams.code
                            };
                        }
                    }
                }).result.then(function () {
                    $state.go('transport-summary', null, { reload: true });
                }, function () {
                    $state.go('^');
                });
            }]
        })
        .state('transport-summary.uploadReport', {
            parent: 'transport-summary',
            url: '/upload-report',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_OPERATOR', 'ROLE_EDITOR'],
                pageTitle: 'transport.report.title'
            },
            params: {
                report: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/orders-warehouse/transport/transport-upload-result.html',
                    controller: 'TransportUploadResultController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function () {
                    $state.go('transport-summary', null, { reload: true });
                }, function () {
                    $state.go('^');
                });
            }]
        })
        .state('transport-summary.log', {
            parent: 'transport-summary',
            url: '/:client/log/:code',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'transport.dbLog.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/orders-warehouse/transport/transport-db-log.html',
                    controller: 'TransportDbLogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialProvider: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('exchange-logs');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function () {
                    $state.go('transport-summary');
                }, function () {
                    $state.go('^');
                });
            }]
        })
        .state('transport-detailed', {
            parent: 'transport',
            url: '/detailed',
            views: {
                'transport-content': {
                    templateUrl: 'app/orders-warehouse/transport/transport-detailed-list.html',
                    controller: 'TransportDetailedListController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value:'transportCode,orderCode,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('sales-orders');
                    $translatePartialLoader.addPart('purchase-orders');
                    return $translate.refresh();
                }]
            }
        });
    }
})();