(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .constant('ClientInfoConstants', {
            "integrationMethods": [ 'FTP', 'EMAIL'],
            "integrationTools": [ 'XML', 'CSV', 'XLS'],
            "storageTypes": [ 'PALLET', 'UNIT', 'BOX', 'SQM']
        });
})();