(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('StaticDocumentCategoryDeleteController', StaticDocumentCategoryDeleteController);

    StaticDocumentCategoryDeleteController.$inject = ['$uibModalInstance', 'category', 'client', 'Documents'];

    function StaticDocumentCategoryDeleteController ($uibModalInstance, category, client, Documents) {
        var vm = this;

        vm.category = category;
        vm.client = client;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            Documents.deleteCategory({
                client: vm.client,
                id: vm.category.id
            }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();