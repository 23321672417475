(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('DatabaseLog', DatabaseLog);

    DatabaseLog.$inject = ['$resource'];

    function DatabaseLog ($resource) {
        var service = $resource('api/database-log/:id', {}, {
            'find': { method: 'POST', url: 'api/find/database-log/:client', isArray: true },
            'get': { method: 'GET' }
        });

        return service;
    }
})();