(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('PostRegistryDetailController', PostRegistryDetailController);

    PostRegistryDetailController.$inject = ['entity', 'ShipmentTypes', 'StringUtils'];

    function PostRegistryDetailController (entity, ShipmentTypes, StringUtils) {
        var vm = this;

        vm.registry = entity;
        vm.shipmentTypes = ShipmentTypes;
        vm.getTranslationString = StringUtils.getTranslationString;
        vm.concatAddress = StringUtils.concatAddressShort;
    }
})();