(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('SalesOrderDeleteController', SalesOrderDeleteController);

    SalesOrderDeleteController.$inject = ['$uibModalInstance', 'orderData', 'SalesOrder', 'Base64utf8'];

    function SalesOrderDeleteController ($uibModalInstance, orderData, SalesOrder, Base64utf8) {
        var vm = this;

        vm.order = orderData;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (orderCode) {
            SalesOrder.delete({
                client: vm.order.client,
                code: Base64utf8.encodeMod(orderCode),
                type: vm.order.type
            }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();