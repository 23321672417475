(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ProductEditController', ProductEditController);

    ProductEditController.$inject = ['$state', '$stateParams', 'Product', 'entity', 'ProductConstants'];

    function ProductEditController ($state, $stateParams, Product, entity, ProductConstants) {
        var vm = this;

        // const
        vm.client = $stateParams.client;
        vm.batchControlOptions = ProductConstants.BatchControlOptions;
        vm.serialCodeControlOptions = ProductConstants.SerialCodeControlOptions;
        vm.expirationTimeViewTypes = ProductConstants.ExpirationTimeViewTypes;
        // var
        vm.product = entity;
        vm.expDate = 0;
        // functions
        vm.addDimensionUnit = addDimensionUnit;
        vm.addBarcode = addBarcode;
        vm.removeElement = removeElement;
        vm.save = save;
        vm.cancel = cancel;
        vm.hasDefaultUom = hasDefaultUom;
        vm.hasPickingUom = hasPickingUom;
        vm.setDefaultUom = setDefaultUom;
        vm.setPickingUom = setPickingUom;

        function addDimensionUnit () {
            var unit = {name: null, height: 0, width: 0, length: 0, weight: 0, multiplier: 1};
            if (vm.product.dimensionUnits.length < 1) {
                unit.isDefault = true;
                unit.isPicking = true;
            }
            vm.product.dimensionUnits.push(unit);
        }

        function addBarcode () {
            vm.product.barcodes.push({ type: null, value: null });
        }

        function removeElement (array, index) {
            array.splice(index, 1);
        }

        function save () {
            vm.isSaving = true;
            if (vm.product.id !== null) {
                Product.update({client: vm.client}, vm.product, onSaveSuccess, onSaveError);
            } else {
                Product.save({client: vm.client}, vm.product, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $state.go('products', null, {reload: true});
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function cancel () {
            $state.go('products', null, {reload: true});
        }

        function hasDefaultUom () {
            return vm.product.dimensionUnits && vm.product.dimensionUnits.some(function (elem) { return elem.isDefault });
        }

        function hasPickingUom () {
            return vm.product.dimensionUnits && vm.product.dimensionUnits.some(function (elem) { return elem.isPicking });
        }

        function setDefaultUom (index) {
            if (vm.product.dimensionUnits[index].isDefault) { // значение после клика true
                // устанавливаем все остальные чекбоксы в false
                vm.product.dimensionUnits.forEach(function (e, i) {
                    e.isDefault = (i === index);
                });
            }
        }

        function setPickingUom (index) {
            if (vm.product.dimensionUnits[index].isPicking) { // значение после клика true
                // устанавливаем все остальные чекбоксы в false
                vm.product.dimensionUnits.forEach(function (e, i) {
                    e.isPicking = (i === index);
                });
            }
        }
    }
})();