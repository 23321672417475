(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('OrgGroupsController', OrgGroupsController);

    OrgGroupsController.$inject = ['$scope', '$localStorage', 'paginationConstants', 'Principal', 'OrganizationGroup', '$uibModal'];

    function OrgGroupsController($scope, $localStorage, paginationConstants, Principal, OrganizationGroup, $uibModal) {
        var vm = this;

        $scope.$storage = $localStorage;
        if (!$scope.$storage.itemsPerPage.organizationGroups) {
            $localStorage.itemsPerPage.organizationGroups = paginationConstants.itemsPerPage;
        }
        vm.itemsPerPage = $scope.$storage.itemsPerPage.organizationGroups;

        vm.page = 1;
        vm.itemsPerPageOptions = paginationConstants.itemsPerPageOptions;
        vm.reverse = true;

        vm.groups = [];

        vm.loadAll = loadAll;
        vm.editTempMode = editTempMode;
        vm.changeItemsPerPage = changeItemsPerPage;

        init();

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.clientName = client.name;
                loadAll();
            });
        }

        function loadAll () {
            OrganizationGroup.getAll({
                client: vm.client,
            }, onSuccess);
        }

        function onSuccess (data) {
            vm.groups = data;
        }

        function editTempMode (group) {
            var editTempModeModal = $uibModal.open({
                templateUrl: 'app/catalogs/organization-groups/org-group-edit.html',
                controller: 'OrgGroupEditController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    group: function () { return group; },
                    client: function () { return vm.client; }
                }
            }).closed.then(function () {
                loadAll();
            });
        }

        function changeItemsPerPage () {
            // 2-way binding doesn't seem to work here for some reason
            $scope.$storage.itemsPerPage.organizationGroups = vm.itemsPerPage;
        }
    }
})();