(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('SqlConfigurationController', SqlConfigurationController);

    SqlConfigurationController.$inject = ['WmsSqlConfig', 'AlertService'];

    function SqlConfigurationController (WmsSqlConfig, AlertService) {
        var vm = this;

        vm.configs = []
        vm.loadAll = loadAll;

        vm.loadAll();

        function loadAll () {
            WmsSqlConfig.query(onSuccess, onError);
        }

        function onSuccess(data, headers) {
            vm.configs = data;
        }

        function onError(error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }
    }
})();
