(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('TransportEditCommentDialogController', TransportEditCommentDialogController);

    TransportEditCommentDialogController.$inject = ['commentary', 'codes', '$uibModalInstance'];

    function TransportEditCommentDialogController (commentary, codes, $uibModalInstance) {
        var vm = this;

        vm.commentary = commentary;
        vm.orderCodes = codes;

        vm.clear = clear;
        vm.confirm = confirm;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm () {
            $uibModalInstance.close(vm.commentary);
        }
    }
})();