(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('CourierRegistryController', CourierRegistryController);

    CourierRegistryController.$inject = ['Principal', 'CourierOrderRegistry', 'AlertService', '$state', 'ParseLinks', 'pagingParams', 'paginationConstants'];

    function CourierRegistryController (Principal, CourierOrderRegistry, AlertService, $state, ParseLinks, pagingParams, paginationConstants) {
        var vm = this;

        vm.init = init;
        vm.loadAll = loadAll;
        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.client = null;
        vm.clientName = null;
        vm.registries = [];
        vm.isNew = isNew;
        vm.sendRegistry = sendRegistry;

        vm.init();

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.clientName = client.name;
                vm.loadAll();
            });
        }

        function loadAll () {
            CourierOrderRegistry.query({
                client: vm.client,
                page: pagingParams.page - 1,
                size: vm.totalItems,
                sort: sort()
            }, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.registries = data;
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage () {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function isNew (registry) {
            return registry.status === 0;
        }

        function sendRegistry (selected) {
            CourierOrderRegistry.send({ client: vm.client }, selected.code,
            function () {
                vm.loadAll();
            },
            function (error) {
                var message = error.data.join("\n");
                AlertService.error('error.param.error', { param: message });
            });
        }
    }
})();