(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('CourierRegistryDeleteController', CourierRegistryDeleteController);

    CourierRegistryDeleteController.$inject = ['$uibModalInstance', 'entity', 'CourierOrderRegistry'];

    function CourierRegistryDeleteController ($uibModalInstance, entity, CourierOrderRegistry) {
        var vm = this;

        vm.registry = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            CourierOrderRegistry.delete({
                client: vm.registry.clientCode,
                code: vm.registry.code
            }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();