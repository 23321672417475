(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('PurchaseOrdersController', PurchaseOrdersController);

    PurchaseOrdersController.$inject = ['Principal', 'PurchaseOrder', 'PurchaseOrderStatuses', 'PurchaseOrderTypes', 'AlertService', '$state', '$stateParams', 'ParseLinks', 'pagingParams', 'paginationConstants', 'FileSaver', '$localStorage', '$scope', 'types', 'Upload', 'Base64utf8', '$sessionStorage'];

    function PurchaseOrdersController(Principal, PurchaseOrder, PurchaseOrderStatuses, PurchaseOrderTypes, AlertService, $state, $stateParams, ParseLinks, pagingParams, paginationConstants, FileSaver, $localStorage, $scope, types, Upload, Base64utf8, $sessionStorage) {
        var vm = this;

        $scope.$storage = $localStorage;
        if(!$scope.$storage.poColumns) { //due to $default issue with restoring storage
            $localStorage.poColumns = { plannedDate: true, factDate: true, itemCount: true, sumCorrectedQty: true, sumFactQty: true, discrepancy: true};
        }
        vm.shownColumns = $scope.$storage.poColumns;
        if (!$scope.$storage.itemsPerPage.purchaseOrders) {
            $localStorage.itemsPerPage.purchaseOrders = paginationConstants.itemsPerPage;
        }
        vm.itemsPerPage = $scope.$storage.itemsPerPage.purchaseOrders;

        if (!$sessionStorage.filters.po) {
            $sessionStorage.filters.po = {
                code: null,
                plannedDate: null,
                status: 100
            };
        }
        vm.searchParams = $sessionStorage.filters.po;
        vm.searchVisible = true;

        vm.init = init;
        vm.loadAll = loadAll;
        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        vm.client = null;
        vm.clientName = null;
        vm.orders = [];
        vm.statusValues = PurchaseOrderStatuses;
        vm.typeValues = PurchaseOrderTypes;//!
        vm.typeTranslations = types;
        vm.itemsPerPageOptions = paginationConstants.itemsPerPageOptions;
        vm.files = [];

        vm.isNew = isNew;
        vm.sendOrder = sendOrder;
        vm.syncStatus = syncStatus;
        vm.syncStatusBatch = syncStatusBatch;
        vm.syncClosed = syncClosed;
        vm.openCalendar = openCalendar;
        vm.exportToExcel = exportToExcel;
        vm.getTypeTranslation = getTypeTranslation;
        vm.changeItemsPerPage = changeItemsPerPage;
        vm.uploadFile = uploadFile;
        vm.downloadTemplate = downloadTemplate;
        vm.changeSelectedFiles = changeSelectedFiles;
        vm.setMovementBlock = setMovementBlock;

        vm.init();

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.clientName = client.name;
                vm.loadAll();
            });
        }

        function loadAll () {
            PurchaseOrder.find({
                client: vm.client,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, getSearchFilter(), onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.orders = data;
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage () {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filter: getSearchFilter()
            });
        }

        function isNew (order) {
            return order.status === 0;
        }

        function sendOrder (order) {
            PurchaseOrder.send({client: vm.client}, { code: order.code, type: order.type }, onWMSCallSuccess, onError);
        }

        function syncStatus (order) {
            PurchaseOrder.status({client: vm.client}, { code: order.code, type: order.type }, onWMSCallSuccess, onError);
        }

        function syncStatusBatch () {
            PurchaseOrder.statusBatch({client: vm.client}, null, onWMSCallSuccess, onError);
        }

        function syncClosed () {
            PurchaseOrder.syncClosed({client: vm.client}, null, onWMSCallSuccess, onError);
        }

        function onWMSCallSuccess (data) {
            if (data.length > 0) {
                AlertService.warning(error.data.message, []);
            }
            vm.loadAll();
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function exportToExcel () {
            vm.savingXls = true;
            PurchaseOrder.toExcel({
                client: vm.client,
                sort: sort()
            },
            getSearchFilter(),
            function (data) {
                vm.savingXls = false;
                FileSaver.saveAs(data.blob, "purchase_orders.xlsx");
            }, function (error) {
                vm.savingXls = false;
                onError(error);
            });
        }

        function transformStatusFilter (status) {
            switch (status) {
                case 100: return [0, 1, 10, 2];
                case 101: return [3, 30];
                default: return [status];
            }
        }

        function getSearchFilter () {
            vm.searchParams.statuses = vm.searchParams.status != null ? transformStatusFilter(vm.searchParams.status) : null;
            return vm.searchParams;
        }

        function getTypeTranslation (type) {
            return vm.typeTranslations[type] ? vm.typeTranslations[type] : type;
        }

        function changeItemsPerPage () {
            // 2-way binding doesn't seem to work here for some reason
            $scope.$storage.itemsPerPage.purchaseOrders = vm.itemsPerPage;
            loadAll();
        }
        function uploadFile () {
            vm.uploading = true;
            Upload.upload({
                url: '/api/import/xls/po',
                data: {
                    clientCode: vm.client,
                    files: vm.files
                },
                arrayKey: ''
            }).then(function (response) {
                vm.uploading = false;
                $state.go('purchase-orders.uploadReport', { report: response.data });
            }, function (error) {
                vm.uploading = false;
                onError(error);
            });
        }

        function downloadTemplate () {
            vm.savingTemplate = true;
            PurchaseOrder.getTemplate({
                client: vm.client
            }, function (data) {
                vm.savingTemplate = false;
                var filename = "po_import_template.xlsx";
                FileSaver.saveAs(data.blob, filename);
            }, function (error) {
                vm.savingTemplate = false;
                onError(error);
            })
        }
        
        function changeSelectedFiles () {
            vm.selectedFiles = vm.files.map(function(elem) { return elem.name }).join(", ");
        }

        function setMovementBlock (order, isEnabled) {
            PurchaseOrder.setMovementBlockValue({
                client: vm.client,
                code: Base64utf8.encodeMod(order.code),
                type: order.type,
            }, isEnabled, function () {
                loadAll();
            }, onError)
        }
    }
})();