(function () {
    'use strict';

    angular
    .module('rbllkApp')
    .controller('TranslationStringEditDialogController', TranslationStringEditDialogController);

    TranslationStringEditDialogController.$inject = ['$uibModalInstance', 'TranslationString', 'data']

    function TranslationStringEditDialogController ($uibModalInstance, TranslationString, data) {
        var vm  = this;

        vm.translationString = data;
        vm.save = save;
        vm.clear = clear;

        function save () {
            vm.isSaving = true;
            TranslationString.update(vm.translationString, onSaveSuccess, onSaveError);
        }
        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();