(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('OrderUploadResultController', OrderUploadResultController);

    OrderUploadResultController.$inject = ['$stateParams', '$uibModalInstance'];

    function OrderUploadResultController ($stateParams, $uibModalInstance) {
        var vm = this;

        vm.report = $stateParams.report;
        vm.clear = clear;
        vm.done = done;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function done () {
            $uibModalInstance.close(true);
        }
    }
})();