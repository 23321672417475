(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ChangePasswordDialogController', ChangePasswordDialogController);

    ChangePasswordDialogController.$inject = ['$uibModalInstance', '$stateParams', 'User'];

    function ChangePasswordDialogController ($uibModalInstance, $stateParams, User) {
        var vm = this;

        vm.login = $stateParams.login;
        vm.clear = clear;
        vm.confirmChanges = confirmChanges;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmChanges () {
            if (vm.password !== vm.confirmPassword) {
                vm.doNotMatch = true;
            } else {
                vm.doNotMatch = false;
                User.changePassword({
                    login: vm.login
                }, vm.password, function () {
                    $uibModalInstance.close(true);
                });
            }
        }
    }
})();