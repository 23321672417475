(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('TransportSlots', TransportSlots);

    TransportSlots.$inject = ['$resource', 'DateUtils'];

    function TransportSlots ($resource, DateUtils) {
        var service = $resource('api/transport-slots/data/:client', {}, {
            'getAll': { method: 'GET', isArray: true },
            'findAll': { method: 'POST', url: 'api/transport-slots/find/:client', isArray: true },
            'save': { method: 'POST' },
            'calculate': { method: 'POST', url: 'api/transport-slots/calc/:client', isArray: true }
        });

        return service;
    }
})();