(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .constant('ProductConstants', {
            BatchControlOptions: ["NONE", "PO", "LOT", "EXP_DATE", "MFG_DATE"],
            SerialCodeControlOptions: ["NONE", "INV_DETAIL", "OUTBOUND_DETAIL"],
            ExpirationTimeViewTypes: ["SECONDS", "MONTHS", "DAYS"]
        });

})();