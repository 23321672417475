(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ProductsController', ProductsController);

    ProductsController.$inject = ['Principal', 'Product', 'ParseLinks', 'AlertService', '$state', '$stateParams', 'pagingParams', 'paginationConstants', 'Upload', 'FileSaver', '$localStorage', '$scope'];

    function ProductsController (Principal, Product, ParseLinks, AlertService, $state, $stateParams, pagingParams, paginationConstants, Upload, FileSaver, $localStorage, $scope) {
        var vm = this;

        //storage setup
        $scope.$storage = $localStorage;
        if (!$scope.$storage.productColumns) { //due to $default issue with restoring storage
            $localStorage.productColumns = { cost: true };
        }
        vm.shownColumns = $scope.$storage.productColumns;
        if (!$scope.$storage.itemsPerPage.products) {
            $localStorage.itemsPerPage.products = paginationConstants.itemsPerPage;
        }
        vm.itemsPerPage = $scope.$storage.itemsPerPage.products;

        vm.init = init;
        vm.loadAll = loadAll;
        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        vm.products = [];
        vm.client = null;
        vm.clientName = null;
        vm.file = null;
        vm.searchParams = $stateParams.filter;
        vm.batchControlFilterOptions = [
            {name: "" , value: null},
            {name: "products.search.withBatchControl" , value: true},
            {name: "products.search.withoutBatchControl" , value: false}
        ];
        vm.itemsPerPageOptions = paginationConstants.itemsPerPageOptions;

        vm.uploadFile = uploadFile;
        vm.exportToWMS = exportToWMS;
        vm.batchControlStrings = batchControlStrings;
        vm.exportToExcel = exportToExcel;
        vm.changeItemsPerPage = changeItemsPerPage;
        vm.downloadTemplate = downloadTemplate;

        vm.init();

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.clientName = client.name;
                vm.loadAll();
            });
        }

        function loadAll () {
            Product.find({
                client: vm.client,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, vm.searchParams, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.products = data;
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filter: vm.searchParams
            });
        }

        function uploadFile () {
            vm.uploading = true;
            Upload.upload({
                url: '/api/import/xls/products',
                data: { clientCode: vm.client },
                file: vm.file
            }).then(function (response) {
                vm.uploading = false;
                $state.go('products.uploadReport', { report: response.data });
            }, function (error) {
                vm.uploading = false;
                onError(error);
            });
        }

        function exportToWMS () {
            vm.exporting = true;
            Product.send({
                client: vm.client
            }, null, function (result, headers) {
                vm.exporting = false;
            }, function (error) {
                vm.exporting = false;
                onError(error);
            });
        }

        function batchControlStrings (product) {
            var strings = [];
            if(product.lotTrack) {
                if(product.lotTrackLot) {
                    strings.push('products.batchControlOptions.LOT');
                }
                if(product.lotTrackPo) {
                    strings.push('products.batchControlOptions.PO');
                }
                if(product.lotTrackExp) {
                    strings.push('products.batchControlOptions.EXP_DATE');
                }
            } else {
                strings.push('products.batchControlOptions.NONE');
            }
            return strings;
        }

        function exportToExcel () {
            vm.savingXls = true;
            Product.toExcel({
                client: vm.client,
                sort: sort()
            },
            vm.searchParams,
            function (data) {
                vm.savingXls = false;
                FileSaver.saveAs(data.blob, "products.xlsx");
            }, function (error) {
                vm.savingXls = false;
                onError(error);
            });
        }

        function changeItemsPerPage () {
            // 2-way binding doesn't seem to work here for some reason
            $scope.$storage.itemsPerPage.products = vm.itemsPerPage;
            loadAll();
        }

        function downloadTemplate () {
            vm.savingTemplate = true;
            Product.getTemplate({
                client: vm.client
            }, function (data) {
                vm.savingTemplate = false;
                var filename = "mf_template.xls";
                FileSaver.saveAs(data.blob, filename);
            }, function (error) {
                vm.savingTemplate = false;
                onError(error);
            })
        }
    }
})();