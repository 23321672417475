(function () {
    'use strict';

   angular
        .module('rbllkApp')
        .factory('PasswordService', PasswordService);

    PasswordService.$inject = ['$resource'];

    function PasswordService (resource) {
        var service = resource('api/account', {}, {
            'change': { method: 'POST', url: 'api/account/change-password' },
            'resetFinish': { method: 'POST', url: 'api/account/reset-password/finish' },
            'resetInit': { method: 'POST', url: 'api/account/reset-password/init' },
        });

        return service;
    }
})();