(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('S3FileStorageController', S3FileStorageController);

    S3FileStorageController.$inject = ['S3FileStorage', 'AlertService'];

    function S3FileStorageController(S3FileStorage, AlertService) {
        var vm = this;

        vm.buckets = [];
        vm.objects = [];

        vm.getBuckets = getBuckets;
        vm.getObjects = getObjects;

        function getBuckets () {
            S3FileStorage.getBuckets(function (data) {
                vm.buckets = data;
            }, onError)
        }

        function getObjects (bucketName) {
            S3FileStorage.getObjects({
                bucketName: bucketName
            }, function (data) {
                vm.objects = data;
            }, onError)
        }

        function onError(error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        vm.fixLegacyRec = fixLegacyRec;

        function fixLegacyRec() {
            S3FileStorage.fixLegacyRec(function () {
                console.log('reclamation fix OK');
            }, onError);
        }

        vm.getOwnerGroups = getOwnerGroups;

        function getOwnerGroups() {
            vm.jsonData = null;
            S3FileStorage.getOwnerGroups(function (data) {
                vm.jsonData = JSON.stringify(data);
            }, onError);
        }

        vm.getOwnerGroupsByLogin = getOwnerGroupsByLogin;

        function getOwnerGroupsByLogin() {
            vm.jsonData = null;
            S3FileStorage.getOwnerGroupsByLogin({ login: vm.login }, function (data) {
                vm.clientGroups = data;
                vm.jsonData = JSON.stringify(data, null, 2);
            }, onError);
        }

        vm.selectOwner = selectOwner;

        function selectOwner (owner) {
            vm.client=owner;
            vm.clientSelectLabel=owner.name;
            vm.jsonData = JSON.stringify(owner, null, 2);
        }

        vm.groupToggles = [];
        vm.toggleGroup = toggleGroup;
        vm.isCollapsed = isCollapsed;

        function toggleGroup (index) {
            var toggleIndex = vm.groupToggles.indexOf(index)
            if (toggleIndex !== -1) {
                vm.groupToggles.splice(toggleIndex, 1);
            } else {
                vm.groupToggles.push(index);
            }
        }

        function isCollapsed (index) {
            return vm.groupToggles.indexOf(index) == -1
        }
    }
})();