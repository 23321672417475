(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('PickupOrderEditController', PickupOrderEditController);

    PickupOrderEditController.$inject = ['AddressTypeahead', '$state', '$stateParams', 'PickupOrder', 'entity', 'warehouses', 'PickupOrderContentTypes', 'PickupOrderPackageTypes', 'DeliveryOrderPaymentMethods', 'PickupOrderPayers', 'OrderTypes', 'StringUtils'];

    function PickupOrderEditController (AddressTypeahead, $state, $stateParams, PickupOrder, entity, warehouses, PickupOrderContentTypes, PickupOrderPackageTypes, DeliveryOrderPaymentMethods, PickupOrderPayers, OrderTypes, StringUtils) {
        var vm = this;

        vm.save = save;
        vm.client = $stateParams.client;
        vm.order = entity;
        vm.cancel = cancel;
        vm.paymentMethods = DeliveryOrderPaymentMethods;
        vm.contentTypes = PickupOrderContentTypes;
        vm.packageTypes = PickupOrderPackageTypes;
        vm.payers = PickupOrderPayers;
        vm.types = OrderTypes;
        vm.openCalendar = openCalendar;
        vm.warehouses = warehouses;
        vm.concatAddress = StringUtils.concatAddressShort;
        vm.isCourier = isCourier;
        vm.getStreet = getStreet;
        vm.getLocality = getLocality;
        vm.getRegion = getRegion;
        vm.getMetro = getMetro;

        function save () {
            vm.isSaving = true;
            if (vm.order.id !== null) {
                PickupOrder.update({client: vm.client}, vm.order, onSaveSuccess, onSaveError);
            } else {
                PickupOrder.save({client: vm.client}, vm.order, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $state.go('pickup-orders', null, {reload: true});
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function cancel () {
            $state.go('pickup-orders', null, {reload: true});
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function isCourier() {
            return vm.order.type === "COURIER";
        }

        function getStreet(val) {
            return AddressTypeahead.findStreet(val);
        }

        function getRegion(val) {
            return AddressTypeahead.findRegion(val);
        }

        function getLocality(val) {
            return AddressTypeahead.findLocality(val);
        }

        function getMetro(val) {
            return AddressTypeahead.findMetroStation(val, vm.order.warehouse.address.locality);
        }
    }
})();