(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('TransportCompany', TransportCompany);

    TransportCompany.$inject = ['$resource'];

    function TransportCompany ($resource) {
        var service = $resource('api/transport-companies/:client/:id', {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'delete': { method: 'DELETE' },
            'getList': { method: 'GET', url: 'api/list/transport-companies/:client/', isArray: true },
            'getCommon': { method: 'GET', url: 'api/list/common-tcs' },
            'find': { method: 'POST', url: 'api/find/transport-companies/:client/', isArray: true },
            'syncAll': { method: 'GET', url: 'api/external/wms/organization/getAll/:client' }
        });

        return service;
    }
})();