(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('WMSInventoryAltController', WMSInventoryAltController);

    WMSInventoryAltController.$inject = ['Principal', 'Reports', '$stateParams', 'paginationConstants', 'FileSaver'];

    function WMSInventoryAltController (Principal, Reports, $stateParams, paginationConstants, FileSaver) {
        var vm = this;

        vm.data = [];
        vm.filtersOpen = false;
        vm.filter = $stateParams.filter;

        vm.page = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.itemsPerPageOptions = paginationConstants.itemsPerPageOptions;
        vm.predicate = 'sku';
        vm.reverse = true;

        vm.loadAll = loadAll;
        vm.openCalendar = openCalendar;
        vm.clearFilter = clearFilter;
        vm.exportToExcel = exportToExcel;

        vm.shownColumns = { serial: false };

        init();

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                loadAll();
            });
        }

        function loadAll () {
            Reports.getInventory({
                client: vm.client,
            }, onSuccess);
        }

        function onSuccess (data) {
            vm.data = data;
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function clearFilter () {
            vm.filter = {
                sku: null,
                description: null,
                expAfter: null,
                expBefore: null,
                mfgAfter: null,
                mfgBefore: null,
                qtyGt: null,
                qtyLt: null,
                lotString: null,
                serial: null
            };
            vm.totalItems = null;
        }

        function exportToExcel() {
            Reports.exportInventoryToExcelAlt({
                client: vm.client
            },
            vm.filtered,
            function (data) {
                vm.exportingXlsx = false;
                FileSaver.saveAs(data.blob, "inventory.xlsx");
            },
            function () {
                vm.exportingXlsx = false;
            });
        }

    }
})();