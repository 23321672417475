(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('TransportSlotDialogController', TransportSlotDialogController);

    TransportSlotDialogController.$inject = ['entity', 'TransportSlots', 'TransportSlotsConstants', '$uibModalInstance', '$stateParams', '$translate', '$filter'];

    function TransportSlotDialogController(entity, TransportSlots, TransportSlotsConstants, $uibModalInstance, $stateParams, $translate, $filter) {
        var vm = this;

        vm.client = $stateParams.client;
        vm.data = entity;
        vm.needsRecalc = true;
        vm.transportTypeOptions = TransportSlotsConstants.TransportTypeOptions;
        vm.loadTypeOptions = TransportSlotsConstants.LoadTypeOptions;
        vm.gateOptions = ["33","34","35"];

        vm.clear = clear;
        vm.save = save;
        vm.openCalendar = openCalendar;
        vm.calculate = calculate;
        vm.setNeedsRecalc = setNeedsRecalc;
        vm.isRecalcDisabled = isRecalcDisabled;
        vm.getSlotString = getSlotString;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            TransportSlots.save({client: vm.client}, vm.data, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function calculate() {
            TransportSlots.calculate({client: vm.client}, vm.data, onCalcSuccess, onCalcError);
        }

        function onCalcSuccess (result) {
            vm.needsRecalc = false;
            vm.slotOptions = result;
        }

        function onCalcError () {
            vm.isSaving = false;
        }

        function setNeedsRecalc() {
            vm.needsRecalc = false;
            vm.slotOptions = [];
            vm.data.expectedSlot = null;
        }

        function isRecalcDisabled(editForm) {
            return editForm.gate.$invalid ||
                editForm.expectedDate.$invalid ||
                editForm.transportType.$invalid ||
                editForm.loadType.$invalid ||
                editForm.itemCount.$invalid
        }

        function getSlotString(slot) {
            return $translate.instant('transportSlots.strings.slot', { startTime: $filter('date')(slot.startTime, 'dd.MM.yyyy HH:mm'), duration: slot.duration });
        }
    }
})();