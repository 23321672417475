(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('client-management', {
            parent: 'admin',
            url: '/client-management?page&sort',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'clientManagement.home.title' //TODO: i18n
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/client-management/client-management.html',
                    controller: 'ClientManagementController',
                    controllerAs: 'vm'
                }
            },
            params: { //default params
                page: {
                    value: '1',
                    squash: true //omits defaults from string
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader){
                    $translatePartialLoader.addPart('client-management');
                    return $translate.refresh();
                }]
            }
        })
        .state('client-management-detail', {
            parent: 'admin',
            url: '/client/:code',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'clientManagement.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/client-management/client-management-detail.html',
                    controller: 'ClientManagementDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('client-management');
                    return $translate.refresh();
                }]
            }
        })
        .state('client-management.new', {
            parent: 'client-management',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'clientManagement.create.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/client-management/client-management-edit.html',
                    controller: 'ClientManagementEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        id: null, code: null, name: null, info: null, availableServices: [], createdBy: null,
                        createdDate: null, lastModifiedBy: null, lastModifiedDate: null,
                        servicesConfig: { wmsConfiguration: { batchControl: 'NONE', snControl: 'NONE', expirationControl: 'NONE', kit: 'NONE' }}
                    };
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('client-management');
                    return $translate.refresh();
                }],
                wsConfigs: ['WebServiceConfig', function (WebServiceConfig) {
                    return WebServiceConfig.query();
                }],
                financeConfigs: ['ExternalServiceConfig', function (ExternalServiceConfig) {
                    return ExternalServiceConfig.query({type: 'finance'});
                }],
                sqlConfigs: ['WmsSqlConfig', function (WmsSqlConfig) {
                    return WmsSqlConfig.query();
                }]
            }
         })
        .state('client-management.edit', {
            parent: 'client-management',
            url: '/{code}/edit',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'clientManagement.edit.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/client-management/client-management-edit.html',
                    controller: 'ClientManagementEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Client', function($stateParams, Client) {
                    return Client.get({code : $stateParams.code});
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('client-management');
                    return $translate.refresh();
                }],
                wsConfigs: ['WebServiceConfig', function (WebServiceConfig) {
                    return WebServiceConfig.query();
                }],
                financeConfigs: ['ExternalServiceConfig', function (ExternalServiceConfig) {
                    return ExternalServiceConfig.query({type: 'finance'});
                }],
                sqlConfigs: ['WmsSqlConfig', function (WmsSqlConfig) {
                    return WmsSqlConfig.query();
                }]
            }
        })
        .state('client-management.sync', {
            parent: 'client-management',
            url: '/:code/sync',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'clientManagement.sync.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/client-management/client-management-sync.html',
                    controller: 'ClientManagementSyncController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();