(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('AddressTypeahead', AddressTypeahead);

    AddressTypeahead.$inject = ['AddressSearch'];

    function AddressTypeahead (AddressSearch) {

        var service = {
            findAddressObject: findAddressObject,
            findStreet: findStreet,
            findLocality: findLocality,
            findRegion: findRegion,
            findMetroStation: findMetroStation
        };

        return service;

        function findAddressObject (term, type) {
            if (term.length > 1) {
                return AddressSearch.searchAddress({
                    term: term,
                    type: type
                }).$promise.then(function (response) {
                    return response;
                });
            }
        }

        function findStreet (term) {
            return findAddressObject(term, 'street');
        }

        function findLocality (term) {
            return findAddressObject(term, 'locality');
        }

        function findRegion (term) {
            return findAddressObject(term, 'region');
        }

        function findMetroStation(term, city) {
            if (term.length > 1) {
                return AddressSearch.searchMetroStation({
                    term: term,
                    city: city
                }).$promise.then(function (response) {
                    return response;
                });
            }
        }
    }
})();