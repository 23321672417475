(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('CourierOrderDeleteController', CourierOrderDeleteController);

    CourierOrderDeleteController.$inject = ['$uibModalInstance', 'entity', 'clientCode', 'CourierOrder'];

    function CourierOrderDeleteController ($uibModalInstance, entity, clientCode, CourierOrder) {
        var vm = this;

        vm.order = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.client = clientCode;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (orderCode) {
            CourierOrder.delete({
                client: vm.client,
                code: orderCode
            }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();