(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('OrderImportLogController', OrderImportLogController);

    OrderImportLogController.$inject = ['OrderImportLog', 'Principal', '$state', '$stateParams', 'AlertService', 'ParseLinks', 'pagingParams', 'paginationConstants', '$uibModal']

    function OrderImportLogController (OrderImportLog, Principal, $state, $stateParams, AlertService, ParseLinks, pagingParams, paginationConstants, $uibModal) {
        var vm  = this;

        vm.entries = [];
        vm.searchParams = $stateParams.filter;
        vm.importResultValues = ["SUCCESS", "FAILURE"];
        vm.orderTypeValues = ["PO", "SO"];


        vm.init = init;
        vm.loadAll = loadAll;
        vm.openCalendar = openCalendar;
        vm.showDetails = showDetails;

        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;

        vm.init();

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.clientName = client.name;
                vm.loadAll();
            });
        }

        function loadAll () {
            OrderImportLog.find({
                client: vm.client,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, vm.searchParams, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.entries = data;
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.message);
            }
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage () {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filter: vm.searchParams
            });
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function showDetails (entryId) {
            var dialog = $uibModal.open({
                templateUrl: 'app/admin/exchange-logs/order-import-log/order-import-log-detail.html',
                controller: 'OrderImportLogDetailController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    data: function () {
                        return OrderImportLog.get({id: entryId});
                    }
                }
            });
        }
    }
})();