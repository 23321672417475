(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('PickupOrdersController', PickupOrdersController);

    PickupOrdersController.$inject = ['Principal', 'PickupOrder', 'AlertService', '$state', '$stateParams', 'ParseLinks', 'pagingParams', 'paginationConstants', '$uibModal', 'FileSaver'];

    function PickupOrdersController(Principal, PickupOrder, AlertService, $state, $stateParams, ParseLinks, pagingParams, paginationConstants, $uibModal, FileSaver) {
        var vm = this;

        vm.init = init;
        vm.loadAll = loadAll;
        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.client = null;
        vm.clientName = null;
        vm.orders = [];
        vm.isNew = isNew;
        vm.sendOrder = sendOrder;
        vm.openCalendar = openCalendar;
        vm.searchParams = $stateParams.filter;
        vm.exportToExcel = exportToExcel;

        vm.init();

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.clientName = client.name;
                vm.loadAll();
            });
        }

        function loadAll () {
            PickupOrder.find({
                client: vm.client,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, vm.searchParams, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.orders = data;
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage () {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                filter: vm.searchParams
            });
        }

        function isNew (order) {
            return order.status === 0;
        }

        function sendOrder (selected) {
            var sendDialogModal = $uibModal.open({
                templateUrl: 'app/orders-delivery/pickup-orders/pickup-order-send-dialog.html',
                controller: 'PickupOrderSendDialogController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    data: function () {
                        return {
                            id: selected.id,
                            code: selected.code,
                            client: vm.client,
                            type: selected.type
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('delivery-orders');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function () {
                $state.reload();
            });
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function exportToExcel () {
            vm.savingXls = true;
            PickupOrder.toExcel({
                client: vm.client,
                sort: sort()
            },
            vm.searchParams,
            function (data) {
                vm.savingXls = false;
                FileSaver.saveAs(data.blob, "pickup_orders.xlsx");
            }, function (error) {
                vm.savingXls = false;
                onError(error);
            });
        }
    }
})();