(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('PostOrderDeleteController', PostOrderDeleteController);

    PostOrderDeleteController.$inject = ['$uibModalInstance', 'entity', 'clientCode', 'PostOrder'];

    function PostOrderDeleteController ($uibModalInstance, entity, clientCode, PostOrder) {
        var vm = this;

        vm.order = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.client = clientCode;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (orderCode) {
            PostOrder.delete({
                client: vm.client,
                code: orderCode
            }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();