(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('TransportOrdersDialogController', TransportOrdersDialogController);

    TransportOrdersDialogController.$inject = ['$uibModalInstance', 'client', 'orderService', 'selected', 'type', 'typeTranslations', 'AlertService', 'ParseLinks'];

    function TransportOrdersDialogController ($uibModalInstance, client, orderService, selected, type, typeTranslations, AlertService, ParseLinks) {
        var vm = this;

        vm.type = type;
        vm.selected = selected;
        vm.searchResult = [];
        vm.client = client;
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.itemsPerPageOptions = [5, 10, 20, 50, 100];
        vm.totalItems = null;
        vm.links = null;
        vm.searchParams = { code: null };
        vm.typeTranslations = typeTranslations;

        vm.clear = clear;
        vm.search = search;
        vm.loadPage = loadPage;
        vm.isAlreadySelected = isAlreadySelected;
        vm.addOrder = addOrder;
        vm.removeOrder = removeOrder;
        vm.translateType = translateType;
        vm.openCalendar = openCalendar;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function search () {
            vm.searching = true;
            vm.page = 1;
            loadPage();
        }

        function loadPage () {
            orderService.findTransportless({
                client: vm.client,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, vm.searchParams, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.searching = false;
            vm.searchResult = data;
        }

        function onError (error) {
            vm.searching = false;
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function isAlreadySelected (index) {
            return (vm.selected.filter(function (element) {
                return element.code === vm.searchResult[index].code;
            }).length > 0);
        }

        function addOrder (index) {
            vm.selected.push(vm.searchResult[index]);
        }

        function removeOrder (index) {
            vm.selected.splice(index, 1);
        }

        function translateType (string) {
            return vm.typeTranslations[string] ? string + " (" +vm.typeTranslations[string]+")" : string;
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }
    }
})();