(function () {
    'use strict';

    angular
    .module('rbllkApp')
    .controller('RepresentativeEditDialogController', RepresentativeEditDialogController);

    RepresentativeEditDialogController.$inject = ['$uibModalInstance', 'Representative', 'data', 'client']

    function RepresentativeEditDialogController ($uibModalInstance, Representative, data, client) {
        var vm  = this;

        vm.representative = data;
        vm.client = client;
        vm.save = save;
        vm.clear = clear;

        function save () {
            vm.isSaving = true;
            if (vm.representative.id !== null) {
                Representative.update({client: vm.client}, vm.representative, onSaveSuccess, onSaveError);
            } else {
                Representative.create({client: vm.client}, vm.representative, onSaveSuccess, onSaveError);
            }
        }
        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();