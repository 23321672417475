(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('external-configuration', {
            parent: 'admin',
            url: '/external-configuration',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'externalServicesConfiguration.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/external-configuration/external-configuration.html',
                    controller: 'ExternalServicesConfigurationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialProvider: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('external-configuration');
                    return $translate.refresh();
                }]
            }
        })
    }
})();