(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('SoReferenceEditController', SoReferenceEditController);

    SoReferenceEditController.$inject = ['entity', '$uibModalInstance', 'SalesOrder', '$stateParams'];

    function SoReferenceEditController(entity, $uibModalInstance, SalesOrder, $stateParams) {
        var vm = this;

        vm.order = entity;
        vm.client = $stateParams.client;
        vm.values = { reference7: null, reference9: null };

        vm.save = save;
        vm.clear = clear;

        function save () {
            if (entity.wmsId) {
                vm.values.soId = entity.wmsId;
                vm.isSaving = true;
                SalesOrder.updateReference({client: vm.client}, vm.values, onSaveSuccess, onSaveError);
            } else {
                // empty id ERROR
                console.log("ERROR: Empty soId");
            }
        };

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();