(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ClientSelectController', ClientSelectController);

    ClientSelectController.$inject = ['Principal', '$state'];

    function ClientSelectController (Principal, $state) {
        var vm = this;

        vm.currentClient = null;
        vm.clients = [];
        vm.clientNameFilter = "";

        vm.changeCurrentClient = changeCurrentClient;
        vm.filterActive = filterActive;

        Principal.getClientData().then(function (data) {
            vm.clients = data.clients.filter(filterActive).sort(compareByName);
            vm.currentClient = data.current;
        });

        function changeCurrentClient(client) {
            if (Principal.setCurrentClient(client)) {
                vm.currentClient = client;
                $state.reload();
            }
        }

        function compareByName(a, b) {
            return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
        }

        function filterActive(a) {
            return a.active;
        }
    }
})();