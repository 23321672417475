(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('SqlConfigurationDeleteController', SqlConfigurationDeleteController);

    SqlConfigurationDeleteController.$inject = ['$uibModalInstance', 'entity', 'WmsSqlConfig'];

    function SqlConfigurationDeleteController ($uibModalInstance, entity, WmsSqlConfig) {
        var vm = this;

        vm.config = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            WmsSqlConfig.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
