(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ItemImportLogDetailController', ItemImportLogDetailController);

    ItemImportLogDetailController.$inject = ['data', '$uibModalInstance'];

    function ItemImportLogDetailController (data, $uibModalInstance) {
        var vm = this;

        vm.data = data;
        vm.clear = clear;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();