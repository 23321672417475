(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .constant('PurchaseOrderStatuses', {
            "values": [0, 10, 1, 2, 3, 30, 100, 101],
            "strings": {
                "0": "purchaseOrders.statusValue.localNew",
                "1": "purchaseOrders.statusValue.localSentAll",
                "2": "purchaseOrders.statusValue.imported",
                "3": "purchaseOrders.statusValue.closed",
                "10": "purchaseOrders.statusValue.localSentWMS",
                "30": "purchaseOrders.statusValue.cancelled",
                "-1": "purchaseOrders.statusValue.invalid",
                "100": "purchaseOrders.statusValue.unfinished",
                "101": "purchaseOrders.statusValue.finished"
            }
        })
        .constant('PurchaseOrderTypes', {
            "values": [0, 1, 2, 3],
            "strings": {
                "0": "purchaseOrders.typeValue.purchase",
                "1": "purchaseOrders.typeValue.supplier",
                "2": "purchaseOrders.typeValue.return",
                "3": "purchaseOrders.typeValue.relocation"
            }
        })
        .constant('PurchaseOrderRecipientTemplate', {
            "organizationName": "РБ Лоджистикс",
            "schedule": "",
            "contactPerson": {
                "firstName": "",
                "patronymic": "",
                "lastName": "",
                "phoneNumber": "+7 (495) 744-8-744"
            },
            "address": {
                "zipcode": "143981",
                "country": "Россия",
                "region": "Московская обл.",
                "subRegion": "",
                "locality": "Балашиха",
                "street": "мкр. Южное Кучино, ул. Южная",
                "house": "21",
                "housing": "6",
                "building": "3",
                "apartment": "",
                "entrance": "",
                "floor": "",
                "metro": ""
            }
        });

})();