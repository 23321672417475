(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ManagedDocumentsAddController', ManagedDocumentsAddController);

    ManagedDocumentsAddController.$inject = ['document', 'categories', 'author', '$state', '$stateParams', 'Upload', 'AlertService', '$uibModal'];

    function ManagedDocumentsAddController (document, categories, author, $state, $stateParams, Upload, AlertService, $uibModal) {
        var vm = this;

        vm.client = $stateParams.client;
        vm.type = $stateParams.type;
        vm.document = document;
        vm.author = author;
        vm.file = null;
        vm.commentInput = null;
        vm.cancel = cancel;
        vm.save = save;
        vm.isEditable = true;
        vm.isAddEnabled = false;
        vm.saveButtonLabel = 'managedDocuments.saveNew';
        vm.categories = categories;
        vm.editEmails = editEmails;
        vm.stringifyEmails = stringifyEmails;

        function save () {
            vm.isSaving = true;
            addComment(vm.commentInput);
            Upload.upload({
                url: '/api/document/' + vm.type + '/' + vm.client,
                file: vm.file,
                data: { document: Upload.jsonBlob(vm.document) }
            }).then(onSuccess, onError);
        }

        function addComment() {
            vm.document.comments = [];
            vm.document.comments.push({
                message: vm.commentInput
            });
        }

        function onSuccess () {
            vm.isSaving = false;
            $state.go('managed-documents', null, {reload: true});
        }

        function onError (error) {
            vm.isSaving = false;
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), {param: error.headers('X-rbllkApp-params')});
            } else {
                AlertService.error({param: error.data.detail});
            }
        }

        function cancel () {
            $state.go('managed-documents', null, {reload: true});
        }

        function editEmails () {
            var editEmailsModal = $uibModal.open({
                templateUrl: 'app/documents/managed-documents/managed-documents-email-dialog.html',
                controller: 'ManagedDocumentsEmailDialog',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    emails: function () { return vm.document.emails.slice(); }
                }
            }).result.then(function (result) {
                vm.document.emails.length = 0;
                vm.document.emails = vm.document.emails.concat(result);
            })
        }

        function stringifyEmails () {
            return vm.document.emails.join('; ');
        }
    }
})();