(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('ItemImportLog', ItemImportLog);

    ItemImportLog.$inject = ['$resource'];

    function ItemImportLog ($resource) {
        var service = $resource('api/item-import-log', {}, {
            'find': { method: 'POST', url: 'api/find/item-import-log', isArray: true }
        });

        return service;
    }
})();