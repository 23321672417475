(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
        .state('exchange-logs', {
            parent: 'admin',
            url: '/exchange-logs',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'exchangeLogs.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/exchange-logs/exchange-logs.html',
                    controller: 'ExchangeLogsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialProvider: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('exchange-logs');
                    return $translate.refresh();
                }]
            }
        })
        .state('item-import-log', {
            parent: 'exchange-logs',
            url: '/item-import-log?page&sort',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'importLog.home.title'
            },
            views: {
                'log-cat-content': {
                    templateUrl: 'app/admin/exchange-logs/item-import-log/item-import-log.html',
                    controller: 'ItemImportLogController',
                    controllerAs: 'vm'
                }
            },
            params: { //default params
                page: {
                    value: '1',
                    squash: true //omits defaults from string
                },
                sort: {
                    value: 'createdDate,desc',
                    squash: true
                },
                filter: {
                    createdDate: null,
                    filename: null
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]
            }
        })
        .state('sync-events', {
            parent: 'exchange-logs',
            url: '/sync-events?page&sort',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'syncEvent.home.title'
            },
            views: {
                'log-cat-content': {
                    templateUrl: 'app/admin/exchange-logs/sync-events/sync-events.html',
                    controller: 'SyncEventsController',
                    controllerAs: 'vm'
                }
            },
            params: { //default params
                page: {
                    value: '1',
                    squash: true //omits defaults from string
                },
                sort: {
                    value: 'createdDate,desc',
                    squash: true
                },
                filter: {
                    docCode: null,
                    docType: null,
                    createdDate: null,
                    syncResult: null
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]
            }
        })
        .state('database-log', {
            parent: 'exchange-logs',
            url: '/database-log?page&sort',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'syncEvent.home.title'
            },
            views: {
                'log-cat-content': {
                    templateUrl: 'app/admin/exchange-logs/database-log/database-log.html',
                    controller: 'DatabaseLogController',
                    controllerAs: 'vm'
                }
            },
            params: { //default params
                page: {
                    value: '1',
                    squash: true //omits defaults from string
                },
                sort: {
                    value: 'createdDate,desc',
                    squash: true
                },
                filter: {
                    createdDate: null,
                    targetDb: null,
                    targetEntity: null,
                    targetEntityId: null,
                    operation: null,
                    result: null
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]
            }
        })
        .state('order-import-log', {
            parent: 'exchange-logs',
            url: '/order-import-log?page&sort',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'orderImportLog.home.title'
            },
            views: {
                'log-cat-content': {
                    templateUrl: 'app/admin/exchange-logs/order-import-log/order-import-log.html',
                    controller: 'OrderImportLogController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true //omits defaults from string
                },
                sort: {
                    value: 'createdDate,desc',
                    squash: true
                },
                filter: {
                    createdDate: null,
                    orderType: null,
                    result: null
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]
            }
        }

        );
    }
})();