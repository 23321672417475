(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ClientManagementSyncController', ClientManagementSyncController);

    ClientManagementSyncController.$inject = ['$state', '$stateParams', 'OrderSync', 'AlertService'];

    function ClientManagementSyncController ($state, $stateParams, OrderSync, AlertService) {
        var vm = this;

        vm.client = $stateParams.code;
        vm.intervals = ['DAYS', 'WEEKS', 'MONTHS'];
        vm.orderTypes = ['PO', 'SO', 'TRANSPORT'];
        vm.orderStatus = ['OPEN', 'CLOSED'];
        vm.dateRangeInvalid = false;
        vm.requiresSplit = false;

        vm.cancel = cancel;
        vm.openCalendar = openCalendar;
        vm.executeQuery = executeQuery;
        vm.validateDateRange = validateDateRange;
        vm.typeChanged = typeChanged;

        function cancel() {
            $state.go('client-management', null, {reload: true});
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

        function executeQuery () {
            vm.isQuerying = true;
            OrderSync.sendQuery({ client: vm.client }, vm.queryParams, function () {
                vm.isQuerying = false;
            }, onError);
        }

        function onError (error) {
            vm.isQuerying = false;
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function validateDateRange() {
            vm.dateRangeInvalid = vm.queryParams.dateFrom >= vm.queryParams.dateTo;
        }

        function typeChanged () {
            vm.requiresSplit = (vm.queryParams.type !== 'TRANSPORT' && vm.queryParams.type !== 'SO' && vm.queryParams.type !== 'PO');
            vm.requiresStatus =  (vm.queryParams.type !== 'TRANSPORT' && vm.queryParams.type !== 'SO' && vm.queryParams.type !== 'PO');
        }
    }
})();