(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('OrderSync', OrderSync);

    OrderSync.$inject = ['$resource'];

    function OrderSync ($resource) {
        var service = $resource('api/sync/date-interval/:client', {}, {
            'sendQuery': { method: 'POST' }
        });

        return service;
    }
})();