(function () {
    'use strict';

   angular
        .module('rbllkApp')
        .factory('OrganizationGroup', OrganizationGroup);

    OrganizationGroup.$inject = ['$resource'];

    function OrganizationGroup (resource) {
        var service = resource('api/org-groups', {}, {
            'getAll': { method: 'GET' , url: 'api/org-groups/:client/list', isArray: true },
            'saveMode': { method: 'POST', url: 'api/org-groups/:client/temp-mode' },
        });

        return service;
    }
})();