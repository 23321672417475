(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('Transport', Transport);

    Transport.$inject = ['$resource', 'DateUtils'];

    function Transport ($resource, DateUtils) {
        var service = $resource('api/transport/:client/:code', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    if (data.expectedDateTime) {
                        data.expectedDateTime = DateUtils.localDateTimeToUTC(data.expectedDateTime);
                    }
                    if (data.factArrival) {
                        data.factArrival = DateUtils.localDateTimeToUTC(data.factArrival);
                    }
                    if (data.factDock) {
                        data.factDock = DateUtils.localDateTimeToUTC(data.factDock);
                    }
                    if (data.factUndock) {
                        data.factUndock = DateUtils.localDateTimeToUTC(data.factUndock);
                    }
                    if (data.factDeparture) {
                        data.factDeparture = DateUtils.localDateTimeToUTC(data.factDeparture);
                    }
                    if (data.enterDateTime) {
                        data.enterDateTime = DateUtils.localDateTimeToUTC(data.enterDateTime);
                    }
                    return data;
                }
            },
            'save': { method: 'POST' },
            'update': { method: 'PUT' },
            'delete': { method: 'DELETE' },
            'find': { method: 'POST', url: 'api/find/transport/:client', isArray: true },
            'findDetailed': { method: 'POST', url: 'api/find/transport-detailed/:client', isArray: true },
            'toExcelSummary': {
                method: 'POST',
                url: 'api/excel/transport/summary/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'toExcelDetailed': {
                method: 'POST',
                url: 'api/excel/transport/detailed/:client',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            },
            'status': { method: 'POST', url: 'api/external/wms/transport/status/:client' },
            'statusBatch': { method: 'POST', url: 'api/external/wms/transport/statusBatch/:client' },
            'send': { method: 'POST', url: 'api/external/wms/transport/export/:client' },
            'getTemplate': { 
                method: 'GET', url: 'api/templates/transport-import/:client', responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    }; 
                }
            },
            'getLog': { method: 'GET', url: 'api/pub-log/transport/:client/:code', isArray: true }
        });

        return service;
    }
})();