(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('transport-slots', {
            parent: 'orders-warehouse',
            url: '/slots',
            data: {
                authorities: ['ROLE_ADMIN'], // temporary
                pageTitle: 'transportSlots.home.title',

            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-warehouse/transport-slots/transport-slots.html',
                    controller: 'TransportSlotsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('transport-slots');
                    return $translate.refresh();
                }]
            }
        })
        .state('transport-slots.edit', {
            parent: 'transport-slots',
            url: '/:client/edit',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'transportSlots.edit.title'
            },
            params: {
                date: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/orders-warehouse/transport-slots/transport-slot-dialog.html',
                    controller: 'TransportSlotDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                licensePlate: null, vehicleType: null, loadType: null, itemCount: null,
                                expectedDuration: null, driverName: null, driverPhoneNumber: null,
                                expectedDate: $stateParams.date ? $stateParams.date : null, gate: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('transport-slots', null, { reload: true });
                }, function() {
                    $state.go('transport-slots');
                });
            }]
        })

    }
})();