(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ReclamationSkuDialogController', ReclamationSkuDialogController);

    ReclamationSkuDialogController.$inject = ['client', '$uibModalInstance', 'paginationConstants', 'AlertService', 'ParseLinks', 'Reclamation'];

    function ReclamationSkuDialogController(client, $uibModalInstance, paginationConstants, AlertService, ParseLinks, Reclamation) {
        var vm = this;

        vm.client = client;
        vm.searchResult = [];
        vm.page = 1;
        vm.predicate = 'code';
        vm.reverse = true;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.itemsPerPageOptions = paginationConstants.itemsPerPageOptions;
        vm.totalItems = null;
        vm.links = null;
        vm.searchParams = { code: null };

        vm.clear = clear;
        vm.search = search;
        vm.loadPage = loadPage;
        vm.selectProduct = selectProduct;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function search () {
            vm.searching = true;
            vm.page = 1;
            loadPage();
        }

        function loadPage () {
            Reclamation.findSku({
                clientCode: vm.client.code,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, vm.searchParams, onSuccess, onError);
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.searching = false;
            vm.searchResult = data;
        }

        function onError (error) {
            vm.searching = false;
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function selectProduct (product) {
            $uibModalInstance.close(product);
        }
    }
})();