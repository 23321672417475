(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('AddProductDialogController', AddProductDialogController);

    AddProductDialogController.$inject = ['$uibModalInstance', 'client', 'Product', 'AlertService', 'ParseLinks', 'OrderConstants'];

    function AddProductDialogController ($uibModalInstance, client, Product, AlertService, ParseLinks, OrderConstants) {
        var vm = this;
        vm.client = client;
        vm.ndsvalues = OrderConstants.VATRates;

        vm.clear = clear;
        vm.confirm = confirm;

        vm.searchParams = {
            code: null,
            description: null
        };
        vm.search = search;
        vm.searchResult = null;
        vm.products = [];

        vm.loadPage = loadPage;
        vm.page = 1;
        vm.itemsPerPage = 5;
        vm.itemsPerPageOptions = [5, 10, 20, 50, 100];
        vm.totalItems = null;
        vm.links = null;

        vm.isAlreadySelected = isAlreadySelected;
        vm.addProduct = addProduct;
        vm.removeProduct = removeProduct;

        function search () {
            vm.searching = true;
            vm.page = 1;
            loadPage();
        }

        function clear () {
             $uibModalInstance.dismiss('cancel');
        }

        function confirm () {
            $uibModalInstance.close(vm.products);
        }

        function loadPage () {
            Product.find({
                client: vm.client,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, vm.searchParams, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.searching = false;
            vm.searchResult = data;
        }

        function onError (error) {
            vm.searching = false;
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function isAlreadySelected (index) {
            return (vm.products.filter(function (element) {
                return element.product.code === vm.searchResult[index].code;
            }).length > 0);
        }

        function addProduct (index) {
            vm.products.push({product: vm.searchResult[index], cost: vm.searchResult[index].cost, quantity: 1, nds: 20});
        }

        function removeProduct (index) {
            vm.products.splice(index, 1);
        }
    }
})();