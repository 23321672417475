(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('WsConfigurationDeleteController', WsConfigurationDeleteController);

    WsConfigurationDeleteController.$inject = ['$uibModalInstance', 'entity', 'WebServiceConfig'];

    function WsConfigurationDeleteController ($uibModalInstance, entity, WebServiceConfig) {
        var vm = this;

        vm.config = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            WebServiceConfig.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
