(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('RevisionController', RevisionController);
    
    RevisionController.$inject = ['Principal', 'AlertService', 'Upload', 'FileSaver'];

    function RevisionController(Principal, AlertService, Upload, FileSaver) {
        var vm = this;

        vm.fileClient = null;
        vm.fileWarehouse = null;
        vm.checkStatus = false;

        vm.uploadFiles = uploadFiles;

        init();

        function init () {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.clientName = client.name;
            });
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function uploadFiles() {
            vm.uploading = true;
            Upload.upload({
                url: '/api/revision/xls',
                data: {
                    clientCode: vm.client,
                    checkStatus: vm.checkStatus,
                    fileCl: vm.fileClient,
                    fileWh: vm.fileWarehouse
                },
                responseType: 'blob'
                // arrayKey: ''
            }).then(function (response) {
                vm.uploading = false;
                var filename = "revision.xlsx";
                var blob = new Blob([response.data], {type: response.headers('Content-Type')})
                FileSaver.saveAs(blob, filename)
            }, function (error) {
                vm.uploading = false;
                onError(error);
            });
        }
    }
})();