(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .directive('hasService', hasService);

    hasService.$inject = ['Principal'];
    function hasService(Principal) {
        var directive = {
            link: linkFunc,
            restrict: 'A'
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var service = attrs.hasService.replace(/\s+/g, '');
            var setVisible = function() {
                element.removeClass('hidden');
            },
            setHidden = function() {
                element.addClass('hidden');
            },
            defineVisibility = function(reset) {
                if (reset) {
                    setVisible();
                }

                Principal.hasService(service)
                    .then(function (result) {
                        if (result) {
                            setVisible();
                        } else {
                            setHidden();
                        }
                    });
            };

            if (service.length > 0) {
                defineVisibility(true);

                scope.$watch(function() {
                    return Principal.isAuthenticated();
                }, function() {
                    defineVisibility(true);
                });
            }
        }
    }
})();