(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ExchangeLogsController', ExchangeLogsController);

    ExchangeLogsController.$inject = [];

    function ExchangeLogsController () {
        var vm = this;
    }
})();