(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .constant('ReclamationConstants', {
            'AllStatusOptions': [
                'DRAFT',
                'NEW',
                'IN_PROGRESS',
                'CLOSED',
                'SUSPENDED'
            ],
            'MgmtStatusOptions': [
                'NEW',
                'IN_PROGRESS',
                'CLOSED'
            ]
        });

})();