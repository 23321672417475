(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('OrgGroupEditController', OrgGroupEditController);

    OrgGroupEditController.$inject = ['client', 'group', 'OrganizationGroup', '$uibModalInstance'];

    function OrgGroupEditController(client, group, OrganizationGroup, $uibModalInstance) {
        var vm = this;

        vm.client = client;
        vm.group = group;
        vm.modeNotSet = vm.group.tempMode == null;

        vm.save = save;
        vm.clear = clear;

        function save () {
            vm.isSaving = true;
            OrganizationGroup.saveMode({
                client: vm.client
            }, {
                id: vm.group.id,
                tempMode: vm.modeNotSet ? null : vm.group.tempMode
            }, onSuccess, onError);
        }

        function onSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onError (error) {
            vm.isSaving = false;
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }
        

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();