(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('WarehouseEditController', WarehouseEditController);

    WarehouseEditController.$inject = ['AddressTypeahead', '$state', '$stateParams', 'Warehouse', 'entity'];

    function WarehouseEditController (AddressTypeahead, $state, $stateParams, Warehouse, entity) {
        var vm = this;

        vm.save = save;
        vm.client = $stateParams.client;
        vm.warehouse = entity;
        vm.cancel = cancel;
        vm.getStreet = getStreet;
        vm.getLocality = getLocality;
        vm.getRegion = getRegion;
        vm.getMetro = getMetro;

        function save () {
            vm.isSaving = true;
            if (vm.warehouse.id !== null) {
                Warehouse.update({client: vm.client}, vm.warehouse, onSaveSuccess, onSaveError);
            } else {
                Warehouse.save({client: vm.client}, vm.warehouse, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess () {
            vm.isSaving = false;
            $state.go('warehouses', null, {reload: true});
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function cancel () {
            $state.go('warehouses', null, {reload: true});
        }

        function getStreet(val) {
            return AddressTypeahead.findStreet(val);
        }

        function getRegion(val) {
            return AddressTypeahead.findRegion(val);
        }

        function getLocality(val) {
            return AddressTypeahead.findLocality(val);
        }

        function getMetro(val) {
            return AddressTypeahead.findMetroStation(val, vm.warehouse.address.locality);
        }
    }
})();