(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$uibModalInstance', 'entity', 'clients', 'User', 'JhiLanguageService', 'UserConstants'];

    function UserManagementDialogController ($uibModalInstance, entity, clients, User, JhiLanguageService, UserConstants) {
        var vm = this;

        vm.authorities = UserConstants.AuthorityOptions;
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;
        vm.clients = clients; //client list
        vm.confirmPassword = null;

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            if (vm.user.id == null) {
                vm.doNotMatch = vm.user.password !== vm.confirmPassword;
            }
            if (!vm.doNotMatch) {
                vm.isSaving = true;
                if (vm.user.id !== null) {
                    User.update(vm.user, onSaveSuccess, onSaveError);
                } else {
                    User.save(vm.user, onSaveSuccess, onSaveError);
                }
            }
        }
    }
})();
