(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('OrderImportLog', OrderImportLog);

    OrderImportLog.$inject = ['$resource'];

    function OrderImportLog ($resource) {
        var service = $resource('api/order-import-log/:id', {}, {
            'find': { method: 'POST', url: 'api/find/order-import-log/:client', isArray: true },
            'get': { method: 'GET' }
        });

        return service;
    }
})();