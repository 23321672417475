(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('errorHandlerInterceptor', errorHandlerInterceptor);

    errorHandlerInterceptor.$inject = ['$q', '$rootScope'];

    function errorHandlerInterceptor ($q, $rootScope) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError (response) {
            if (!(response.status === 401 && (response.data === '' || (response.data.path && response.data.path.indexOf('/api/account') === 0 )))) {
                if (response.status === 500 && response.data.blob) {
                    return blobToJson(response.data.blob).then(function(result) {
                        response.data = result;
                        $rootScope.$emit('rbllkApp.httpError', response);
                        return $q.reject(response);
                    })
                }
                $rootScope.$emit('rbllkApp.httpError', response);
            }
            return $q.reject(response);
        }

        function blobToJson (blob) {
            return new Promise(function(resolve, reject) {
                var reader = new FileReader();
                reader.onload = function () {
                    resolve(JSON.parse(reader.result));
                }
                reader.onerror = function(error) { reject(error) };
                reader.readAsText(blob);
            })
        }
    }
})();
