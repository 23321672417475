(function () {
    'use strict'

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('representatives', {
            parent: 'general-information',
            url: '/representatives',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'representatives.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/general-information/representatives/representatives.html',
                    controller: 'RepresentativesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('representatives');
                    return $translate.refresh();
                }]
            }
        })
    }
})();