(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ManagedDocumentsController', ManagedDocumentsController);

    ManagedDocumentsController.$inject = ['Principal', 'ManagedDocuments', '$state', '$stateParams', 'AlertService', 'pagingParams', 'paginationConstants', 'ParseLinks'];

    function ManagedDocumentsController (Principal, ManagedDocuments, $state, $stateParams, AlertService, pagingParams, paginationConstants, ParseLinks) {
        var vm = this;

        vm.init = init;
        vm.loadAll = loadAll;
        vm.getCreateAuthorities = getCreateAuthorities;

        vm.type = $stateParams.type;
        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;

        vm.statusColor = statusColor;

        vm.documents = [];

        vm.init();

        function init() {
            Principal.getCurrentClient().then(function (client) {
                vm.client = client.code;
                vm.clientName = client.name;
                vm.loadAll();
            });
        }

        function loadAll () {
            ManagedDocuments.query({
                type: vm.type,
                client: vm.client,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.documents = data;
        }

        function onError (error) {
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage () {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                type: vm.type
            });
        }

        function statusColor (status) {
            switch (status) {
                case 'NEW': return 'label-primary';
                case 'TO_CLIENT': return 'label-info';
                case 'ON_REVISION': return 'label-warning';
                case 'CONFIRMED': return 'label-success';
                default: return 'label-default';
            }
        }

        function getCreateAuthorities () {
            switch (vm.type) {
                case 'rb': return 'ROLE_ADMIN, ROLE_OPERATOR';
                case 'cl': return 'ROLE_USER';
            }
        }
    }
})();