(function () {
    'use strict';

    angular.module('rbllkApp').controller('ProductDetailController', ProductDetailController);

    ProductDetailController.$inject = ['entity', 'ProductConstants', 'remains'];

    function ProductDetailController (entity, ProductConstants, remains) {
        var vm = this;

        vm.product = entity;
        vm.batchControlOptions = ProductConstants.BatchControlOptions;
        vm.serialCodeControlOptions = ProductConstants.SerialCodeControlOptions;
        vm.remains = remains;
    }
})();