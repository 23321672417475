(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('PickupOrderDetailController', PickupOrderDetailController);

    PickupOrderDetailController.$inject = ['entity','PickupOrderContentTypes', 'PickupOrderPackageTypes', 'DeliveryOrderPaymentMethods', 'PickupOrderPayers', 'StringUtils'];

    function PickupOrderDetailController (entity, PickupOrderContentTypes, PickupOrderPackageTypes, DeliveryOrderPaymentMethods, PickupOrderPayers, StringUtils) {
        var vm = this;

        vm.order = entity;
        vm.paymentMethods = DeliveryOrderPaymentMethods;
        vm.contentTypes = PickupOrderContentTypes;
        vm.packageTypes = PickupOrderPackageTypes;
        vm.payers = PickupOrderPayers;
        vm.getTranslationString = StringUtils.getTranslationString;
        vm.concatAddress = StringUtils.concatAddressShort;
        vm.stringifyAddress = StringUtils.stringifyAddress;
    }
})();