(function () {
    'use strict';

   angular
        .module('rbllkApp')
        .factory('ReclamationReportService', ReclamationReportService);

    ReclamationReportService.$inject = ['$resource'];

    function ReclamationReportService (resource) {
        var service = resource('api/reclamation/reports', {}, {
            'getReport1': { 
                method: 'POST',
                url: 'api/reclamation/reports/report1',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    }; 
                }
            },
            'getReport2': { 
                method: 'POST',
                url: 'api/reclamation/reports/report2',
                responseType: 'blob',
                transformResponse: transformToBlob
            },
            'getReport2a': { 
                method: 'POST',
                url: 'api/reclamation/reports/report2a',
                responseType: 'blob',
                transformResponse: transformToBlob
            },
            'getReport2b': { 
                method: 'POST',
                url: 'api/reclamation/reports/report2b',
                responseType: 'blob',
                transformResponse: transformToBlob
            },
            'getReport3': {
                method: 'POST',
                url: 'api/reclamation/reports/report3',
                responseType: 'blob',
                transformResponse: transformToBlob
            },
            'getReport4': {
                method: 'POST',
                url: 'api/reclamation/reports/report4',
                responseType: 'blob',
                transformResponse: transformToBlob
            },
            'getWarehouses': {
                method: 'GET',
                url: 'api/reclamation/utils/warehouses',
                isArray: true
            },
            'getClients': {
                method: 'GET',
                url: 'api/reclamation/utils/clients',
                isArray: true
            },
            'getRecipients': {
                method: 'GET',
                url: 'api/reclamation/utils/recipients',
                isArray: true
            },
            'getReportStatistics': {
                method: 'POST',
                url: 'api/reclamation/reports/statistics_rep',
                responseType: 'blob',
                transformResponse: transformToBlob
            },
        });

        function transformToBlob (data, headers) {
            return {
                blob: new Blob([data], {type: headers('Content-Type')})
            }; 
        }

        return service;
    }
})();