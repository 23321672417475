(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', '$scope', 'SidebarService'];

    function NavbarController ($state, Auth, Principal, ProfileService, LoginService, $scope, SidebarService) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.isSidebarCollapsed = SidebarService.getState;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.toggleSidebar = toggleSidebar;

        $scope.$on('authenticationSuccess', function() {
            updateCurrentUser();
        });
        updateCurrentUser();

        function updateCurrentUser () {
            return Principal.getUserNameAndLogin().then(function (response) {
                vm.currentUser = response.username;
            });
        }

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function toggleSidebar () {
            SidebarService.toggle();
        }
    }
})();
