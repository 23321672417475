(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .filter('inventoryFilter', inventoryFilter);

    function inventoryFilter () {
        var filter = function (items, params) {
            if (isEmpty(params.sku) &&
                isEmpty(params.description) &&
                isEmpty(params.lotString) &&
                isEmpty(params.serial) &&
                isEmpty(params.qtyGt) &&
                isEmpty(params.qtyLt) &&
                isEmpty(params.mfgAfter) &&
                isEmpty(params.mfgBefore) &&
                isEmpty(params.expAfter) && 
                isEmpty(params.expBefore) &&
                isEmpty(params.skuGroups)) {
                return items;
            }
            
            var filteredItems = [];
            if (angular.isArray(items) && items.length > 0) {
                filteredItems = items.filter(function (item) {
                    return (isEmpty(params.sku) || item.sku.toUpperCase().indexOf(params.sku.toUpperCase()) !== -1) &&
                    (isEmpty(params.description) || item.description.toUpperCase().indexOf(params.description.toUpperCase()) !== -1) &&
                    (isEmpty(params.lotString) || item.lotString.toUpperCase().indexOf(params.lotString.toUpperCase()) !== -1) &&
                    (isEmpty(params.serial) || (!isEmpty(item.serial) && item.serial.toUpperCase().indexOf(params.serial.toUpperCase()) !== -1)) &&
                    (isEmpty(params.qtyGt) || item.quantity > params.qtyGt) &&
                    (isEmpty(params.qtyLt) || item.quantity < params.qtyLt) &&
                    (isEmpty(params.mfgAfter) || (!isEmpty(item.mfgDate) && item.mfgDate.getTime() > params.mfgAfter.getTime())) &&
                    (isEmpty(params.mfgBefore) || (!isEmpty(item.mfgDate) && item.mfgDate.getTime() < params.mfgBefore.getTime())) &&
                    (isEmpty(params.expAfter) || (!isEmpty(item.expDate) && item.expDate.getTime() > params.expAfter.getTime())) &&
                    (isEmpty(params.expBefore) || (!isEmpty(item.expDate) && item.expDate.getTime() < params.expBefore.getTime())) &&
                    (isEmpty(params.skuGroups) || (!isEmpty(item.skuGroups) && item.skuGroups.toUpperCase().indexOf(params.skuGroups.toUpperCase()) !== -1));
                });
                return filteredItems;
            }
        };

        var isEmpty = function (item) {
            return (typeof item == 'undefined' || item === null || item === '');
        };

        return filter;
    }
})();