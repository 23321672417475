(function() {
'use strict';

    angular
        .module('rbllkApp')
        .controller('ClientManagementDetailController', ClientManagementDetailController);

    ClientManagementDetailController.$inject = ['$stateParams', 'Client'];
    function ClientManagementDetailController($stateParams, Client) {
        var vm = this;

        vm.load = load;
        vm.client = {};

        vm.load($stateParams.code);

        function load (code) {
            Client.get({code: code}, function(result) {
                vm.client = result;
            });
        }
    }
})();