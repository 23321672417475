(function () {
    'use strict'

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('client-info', {
            parent: 'general-information',
            url: '/client-info',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'clientInfo.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/general-information/client-info/client-info-card.html',
                    controller: 'ClientInfoCardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('client-info');
                    return $translate.refresh();
                }]
            }
        })
        .state('client-info.new', {
            parent: 'client-info',
            url: '/:client/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CLIENT_INFO_EDITOR'],
                pageTitle: 'clientInfo.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/general-information/client-info/client-info-card-edit.html',
                    controller: 'ClientInfoCardEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('client-info');
                    return $translate.refresh();
                }],
                data: function () {
                    return {
                        //TODO
                        id: null,
                        contractId: null, conractDate: null, legalAddress: null, actualAddress: null, warehouseAddress: null,
                        contactPersons: [],
                        storage: []
                    }
                }
            }
        })
        .state('client-info.edit', {
            parent: 'client-info',
            url: '/:client/edit/:id',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CLIENT_INFO_EDITOR'],
                pageTitle: 'clientInfo.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/general-information/client-info/client-info-card-edit.html',
                    controller: 'ClientInfoCardEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('client-info');
                    return $translate.refresh();
                }],
                data: ['ClientInfo', '$stateParams', function (ClientInfo, $stateParams) {
                    return ClientInfo.get({
                        client: $stateParams.client,
                        id: $stateParams.id
                    });
                }]

            }
        })
    }
})();