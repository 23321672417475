(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('SyncEventDetailController', SyncEventDetailController);

    SyncEventDetailController.$inject = ['event', '$uibModalInstance'];

    function SyncEventDetailController (event, $uibModalInstance) {
        var vm = this;

        vm.event = event;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();