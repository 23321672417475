(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .service('ClosingDocuments', ClosingDocuments);

    ClosingDocuments.$inject = ['$resource', 'Blob'];

    function ClosingDocuments($resource, Blob) {
        var service = $resource('api/closing-documents/:client', {}, {
            'list': {
                method: 'POST',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getFile': {
                method: 'GET',
                url: 'api/closing-documents/:client/download',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    };
                }
            }
        });

        return service;
    }
})();