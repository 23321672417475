(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('post-orders', {
            parent: 'orders-delivery',
            url: '/post-orders?page&sort',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'postOrders.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-delivery/post-orders/post-orders.html',
                    controller: 'PostOrdersController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value:'externalCode,asc',
                    squash: true
                },
                filter: {
                    code: null,
                    plannedDate: null
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('delivery-orders');
                    $translatePartialLoader.addPart('post-orders');
                    return $translate.refresh();
                }]
            }
        })
        .state('post-order-detail', {
            parent: 'post-orders',
            url: '/:client/detail/:code',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OPERATOR'],
                pageTitle: 'postOrders.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-delivery/post-orders/post-order-detail.html',
                    controller: 'PostOrderDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'PostOrder', function ($stateParams, PostOrder) {
                    return PostOrder.get({
                        client: $stateParams.client,
                        code: $stateParams.code
                    });
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('delivery-orders');
                    $translatePartialLoader.addPart('post-orders');
                    return $translate.refresh();
                }]
            }
        })
        .state('post-orders.new', {
            parent: 'post-orders',
            url: '/:client/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'postOrders.create.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-delivery/post-orders/post-order-edit.html',
                    controller: 'PostOrderEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('delivery-orders');
                    $translatePartialLoader.addPart('post-orders');
                    return $translate.refresh();
                }],
                warehouses: ['$stateParams', 'Warehouse', function ($stateParams, Warehouse) {
                    return Warehouse.getList({ client: $stateParams.client });
                }],
                defaultName: ['Principal', function (Principal) {
                    return Principal.getUserNameAndLogin();
                }],
                entity: ['defaultName', function (defaultName) {
                    return {
                        id: null, createdDate: null, lastModifiedBy: null, lastModifiedDate: null,
                        externalCode: null, internalCode: null,
                        orderCreationDate: new Date(), author: defaultName.username,
                        address: null, weight: 0, length: 0, width: 0, height: 0,
                        deliveryTimeFrom: null, deliveryTimeTo: null,
                        assessedCost: 0, totalCost: 0, deliveryCost: 0, paymentMethod: null, recipient: null,
                        cargoSpaces: [], products: []
                    };
                }]
            }
        })
        .state('post-orders.edit', {
            parent: 'post-orders',
            url: '/:client/edit/:code',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'postOrders.edit.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/orders-delivery/post-orders/post-order-edit.html',
                    controller: 'PostOrderEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('delivery-orders');
                    $translatePartialLoader.addPart('post-orders');
                    return $translate.refresh();
                }],
                warehouses: ['$stateParams', 'Warehouse', function ($stateParams, Warehouse) {
                    return Warehouse.getList({ client: $stateParams.client });
                }],
                entity: ['$stateParams', 'PostOrder', function ($stateParams, PostOrder) {
                    return PostOrder.get({
                        client: $stateParams.client,
                        code: $stateParams.code
                    });
                }]
            }
        })
        .state('post-orders.delete', {
            parent: 'post-orders',
            url: '/:client/delete/:code',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_EDITOR'],
                pageTitle: 'postOrders.delete.title'
            },
            params: {
                code: '—'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/orders-delivery/post-orders/post-order-delete-dialog.html',
                    controller: 'PostOrderDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                code: $stateParams.code
                            };
                        },
                        clientCode: function () {
                            return $stateParams.client;
                        }
                    }
                }).result.then(function () {
                    $state.go('post-orders', null, { reload: true });
                }, function () {
                    $state.go('^');
                });
            }]
        });
    }
})();