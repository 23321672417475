(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .constant('ReportReceivingConstants', {
            'statusValues': [
                "Новый",
                "Закрытый",
                "Открытый"
            ]
        })
        .constant('ReportShippingConstants', {
            'statusValues': [
                "Новый",
                "Отпущен В Отбор",
                "Доставлен",
                "Отменен",
                "Отгружен",
                "Отобран",
                "Загружен"
            ]
        });
})();