(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('TransportDeleteController', TransportDeleteController);

    TransportDeleteController.$inject = ['$uibModalInstance', 'data', 'Transport', 'Base64utf8'];

    function TransportDeleteController ($uibModalInstance, data, Transport, Base64utf8) {
        var vm = this;

        vm.transport = data;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (orderCode) {
            Transport.delete({
                client: vm.transport.client,
                code: Base64utf8.encodeMod(orderCode)
            }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();