(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('ExternalServicesConfigurationController', ExternalServicesConfigurationController);

    ExternalServicesConfigurationController.$inject = [];

    function ExternalServicesConfigurationController () {
        var vm = this;
    }
})();