(function() {
    'use strict';

    angular
        .module('rbllkApp')
        .config(localStorageConfig);

    localStorageConfig.$inject = ['$localStorageProvider', '$sessionStorageProvider'];

    function localStorageConfig($localStorageProvider, $sessionStorageProvider) {
        $localStorageProvider.setKeyPrefix('rbllk-');
        $sessionStorageProvider.setKeyPrefix('rbllk-');

        //defaults
        if (!$localStorageProvider.get('itemsPerPage')) {
            $localStorageProvider.set('itemsPerPage', {});
        }

        if (!$sessionStorageProvider.get('filters')) {
            $sessionStorageProvider.set('filters', {});
        }
    }
})();
