(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .controller('AddOrderDialogController', AddOrderDialogController);

    AddOrderDialogController.$inject = ['$uibModalInstance', 'client', 'orderResource', 'AlertService', 'ParseLinks'];

    function AddOrderDialogController ($uibModalInstance, client, orderResource, AlertService, ParseLinks) {
        var vm = this;

        vm.client = client;

        vm.clear = clear;
        vm.confirm = confirm;
        vm.openCalendar = openCalendar;

        vm.searchParams = {
            code: "",
            internalCode: "",
            creationDate: null,
            statuses: [1]
        };
        vm.search = search;
        vm.searchResult = [];
        vm.orders = [];

        vm.loadPage = loadPage;
        vm.page = 1;
        vm.itemsPerPage = 5;
        vm.totalItems = null;
        vm.links = null;

        vm.isAlreadySelected = isAlreadySelected;
        vm.addOrder = addOrder;
        vm.removeOrder = removeOrder;

        function select (index) {
            if (vm.checkboxes[index]) {
                vm.orders.push(vm.searchResult[index]);
            } else {
                vm.orders.splice(vm.orders.indexOf(vm.searchResult[index]), 1);
            }
        }

        function search () {
            vm.searching = true;
            vm.page = 1;
            if (vm.searchParams.code !== null || vm.searchParams.internalCode !== null || vm.searchParams.creationDate !== null) {
                loadPage();
            }
        }

        function loadPage () {
            orderResource.find({
                client: vm.client,
                page: vm.page - 1,
                size: vm.itemsPerPage
            }, vm.searchParams, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.searching = false;
            vm.searchResult = data;
        }

        function onError (error) {
            vm.searching = false;
            if (error.headers('X-rbllkApp-error')) {
                AlertService.error(error.headers('X-rbllkApp-error'), [error.headers('X-rbllkApp-params')]);
            } else {
                AlertService.error(error.data.message, [error.data.description]);
            }
        }

        function isAlreadySelected (index) {
            return (vm.orders.filter(function (element) {
                return element.externalCode === vm.searchResult[index].externalCode;
            }).length > 0);
        }

        function openCalendar (picker) {
            vm[picker] = true;
        }

         function clear () {
             $uibModalInstance.dismiss('cancel');
         }

         function confirm () {
            $uibModalInstance.close(vm.orders);
         }

         function addOrder (index) {
            vm.orders.push(vm.searchResult[index]);
        }

        function removeOrder(index) {
            vm.orders.splice(index, 1);
        }
    }
})();