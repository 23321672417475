(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('Reclamation', Reclamation);

    Reclamation.$inject = ['$resource'];

    function Reclamation ($resource) {
        var service = $resource('api/reclamation/doc/:id', {}, {
            'get': { 
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    if (data.createdDate) {
                        data.createdDate = new Date(data.createdDate);
                    }
                    if (data.eventDate) {
                        data.eventDate = new Date(data.eventDate);
                    }
                    if (data.startDate) {
                        data.startDate = new Date(data.startDate);
                    }
                    if (data.dueDate) {
                        data.dueDate = new Date(data.dueDate);
                    }
                    return data;
                }
            },
            'getExt': { 
                method: 'GET',
                url: 'api/reclamation/process/:id',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    if (data.createdDate) {
                        data.createdDate = new Date(data.createdDate);
                    }
                    if (data.eventDate) {
                        data.eventDate = new Date(data.eventDate);
                    }
                    if (data.startDate) {
                        data.startDate = new Date(data.startDate);
                    }
                    if (data.dueDate) {
                        data.dueDate = new Date(data.dueDate);
                    }
                    return data;
                }
            },
            'save': { method: 'POST' },
            'update': { method: 'PUT' },
            'delete': { method: 'DELETE' },
            'find': { method: 'POST', url: 'api/find/reclamation/pageable', isArray: true },
            'findSo': { method: 'POST', url: 'api/find/reclamation/so/:dbId', isArray: true },
            'getSoDetailed': { method: 'GET', url: 'api/find/reclamation/so-items/:dbId/:owner/:soId' },
            'findSku': { method: 'POST', url: '/api/find/reclamation/sku/:clientCode', isArray: true },
            'downloadFile': { 
                method: 'GET', url: 'api/reclamation/files/:clientId/:fileId', responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    }; 
                }
            },
            'deleteFile': { method: 'DELETE', url: 'api/reclamation/files/:clientId/:fileId' },
            'setStatus': { method: 'PUT', url: 'api/reclamation/status/:id' },
            'managerUpdate': { method: 'PUT', url: 'api/reclamation/process'},
            'getStatusChangelog': { method: 'GET', url: 'api/reclamation/log/:id', isArray: true },
            'toExcel': { 
                method: 'POST',
                url: 'api/excel/reclamation/mgmt',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    return {
                        blob: new Blob([data], {type: headers('Content-Type')})
                    }; 
                }
            },
            'getEmployees': { method: 'GET', url: 'api/reclamation/employees/:clientId', isArray: true },
            'getWarehouses': { method: 'GET', url: 'api/reclamation/utils/warehouses', isArray: true },
            'getClients': { method: 'GET', url: 'api/reclamation/utils/clients', isArray: true }
        });

        return service;
    }
})();