(function() {
'use strict';

    angular
        .module('rbllkApp')
        .service('Client', Client);

    Client.$inject = ['$resource'];

    function Client($resource) {
        var service = $resource('api/clients/:code', {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method: 'POST' },
            'update': { method: 'PUT' },
            'getList': { method: 'GET', url: 'api/clients/getList', isArray: true },
            'getAllServicesList': { method: 'GET', url: 'api/services/getAll', isArray: true },
            'find': { method: 'POST', url: 'api/find/client', isArray: true },
            'syncNames': { method: 'POST', url: 'api/util/clients/nameSync', hasBody: false },
            //no delete for now
            'getOwnerGroups': { method: 'GET', url: 'api/owners/withGroups', isArray: true },
            'getOwnerGroupsByLogin': { method: 'GET', url: 'api/owners/withGroupsByLogin', isArray: true },
            'syncGroups': { method: 'POST', url: 'api/util/clients/groupSync', hasBody: false }
        });

        return service;
    }
})();