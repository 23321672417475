(function () {
    'use strict';

    angular
        .module('rbllkApp')
        .factory('ReclamationUtils', ReclamationUtils);

    function ReclamationUtils () {

        var service = {
            getStatusLabelKey: getStatusLabelKey,
            getCostSum: getCostSum
        };

        return service;

        function getStatusLabelKey (status, subStatus) {
            switch (status) {
                case 'DRAFT':
                case 'NEW':
                case 'SUSPENDED':
                    return 'reclamations.statuses.'+status
                case 'CLOSED':
                    if (subStatus === 'CONFIRMED' || subStatus === 'REJECTED') {
                        return 'reclamations.statusLabels.' + subStatus;
                    }
                    return 'reclamations.statuses.CLOSED';
                case 'IN_PROGRESS':
                    if (subStatus === 'CLIENT' || subStatus === 'OPERATOR') {
                        return 'reclamations.statusLabels.IN_PROG_' + subStatus;
                    }
                    return 'reclamations.statuses.IN_PROGRESS';
            }
        }

        function getCostSum (item) {
            return Math.abs((item.shortageQty-item.factShortageQty)*item.cost +
                (item.surplusQty-item.factSurplusQty)*item.cost +
                (item.defectQty-item.factDefectQty)*item.cost);
        }
    }
})();